import { useState } from "react";
import Button from "../../../components/NewDesign/Button/Button";
import { DrawnHeader1 } from "../../../components/NewDesign/DrawnHeader";
import { ChevronDownOutlined } from "../../../components/OldDesign/Icons";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AidInterviewSubPage = () => {
  const { t } = useTranslation();
  const AID_INTERVIEW_TABS = [
    { value: "Temel", label: t("filter.basic") },
    { value: "Tamamlayıcı", label: t("filter.supplementary") },
  ];
  const [AIDContent] = useOutletContext();
  const [activeTab, setActiveTab] = useState(AID_INTERVIEW_TABS[0].value);
  const TabContent = () => {
    if (activeTab === "Temel") {
      return (
        <BasicQuestions
          questionType={t("filter.basic")}
          questions={AIDContent.interviewQuestion.filter((item) =>
            ["Temel Soru", "Basic Questions"].includes(item.questionType),
          )}
        />
      );
    } else if (activeTab === "Tamamlayıcı") {
      return (
        <BasicQuestions
          questionType={t("filter.supplementary")}
          questions={AIDContent.interviewQuestion.filter((item) =>
            ["Tamamlayıcı Soru", "Supplementary Questions"].includes(item.questionType),
          )}
        />
      );
    }
  };
  return (
    <div className="grid grid-cols-4 gap-4 max-lg:grid-cols-1 max-lg:gap-2">
      <div className="col-span-1 flex flex-col items-center justify-start gap-4 rounded-xl bg-neutral-100 p-6 max-lg:flex-row max-lg:bg-transparent max-lg:p-0">
        {AID_INTERVIEW_TABS.map((tab, index) => (
          <Button
            key={index}
            className={`${
              activeTab === tab
                ? "border-secondary_recruiter-600 bg-secondary_recruiter-600 text-neutral-100"
                : "border-neutral-200 bg-neutral-200 text-neutral-700"
            } w-full transition-all max-lg:p-1`}
            onClick={() => setActiveTab(tab.value)}
          >
            {tab.label}
          </Button>
        ))}
      </div>
      <div className="col-span-3 rounded-xl bg-neutral-100 p-9 max-lg:col-span-1 max-lg:p-4">
        <TabContent />
      </div>
    </div>
  );
};

const BasicQuestions = ({ questions, questionType }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col items-start gap-3">
        <DrawnHeader1 className="text-[20px]">
          {questionType} {t("aid.subpages.questions_and_reasons")}
        </DrawnHeader1>
        <p className="leading-tight text-primary-900 max-lg:text-[14px]">
          {t("aid.subpages.question_suggestions", { questiontype: questionType.toLowerCase() })}
        </p>
      </div>
      <div className="flex flex-col gap-3">
        {questions.map((question, index) => (
          <QuiestionAcordion key={index} question={question} />
        ))}
      </div>
    </div>
  );
};

const QuiestionAcordion = ({ question }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="rounded-r-3xl rounded-bl-3xl bg-card_candidate-200 p-6 max-lg:p-4">
      <button onClick={() => setIsOpen((prev) => !prev)} className="flex w-full items-center justify-between">
        <h4 className="text-left text-[18px] font-semibold max-lg:text-[16px]">{question.question}</h4>
        <div>
          <ChevronDownOutlined className={`${isOpen ? "rotate-180" : "rotate-0"} h-5 w-5 transition-all`} />
        </div>
      </button>
      <p
        className={`${
          isOpen ? "mt-2 max-h-[240px]" : "mt-0 max-h-0"
        } scrollbar-hide overflow-auto transition-all duration-300 max-lg:text-[14px]`}
      >
        {question.questionReason}
      </p>
    </div>
  );
};

export default AidInterviewSubPage;
