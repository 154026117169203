import { useState } from "react";
import Button from "../Button/Button";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../Forms/Form";
import { Field, FieldCheckbox } from "../Forms/Field";
import { CloseOutlined } from "../../OldDesign/Icons";
import { ASelect, Checkbox, Input } from "../Forms/Input";
import { REACT_BASE_URL } from "../../../api/constants";
import ReactDatePicker from "react-datepicker";
import { addYears, subYears } from "date-fns";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

const ExperienceForm = () => {
  const { t } = useTranslation();
  const coreCV = useStore((state) => state.coreCV);
  const [selectedExperiences, setSelectedExperiences] = useState(
    coreCV.data.experiences_array.map((exp) => {
      return {
        ...exp,
        job_id: { job_id: exp.job_id, job_name: exp.job.name },
        start_date: new Date(`${exp.start_date}-01`),
        end_date: exp.end_date ? new Date(`${exp.end_date}-01`) : null,
      };
    }),
  );

  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const [stillWorking, setStillWorking] = useState(false);
  const [addingExperience, setAddingExperience] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    setError,
    resetField,
    watch,
    getValues,
    reset,
    clearErrors,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      job_id: "",
      firm_name: "",
      company_id: "",
      start_date: null,
      end_date: null,
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const submitExperiencesForm = async (formData) => {
    const experiences = selectedExperiences.map((exp) => {
      //format start date
      let _startDate = new Date(exp.start_date);
      _startDate.setDate(_startDate.getDate() + 1);
      //format end date
      let _endDate = exp.end_date ? new Date(exp.end_date) : null;
      _endDate && _endDate.setDate(_endDate.getDate() + 1);
      //return experiences array items
      return {
        job_id: exp.job_id.job_id,
        firm_name: exp.firm_name,
        company_id: null,
        start_date: _startDate.toISOString().slice(0, 7),
        end_date: _endDate ? _endDate.toISOString().slice(0, 7) : null,
      };
    });
    await updateApplicationForm({ experiences_array: experiences }, () => navigate("../step4"));
  };

  const handleFormNavigateBack = () => {
    navigate("../step2");
  };

  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const checkAndInsertData = () => {
    const experience = getValues();
    if (experience.start_date && experience.firm_name && experience.job_id && (experience.end_date || stillWorking)) {
      setSelectedExperiences((prev) => [...prev, experience]);
      reset();
      clearErrors();
      setAddingExperience(false);
      setStillWorking(false);
    }
  };

  return (
    <section className="">
      <SectionTitle className="items-start" title={t("candidate.corecv_form.professional_info")} />
      <Form id="user_profile" onSubmit={handleSubmit(submitExperiencesForm)}>
        <div className="flex gap-6 py-6">
          {addingExperience ? (
            <fieldset className="col-span-2 grid grid-cols-2 gap-6">
              <Field label={t("placeholder.company_name")} error={errors?.firm_name}>
                <Input
                  placeholder={t("placeholder.write")}
                  {...register("firm_name", { required: "Required", max: 100 })}
                />
              </Field>
              <Field label={t("cv_fields.job_title")} error={errors?.job_id}>
                <Controller
                  control={control}
                  name="job_id"
                  rules={{ required: "Required" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <ASelect
                      defaultValue={value}
                      selected={value}
                      onBlur={onBlur}
                      defaultOptions
                      loadOptions={loadJobTitleOptions}
                      onChange={onChange}
                      placeholder={t("placeholder.select")}
                      getOptionLabel={(opt) => opt.job_name}
                      getOptionValue={(opt) => opt.job_id}
                    />
                  )}
                />
              </Field>
              <div className="flex flex-row gap-4">
                <Field label={t("placeholder.start_date")} error={errors?.start_date}>
                  <Controller
                    control={control}
                    name="start_date"
                    rules={{ required: "Required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={Date.parse(value)}
                        onChange={(date) => onChange(date)}
                        onBlur={onBlur}
                        selectsStart
                        startDate={value}
                        endDate={watch("start_date")}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText={t("placeholder.select")}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
                <Field label={t("placeholder.end_date")} error={errors?.end_date}>
                  <Controller
                    control={control}
                    name="end_date"
                    rules={!stillWorking && { required: "Required" }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ReactDatePicker
                        selected={Date.parse(value)}
                        onChange={onChange}
                        onBlur={onBlur}
                        selectsEnd
                        startDate={watch("start_date")}
                        endDate={value}
                        maxDate={addYears(new Date(), 10)}
                        minDate={subYears(new Date(), 100)}
                        placeholderText={t("placeholder.select")}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        disabled={stillWorking}
                        className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                      />
                    )}
                  />
                </Field>
              </div>
              <FieldCheckbox className={"mt-[22px] h-[52px] border-neutral-300"} label={t("placeholder.still_working")}>
                <Checkbox
                  value={stillWorking}
                  onChange={(e) => {
                    setStillWorking(e.target.checked);
                    resetField("end_date");
                  }}
                />
              </FieldCheckbox>
            </fieldset>
          ) : (
            <div className="grid grid-cols-4 gap-3">
              <button
                type="button"
                onClick={() => setAddingExperience(true)}
                className="flex w-full items-center justify-center rounded-xl border border-dashed border-primary-500 bg-primary-50 p-3 lg:h-[80px]"
              >
                <span className="text-[16px] font-medium leading-tight text-primary-700">
                  {t("cv_fields.experience")} {t("button.add")} +
                </span>
              </button>
              {selectedExperiences.length > 0 &&
                selectedExperiences.map((exp, index) => {
                  const startDate = exp.start_date?.toLocaleString(i18n.language.slice(0, 2), {
                    month: "short",
                    year: "numeric",
                  });
                  const endDate = exp.end_date?.toLocaleString(i18n.language.slice(0, 2), {
                    month: "short",
                    year: "numeric",
                  });
                  return (
                    <div
                      key={index}
                      className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                    >
                      <div className="text-[15px] font-medium leading-tight text-primary-700">
                        <h4 className="line-clamp-1 leading-tight">{exp.firm_name}</h4>
                        <p className="line-clamp-1 text-sm leading-tight">{exp.job_id.job_name}</p>
                        <p className="line-clamp-1 text-sm leading-tight">
                          {startDate} - {endDate ? endDate : t("common.continuing")}
                        </p>
                      </div>
                      <div className="flex items-start gap-2">
                        <button
                          onClick={(e) => setSelectedExperiences((prev) => prev.filter((e, i) => i !== index))}
                          type="button"
                        >
                          <CloseOutlined className="h-5 w-5 text-primary-700" />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
        <div className="flex basis-full justify-between">
          <Button
            type="button"
            className="bg-neutral-100 px-12 py-4 text-primary-600"
            onClick={addingExperience ? () => setAddingExperience(false) : handleFormNavigateBack}
          >
            {t("button.back")}
          </Button>
          <Button
            type="button"
            onClick={() => {
              checkAndInsertData();
              trigger();
            }}
            className={`${!addingExperience && "hidden"} border-success-400 bg-success-400 px-12 py-4`}
          >
            {t("button.add")}
          </Button>
          <Button disabled={isSubmitting} type="submit" className={`${addingExperience && "hidden"} px-12 py-4`}>
            {t("button.submit")}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default ExperienceForm;
