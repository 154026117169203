import { useState } from "react";
import Button from "./Button/Button";
import { CloseCircleIcon, SortOutlined } from "../OldDesign/Icons";
import { Radio } from "./Forms/Input";
import { FieldCheckbox } from "./Forms/Field";
import { useTranslation } from "react-i18next";

const SortBar = ({ setSortOption, sortOption, disabled = false, sortOptions }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(sortOption);
  const handleSetSortOption = () => {
    setSortOption(selectedOption);
    setOpen(false);
  };
  return (
    <div className="relative">
      <button
        onClick={() => setOpen(!open)}
        disabled={disabled}
        className={`${
          open ? "bg-primary-600 text-primary-75" : "border-neutral-400 bg-neutral-100 text-neutral-600"
        } flex h-[33px] items-center gap-1 rounded-full border px-3 py-1.5`}
      >
        <SortOutlined className="h-[14px] w-[14px] " />
        <p className="text-[14px] font-medium leading-tight">{t("button.sort")}</p>
      </button>

      {open && (
        <>
          <div className="absolute top-[40px] z-20 w-[400px] rounded-xl border border-neutral-300  bg-neutral-100 shadow-lg  max-lg:-right-12 max-lg:w-[360px] lg:left-0">
            <button onClick={() => setOpen(false)} className="absolute right-2 top-2">
              <CloseCircleIcon className={`h-4 w-4 text-neutral-600`} />
            </button>
            <div className="flex flex-col gap-4 border-b border-neutral-300 p-4 max-lg:gap-0 max-lg:p-2">
              {sortOptions.map((option, index) => {
                return (
                  <FieldCheckbox key={index} className={"border-none p-0 max-lg:py-2"} label={option.name}>
                    <Radio
                      onChange={() => {
                        setSelectedOption(option);
                      }}
                      name={"sort"}
                      checked={selectedOption.sortby === option.sortby && selectedOption.ascending === option.ascending}
                    />
                  </FieldCheckbox>
                );
              })}
            </div>
            <div className="flex flex-row gap-4 p-3 max-lg:gap-1">
              <Button
                onClick={() => {
                  setOpen(false);
                  setSelectedOption(sortOption);
                }}
                className="flex-1 border-neutral-100 bg-neutral-100 text-primary-600"
              >
                {t("button.cancel")}
              </Button>
              <Button onClick={handleSetSortOption} className="flex-1">
                {t("button.show")}
              </Button>
            </div>
          </div>
          <div className="fixed left-0 top-0 z-10 h-screen w-screen opacity-0" onClick={handleSetSortOption}></div>
        </>
      )}
    </div>
  );
};

export default SortBar;
