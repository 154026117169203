import { useState } from "react";
import Modal from "../../OldDesign/Modal";
import Button from "../Button/Button";
import { CloseCircleIcon } from "../../OldDesign/Icons";
import { useTranslation } from "react-i18next";

const ConfirmationPopup = ({ showModal, setShowModal, confirmModal, id, message, onSuccess, confirmationTopic }) => {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex items-center border-b p-4">
        <h5 className="text-xl font-medium">{confirmationTopic}</h5>
        <button onClick={() => setShowModal(false)} className="ml-auto">
          <CloseCircleIcon className={"h-5 w-5"} />
        </button>
      </div>
      <div className="p-4">
        <p className="rounded-md bg-primary-50 p-2 text-sm text-primary-900">{message}</p>
      </div>
      <div className="flex gap-2 border-t p-4">
        <Button
          onClick={() => {
            setIsDeleting(true);
            confirmModal(id).then(() => {
              setIsDeleting(false);
              setShowModal(false);
              onSuccess && onSuccess();
            });
          }}
          loading={isDeleting}
          className="flex-1 border-primary-600 bg-neutral-100 text-primary-600"
        >
          {confirmationTopic}
        </Button>
        <Button onClick={() => setShowModal(false)} className="flex-1 ">
          {t("button.cancel")}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationPopup;
