import { useState } from "react";
import {
  BalanceFilled,
  ChevronDownOutlined,
  LampColored,
  LightningFilled,
  WayOutlined,
} from "../../../components/OldDesign/Icons";
import { useStore } from "../../../store/useStore";
import { useTranslation } from "react-i18next";

const QuickTips = () => {
  const { t } = useTranslation();
  const user = useStore((state) => state.user.data);
  const QUICK_TIPS_C = [
    {
      title: t("community.quick_tips.candidate.tip1_title"),
      content: t("community.quick_tips.candidate.tip1_description"),
      icon: <WayOutlined className="h-5 w-5" />,
    },
    {
      title: t("community.quick_tips.candidate.tip2_title"),
      content: t("community.quick_tips.candidate.tip2_description"),
      icon: <LightningFilled className="h-5 w-5 " />,
    },
    {
      title: t("community.quick_tips.candidate.tip3_title"),
      content: t("community.quick_tips.candidate.tip3_description"),
      icon: <BalanceFilled className="h-5 w-5 " />,
    },
  ];
  const QUICK_TIPS_R = [
    {
      title: t("community.quick_tips.recruiter.tip1_title"),
      content: t("community.quick_tips.recruiter.tip1_description"),
      icon: <WayOutlined className="h-5 w-5" />,
    },
    {
      title: t("community.quick_tips.recruiter.tip2_title"),
      content: t("community.quick_tips.recruiter.tip2_description"),
      icon: <LightningFilled className="h-5 w-5 " />,
    },
    {
      title: t("community.quick_tips.recruiter.tip3_title"),
      content: t("community.quick_tips.recruiter.tip3_description"),
      icon: <BalanceFilled className="h-5 w-5 " />,
    },
  ];
  return (
    <div className="rounded-xl lg:bg-[linear-gradient(180deg,#EBF7F0_50%,#FFF_50%)] lg:p-6">
      <div className="flex items-center justify-center pb-3">
        <h3 className="text-center text-[24px] font-medium leading-tight text-primary-900 max-lg:text-[18px]">
          {t("community.quick_tips.header")}
        </h3>
        <LampColored className="h-8 w-8" />
      </div>
      <p className="mx-auto pb-7 text-center text-[18px] font-medium leading-tight text-neutral-900 max-lg:pb-3 max-lg:text-[13px] lg:w-1/2">
        {user.userType === "recruiter"
          ? t("community.quick_tips.recruiter.description")
          : t("community.quick_tips.candidate.description")}
      </p>
      <div className="grid grid-cols-3 gap-5 max-lg:grid-cols-1 max-lg:gap-2">
        {user.userType === "recruiter"
          ? QUICK_TIPS_R.map((item, index) => <TipCard key={index} item={item} />)
          : QUICK_TIPS_C.map((item, index) => <TipCard key={index} item={item} />)}
      </div>
    </div>
  );
};

const TipCard = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex flex-col gap-3 rounded-xl bg-neutral-100 px-4 py-6 shadow-md max-lg:p-4">
      <div className="flex cursor-pointer items-center gap-4" onClick={() => setIsOpen(!isOpen)}>
        <div className="rounded-full bg-primary-600 p-1.5 text-neutral-100">{item.icon}</div>
        <h4 className="text-[24px] font-semibold leading-tight text-secondary_applicant-700 max-lg:text-[16px] ">
          {item.title}
        </h4>
        <div className="ml-auto lg:hidden">
          <ChevronDownOutlined className="h-3 w-3" />
        </div>
      </div>
      <p
        className={`${
          isOpen ? "block" : "max-lg:hidden lg:block"
        } text-[18px] leading-tight text-neutral-900 max-lg:text-[14px]`}
      >
        {item.content}
      </p>
    </div>
  );
};

export default QuickTips;
