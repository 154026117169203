import { useState } from "react";
import { AidSectorsIllustration1, AidSectorsIllustration2 } from "../../../components/NewDesign/Illustrations";
import { DrawnHeader1 } from "../../../components/NewDesign/DrawnHeader";
import { ChevronDownOutlined } from "../../../components/OldDesign/Icons";
import { useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AidSectorsSubPage = () => {
  const { t } = useTranslation();
  const [AIDContent] = useOutletContext();
  let aidRow = AIDContent.translations.split(",");
  aidRow = aidRow.concat(aidRow);
  return (
    <div className="grid grid-cols-2 gap-4 max-lg:grid-cols-1">
      {AIDContent.situationCard.map((card, index) => (
        <div key={index} className="flex flex-col gap-4 rounded-xl bg-neutral-100 p-6 max-lg:p-4">
          <h3 className="text-[24px] font-semibold leading-tight text-secondary_recruiter-800 max-lg:text-[20px]">
            {card.title}
          </h3>
          <div className="flex gap-6 max-lg:flex-col">
            <ul className="flex flex-col gap-4">
              {card.Situation.map((situation, index) => (
                <li key={index} className="text-[16px] leading-tight text-primary-800 max-lg:text-[14px]">
                  • {situation.description}
                </li>
              ))}
            </ul>
            <div className="max-lg:mx-auto">
              {index === 0 ? (
                <AidSectorsIllustration1 className={"w-[150px]"} />
              ) : (
                <AidSectorsIllustration2 className={"w-[150px]"} />
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="col-span-2 flex flex-row gap-6 overflow-hidden max-lg:col-span-1 max-lg:gap-2">
        {aidRow.map((item, index) => (
          <p
            key={index}
            className="whitespace-nowrap text-[36px] font-semibold leading-tight text-secondary_recruiter-200 max-lg:text-[16px]"
          >
            {item}
          </p>
        ))}
      </div>
      <div className="col-span-2 flex flex-col items-center gap-4 rounded-xl bg-neutral-100 p-9 max-lg:col-span-1 max-lg:p-4 ">
        <DrawnHeader1 className={"text-center max-lg:text-[20px]"}>{t("aid.subpages.featured_sectors")}</DrawnHeader1>
        {AIDContent.featuringSector.map((sector) => (
          <SectorDrawer key={sector.id} sector={sector} />
        ))}
      </div>
    </div>
  );
};

const SectorDrawer = ({ sector }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <div className="relative rounded-lg border border-l-8 border-secondary_recruiter-700 bg-neutral-100 px-4 py-6 max-lg:p-3 lg:w-[760px]">
      <button onClick={toggleAccordion} className="z-10 flex w-full items-center justify-between">
        <span className="text-start text-[18px] font-semibold max-lg:text-[16px]">{sector.title}</span>
        <ChevronDownOutlined className={`${isOpen ? "rotate-180" : "rotate-0"} h-5 w-5 transition-all`} />
      </button>
      <p
        className={`${
          isOpen ? "max-h-[100px]" : " max-h-0"
        } overflow-auto transition-all duration-300 ease-in max-lg:text-[13px]`}
      >
        {sector.description}
      </p>
    </div>
  );
};

export default AidSectorsSubPage;
