import WhatIsCommunity from "../../candidate/Community/WhatIsCommunity";
import CompanyWeWork from "../../candidate/Community/CompanyWeWork";
import { useStore } from "../../../store/useStore";
import { WorldIllustration } from "../../../components/NewDesign/Illustrations";
import { useTranslation } from "react-i18next";

const CommunityRecruiter = () => {
  const { t } = useTranslation();
  const user = useStore((state) => state.user.data);
  return (
    <main className="bg-neutral-200 pt-4 max-lg:pt-[53px]">
      <div className="container2 relative my-3 flex items-center gap-12 rounded-xl bg-[linear-gradient(261deg,#316BCD_24.64%,#0D568A_99.34%)] px-16 py-9 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:px-4 max-lg:py-6 lg:h-[650px]">
        <div>
          <p className="pb-6 text-[22px] font-semibold leading-tight text-primary-50 max-lg:pb-3 max-lg:text-[14px]">
            {t("common.welcome")} {user.userName}
          </p>
          <p className="pb-8 text-[52px] font-light leading-tight max-lg:pb-3 max-lg:text-[22px]">
            {t("candidate.get_community_benefits")}
          </p>
          <p className="text-[13px]">{t("community.recruiter_description")}</p>
        </div>
        <div className="right-2 top-2 max-lg:absolute">
          <WorldIllustration className="max-lg:w-[120px] lg:w-[500px]" />
        </div>
      </div>
      <WhatIsCommunity />
      <CompanyWeWork />
    </main>
  );
};

export default CommunityRecruiter;
