import { useEffect, useState } from "react";
import { useStore } from "../../store/useStore";
import { useFieldArray, useForm } from "react-hook-form";
import purplewaves from "../../assets/bg/purplewaves.svg";
import {
  CandidateOutlined,
  CloseCircleIcon,
  CloseOutlined,
  CommunityOutlined,
  CopyOutlined,
  DownloadOutlined,
  EditOutlined,
  EmployeeOutlined,
  ExclamationIcon,
  FileOutlined,
  PlusOutlined,
  RubbishOutlined,
  TickOutlined,
} from "../OldDesign/Icons";
import uploadFileGif from "../../assets/upload-file-gif.gif";
import uploadFileSs from "../../assets/uploadfile_ss.png";
import { Form } from "./Forms/Form";
import { Field } from "./Forms/Field";
import { Input } from "./Forms/Input";
import Button from "./Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import IsMobile from "../../utils/IsMobile";
import InviteFormSuccessPopup from "./Popups/InviteFormSuccessPopup";
import { companyInvite, uploadInviteList, downloadInvalidInviteList } from "../../api/endpoints";
import { toast } from "react-toastify";
import { Trans, useTranslation } from "react-i18next";

const InviteTypeSelection = ({ setInviteTypeFormState }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-2 gap-6 max-lg:grid-cols-1">
        <button
          onClick={() => setInviteTypeFormState("Candidates")}
          className="flex items-center justify-center gap-9 rounded-xl border border-neutral-300 px-9 py-4 text-primary-900 hover:bg-secondary_applicant-600 hover:text-neutral-100 lg:min-w-[450px]"
        >
          <CandidateOutlined className="w-20" />
          <p className="text-[17px] font-semibold leading-tight">{t("filter.candidate")}</p>
        </button>
        <button
          onClick={() => setInviteTypeFormState("Employees")}
          className="flex items-center justify-center gap-9 rounded-xl border border-neutral-300 px-9 py-4 text-primary-900 hover:bg-secondary_recruiter-600 hover:text-neutral-100 lg:min-w-[450px]"
        >
          <EmployeeOutlined className="w-14" />
          <p className="text-[17px] font-semibold leading-tight">{t("filter.employee")}</p>
        </button>
      </div>
    </>
  );
};

const InviteForm = () => {
  const { t } = useTranslation();
  const defaultCampaigns = useStore((state) => state.user.data.defaultCampaign);
  const [submitType, setSubmitType] = useState("Copy"); //copy or add or upload

  const [uploadedFileSummary, setUploadedFileSummary] = useState(null);
  const [invitedFormSummary, setInvitedFormSummary] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const [campaign, setCampaign] = useState(null);
  const [inviteTypeFromState, setInviteTypeFormState] = useState(location.state?.inviteType);

  const copyLinkToClipboard = async () => {
    try {
      clearTimeout();
      navigator.clipboard.writeText(campaign.frontend_link).then(() => setCopied(true));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (inviteTypeFromState === "Candidates") {
      setCampaign(defaultCampaigns.candidate);
    } else if (inviteTypeFromState === "Employees") {
      setCampaign(defaultCampaigns.employee);
    }
  }, [defaultCampaigns, inviteTypeFromState]);

  if (invitedFormSummary?.status > 0) {
    return (
      <InviteFormSuccessPopup>
        <div className="flex flex-col gap-1 pb-20">
          {invitedFormSummary.data.map((row) => (
            <div
              className={`${
                row.status > 0 ? "border-success-400 text-success-500" : "border-error-400 text-error-500"
              } flex flex-row gap-4 rounded-lg border p-1`}
            >
              <p className="leading-tight">{row.invitee.email}</p>
              <p className="leading-tight">{row.message}</p>
            </div>
          ))}
        </div>
      </InviteFormSuccessPopup>
    );
  }
  if (uploadedFileSummary?.status > 0) {
    return (
      <FileSummary
        inviteTypeFromState={inviteTypeFromState}
        uploadedFileSummary={uploadedFileSummary}
        setUploadedFileSummary={setUploadedFileSummary}
      />
    );
  }

  return (
    <>
      <div
        className="p-8 max-lg:w-[95vw] max-lg:px-3 max-lg:py-6 lg:w-[995px]"
        style={{
          backgroundImage: `url(${purplewaves}), linear-gradient(180deg,#D5BBFF 0%,#FFF 19.44%,#FFF 100%)`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="flex justify-between pb-9">
          <h4 className="border-l-2 border-primary-900 pl-2 text-[22px] font-semibold leading-tight text-primary-900 max-lg:text-[18px]">
            {!inviteTypeFromState
              ? t("modal.invite.select_invitees")
              : inviteTypeFromState === "Candidates"
              ? t("modal.invite.send_invites_candidates")
              : t("modal.invite.send_invites_employees")}
          </h4>
          <button onClick={() => navigate(-1)}>
            <CloseCircleIcon className="h-6 w-6 text-primary-900" />
          </button>
        </div>
        {!inviteTypeFromState ? (
          <InviteTypeSelection setInviteTypeFormState={setInviteTypeFormState} />
        ) : (
          <>
            <ul className="mb-4 flex items-baseline gap-6 border-b">
              <li
                className={`${
                  submitType === "Copy" ? "font-bold text-secondary_recruiter-700" : "font-medium text-neutral-500"
                } relative pb-3 text-[18px] leading-tight transition-all`}
              >
                <button onClick={() => setSubmitType("Copy")}>
                  {t("modal.invite.tabs.quick_connection")}
                  <CopyOutlined className="inline-block h-5 w-5" />
                </button>
              </li>
              <li
                className={`${
                  submitType === "Add" ? "font-bold text-secondary_recruiter-700" : "font-medium text-neutral-500"
                } relative pb-3 text-[18px] leading-tight transition-all`}
              >
                <button onClick={() => setSubmitType("Add")}>
                  {t("modal.invite.tabs.invitation_form")} <EditOutlined className="inline-block h-5 w-5" />
                </button>
              </li>
              <li
                className={`${
                  submitType === "Upload" ? "font-bold text-secondary_recruiter-700" : "font-medium text-neutral-500"
                } relative pb-3 text-[18px] leading-tight transition-all`}
              >
                <button onClick={() => setSubmitType("Upload")}>
                  {t("modal.invite.tabs.file_upload")} <DownloadOutlined className="inline-block h-5 w-5" />
                </button>
              </li>
            </ul>

            {submitType === "Add" ? (
              <AddWithFormSection
                inviteTypeFromState={inviteTypeFromState}
                setInvitedFormSummary={setInvitedFormSummary}
              />
            ) : submitType === "Upload" ? (
              <FileUploadSection setUploadedFileSummary={setUploadedFileSummary} />
            ) : (
              <div className="pb-9">
                <p className="pb-3 text-[14px] font-medium leading-tight text-primary-900">
                  {t("modal.invite.you_can_copy_link")}
                </p>
                <button
                  onClick={copyLinkToClipboard}
                  disabled={copied}
                  className="relative w-full cursor-copy rounded-xl border p-3 text-start"
                >
                  {campaign && <p className="text-neutral-700">{campaign.frontend_link}</p>}
                  <div className="absolute right-1 top-1/2 -translate-y-1/2 p-3">
                    {copied ? (
                      <TickOutlined className="h-5 w-5 text-success-400" />
                    ) : (
                      <CopyOutlined className="h-6 w-6 text-secondary_recruiter-600" />
                    )}
                  </div>
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

const AddWithFormSection = ({ inviteTypeFromState, setInvitedFormSummary }) => {
  const { t } = useTranslation();
  const userCompany = useStore((state) => state.user.data.userCompany);

  const {
    register,
    control,
    handleSubmit,
    reset,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_array: [{ name: "", email: "", company_name: userCompany }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "user_array",
    rules: { required: true },
  });

  const sendCandidateInvites = async (formData) => {
    const payload = {
      type: inviteTypeFromState === "Candidates" ? "view" : "employ",
      user_array: [...formData.user_array],
    };
    await companyInvite(
      payload,
      (res) => setInvitedFormSummary(res),
      (err) => toast.error(err.message),
    );
  };

  const addNewInvite = async () => {
    const isFormOk = await trigger();
    if (isFormOk) {
      append({ name: "", email: "", company_name: userCompany });
    }
  };
  useEffect(() => {
    reset({
      user_array: [{ name: "", email: "", company_name: userCompany }],
    });
  }, [userCompany, reset]);
  return (
    <>
      <p className="pb-3 text-[14px] font-medium leading-tight text-primary-900">
        {t("modal.invite.you_can_add_names")}
      </p>
      <div className="rounded-xl border border-neutral-300 p-4">
        <Form onSubmit={handleSubmit(sendCandidateInvites)}>
          <ul className="pb-6">
            {fields.map((item, index) => {
              return (
                <li key={item.id} className="flex gap-4 max-lg:hidden max-lg:flex-col max-lg:last-of-type:flex">
                  <Field
                    className="flex-1"
                    label={IsMobile() ? t("placeholder.name_surname") : index === 0 && t("placeholder.name_surname")}
                    error={errors.user_array?.[index]?.name}
                  >
                    <Input
                      {...register(`user_array.${index}.name`, {
                        required: {
                          value: true,
                          message: t("validation.name_required"),
                        },
                      })}
                      placeholder={t("placeholder.name_surname")}
                    />
                  </Field>
                  <Field
                    className="flex-1"
                    label={IsMobile() ? t("placeholder.email") : index === 0 && t("placeholder.email")}
                    error={errors.user_array?.[index]?.email}
                  >
                    <Input
                      {...register(`user_array.${index}.email`, {
                        required: {
                          value: true,
                          message: t("validation.email_required"),
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: t("validation.email_invalid"),
                        },
                      })}
                      placeholder={t("placeholder.email")}
                    />
                  </Field>
                  <Field
                    className="flex-1"
                    label={IsMobile() ? t("placeholder.company_name") : index === 0 && t("placeholder.company_name")}
                  >
                    <Input
                      {...register(`user_array.${index}.company_name`, {
                        required: true,
                      })}
                      placeholder={t("placeholder.company_name")}
                      disabled
                    />
                  </Field>
                  {
                    <button
                      disabled={fields.length === 1}
                      type="button"
                      className={`${index === 0 ? "mt-6" : "mt-1"} flex h-[50px] items-center max-lg:hidden`}
                      onClick={() => remove(index)}
                    >
                      <RubbishOutlined className="h-6 w-6 text-primary-500" />
                    </button>
                  }
                </li>
              );
            })}
          </ul>
          <div className="flex justify-end gap-4">
            <Button
              type="button"
              className="w-72 bg-neutral-100 text-primary-600 max-lg:hidden"
              icon={<PlusOutlined className="h-5 w-5" />}
              onClick={addNewInvite}
            >
              {t("button.add")}
            </Button>

            <Button type="submit" disabled={isSubmitting} loading={isSubmitting} className="w-72 max-lg:w-full">
              {t("button.send")}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const FileUploadSection = ({ setUploadedFileSummary }) => {
  const { t } = useTranslation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [isDragActive, setIsDragActive] = useState(false);

  const validFileTypes = [
    "text/csv",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleDropFile = (e) => {
    e.preventDefault();
    setIsDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && validFileTypes.includes(file.type)) {
      setUploadedFile(file);
    } else {
      toast.error(t("validation.file_invalid"));
    }
  };
  const handleRemoveFile = () => {
    setUploadedFile(null);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragActive(true);
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragActive(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && validFileTypes.includes(file.type)) {
      setUploadedFile(file);
    } else {
      toast.error(t("validation.file_invalid"));
    }
  };

  const sendFile = () => {
    uploadInviteList(
      { file: uploadedFile, action: "upload" },
      (res) => setUploadedFileSummary(res),
      (err) => toast.error(err.response.data.message),
      (bool) => setIsUploading(bool),
    );
  };
  return (
    <div className="flex flex-col items-center gap-2">
      <div className="w-full">
        <p className="pb-1 text-[14px] font-medium leading-tight text-primary-900">
          {t("modal.invite.you_can_add_file")}
        </p>
      </div>
      <div
        onDrop={handleDropFile}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        className={`${
          isDragActive ? "border-2 border-solid opacity-70" : "border-2 border-dashed"
        } relative flex min-h-[180px] w-full flex-col items-center justify-center rounded-xl  border-secondary_recruiter-500 bg-neutral-200 p-4`}
      >
        <input
          type="file"
          onChange={handleFileChange}
          className="absolute left-0 top-0 z-10 h-full w-full cursor-pointer opacity-0"
          accept=".xlsx,.csv,.txt"
        />
        <img src={uploadFileGif} alt="upload-file" className="h-[140px] w-[140px]" />
        <img
          src={uploadFileSs}
          alt="upload-file-ss"
          className="absolute left-0 top-0 w-[240px] rounded-xl max-lg:w-[160px]"
          style={{
            maskImage: "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))",
          }}
        />
        <p
          className={`${
            !uploadedFile ? "text-neutral-600" : "text-secondary_recruiter-600"
          } font-semibold leading-tight `}
        >
          {!uploadedFile ? t("modal.invite.drag_file_here") : t("modal.invite.upload_completed")}
        </p>
      </div>
      <p className="w-full leading-tight text-neutral-600">{t("modal.invite.accepted_file_types")}</p>
      <ul className="self-start pb-1 text-[13px] leading-tight text-primary-900">
        <li>
          <ExclamationIcon className={"mr-1 inline-block w-4 text-warning-400"} />
          {t("modal.invite.upload_file_constraints_1")}
        </li>
        <li>
          <ExclamationIcon className={"mr-1 inline-block w-4 text-warning-400"} />
          {t("modal.invite.upload_file_constraints_2")}
        </li>
      </ul>
      {uploadedFile && (
        <div className="flex w-full flex-row items-center gap-2 rounded-lg border p-3">
          <FileOutlined className={"h-5 w-5 text-secondary_recruiter-600"} />
          <p className="leading-tight text-neutral-800">{uploadedFile.name}</p>
          <button className="ml-auto" onClick={handleRemoveFile}>
            <RubbishOutlined className={"h-5 w-5 text-primary-500"} />
          </button>
        </div>
      )}
      <Button className={"mt-2 w-1/2"} disabled={!uploadedFile} loading={isUploading} onClick={sendFile}>
        {t("button.continue")}
      </Button>
    </div>
  );
};

const FileSummary = ({ uploadedFileSummary, setUploadedFileSummary, inviteTypeFromState }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [inviteComplete, setInviteComplete] = useState(false);
  const [inviteSummary, setInviteSummary] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const handleInviteAcceptedRows = () => {
    const payload = {
      file_hash: uploadedFileSummary.data.file_hash,
      action: "confirm",
      type: inviteTypeFromState === "Candidates" ? "view" : "employ",
    };
    uploadInviteList(
      payload,
      (res) => {
        setInviteSummary(res);
        setInviteComplete(true);
      },
      (err) => console.log(err),
      (bool) => setSubmitting(bool),
    );
  };

  const handleInvalidsFileDownload = () => {
    const payload = {
      file_hash: uploadedFileSummary.data.file_hash,
      action: "download_invalids",
    };
    downloadInvalidInviteList(
      payload,
      (res) => {
        const type = [
          "application/excel",
          "application/vnd.ms-excel",
          "application/vnd.msexcel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(res.type)
          ? "xlsx"
          : "csv";
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement("a");
        link.href = url;
        const date = new Date().toLocaleString("tr-TR").split(" ")[0];
        link.setAttribute("download", `${date}.${type}`);
        document.body.appendChild(link);
        link.click();
      },
      (err) => console.log(err),
      (bool) => setSubmitting(bool),
    );
  };

  return (
    <div
      className="p-8 max-lg:w-[95vw] max-lg:px-3 max-lg:py-6 lg:w-[995px]"
      style={{
        backgroundImage: `url(${purplewaves}), linear-gradient(180deg,#D5BBFF 0%,#FFF 19.44%,#FFF 100%)`,
        backgroundRepeat: "no-repeat",
      }}
    >
      {!inviteComplete ? (
        <>
          <div className="flex justify-between pb-9">
            <h4 className="border-l-2 border-primary-900 pl-2 text-[22px] font-semibold leading-tight text-primary-900 max-lg:text-[18px]">
              {t("modal.invite.file_summary")}
            </h4>
            <button onClick={() => navigate(-1)}>
              <CloseCircleIcon className="h-6 w-6 text-primary-900" />
            </button>
          </div>
          <p className="pb-4 text-[14px] font-medium leading-tight text-primary-900">
            {t("modal.invite.load_after_check_file")}
          </p>
          <div className="flex flex-col gap-2">
            <h5 className="font-semibold leading-tight text-primary-900">{t("modal.invite.uploaded_file")}</h5>
            <div className="flex w-full flex-row items-center gap-2 rounded-lg border p-3">
              <FileOutlined className={"h-8 w-8 text-secondary_recruiter-600"} />
              <div className="flex flex-col gap-0.5">
                <p className="font-medium leading-tight text-neutral-800">{uploadedFileSummary.data.file_name}</p>
                <p className="font-[13px] leading-tight text-neutral-600 ">{uploadedFileSummary.data.file_size}</p>
              </div>
              <button className="ml-auto" onClick={() => setUploadedFileSummary(null)}>
                <RubbishOutlined className={"h-6 w-6 text-primary-500"} />
              </button>
            </div>
            <div className="flex w-full flex-col items-center gap-2 rounded-lg border p-3">
              <div className="flex flex-row gap-2">
                <p className="flex flex-row items-center gap-2 rounded bg-neutral-200 p-4 font-medium leading-tight text-neutral-900">
                  <span className="rounded-full bg-secondary_recruiter-450 p-1.5 ">
                    <CommunityOutlined className={" h-5 w-5 text-neutral-100"} />
                  </span>
                  {t("modal.invite.total_user_count")}:<span>{uploadedFileSummary.data.row_count}</span>
                </p>
                <Button
                  onClick={handleInviteAcceptedRows}
                  loading={submitting}
                  className="border-success-400 bg-success-400"
                >
                  {t("button.invite")}
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <InviteFormSuccessPopup>
          <div className="flex flex-row justify-center gap-1">
            <p className="flex flex-row items-center gap-2 rounded bg-neutral-200 p-4 font-medium leading-tight text-neutral-900">
              <span className="rounded-full bg-success-400 p-1.5 ">
                <TickOutlined className={"h-5 w-5 text-neutral-100"} />
              </span>
              {t("modal.invite.invited_users")}:<span>{inviteSummary.data.row_count_valid}</span>
            </p>
            <p className="flex flex-row items-center gap-2 rounded bg-neutral-200 p-4 font-medium leading-tight text-neutral-900">
              <span className="rounded-full bg-warning-300 p-1.5 ">
                <ExclamationIcon className={"h-5 w-5 text-neutral-100"} />
              </span>
              {t("modal.invite.repeated_users")}:<span>{inviteSummary.data.row_count_repeats}</span>
            </p>
            <p className="flex flex-row items-center gap-2 rounded bg-neutral-200 p-4 font-medium leading-tight text-neutral-900">
              <span className="rounded-full bg-error-300 p-1.5">
                <CloseOutlined className={"h-5 w-5 text-neutral-100"} />
              </span>
              {t("modal.invite.missing_users")}:<span>{inviteSummary.data.row_count_invalid}</span>
            </p>
          </div>
          <p className="w-full pt-4">
            <Trans i18nKey="modal.invite.for_download_missings_file">
              <button
                className="inline-flex items-baseline gap-1 text-primary-600"
                onClick={handleInvalidsFileDownload}
                disabled={submitting}
              >
                tıklayınız <DownloadOutlined className="h-4 w-4" />
              </button>
            </Trans>
          </p>
          <Button className={"border-error-400 bg-error-400"} onClick={() => navigate(-1)}>
            {t("button.exit")}
          </Button>
        </InviteFormSuccessPopup>
      )}
    </div>
  );
};

export default InviteForm;
