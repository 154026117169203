import { Outlet, useNavigate } from "react-router-dom";
import ProgressBar from "../../../components/NewDesign/StatusBar/ProgressBar";
import { VerticalStepper } from "../../../components/NewDesign/Stepper";
import { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import { useTranslation } from "react-i18next";

const ApplicationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getCoreCV = useStore((state) => state.getCoreCV);

  const coreCV = useStore((state) => state.coreCV);
  const user = useStore((state) => state.user.data);
  const [currentStep, setCurretStep] = useState(1);
  const isMember = user.userCoreCvStatus === "completed";
  const steps = [
    {
      stepNumber: 1,
      name: t("candidate.corecv_form.user_profile"),
    },
    {
      stepNumber: 3,
      name: t("candidate.corecv_form.professional_info"),
    },
    {
      stepNumber: 8,
      name: t("candidate.corecv_form.educational_status"),
    },
  ];

  const findCurrentStep = (coreCV) => {
    if (coreCV.data.identity_progress.steps.educational_status.status) {
      setCurretStep(4);
    } else if (coreCV.data.identity_progress.steps.profession_details.status) {
      setCurretStep(3);
    } else if (coreCV.data.identity_progress.steps.user_profile.status) {
      setCurretStep(2);
    } else {
      setCurretStep(1);
    }
  };
  useEffect(() => {
    if (coreCV.isLoaded) {
      findCurrentStep(coreCV);
      if (coreCV.data.identity_progress.current_step === 10) {
        navigate("confirmation");
      }
    }
  }, [coreCV, navigate]);

  useEffect(() => {
    getCoreCV();
  }, [getCoreCV]);

  if (isMember) {
    navigate("/community");
  }

  if (!coreCV.isLoaded) {
    return null;
  }
  return (
    <>
      <div className="pb-9 max-lg:pb-2 max-lg:pt-[53px]">
        <div className="container2 flex flex-col gap-9 rounded-xl bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-9 py-8 font-inter text-neutral-100 max-lg:p-0">
          <div className="flex flex-col gap-3">
            <h1 className="text-[32px] font-bold leading-tight">{t("candidate.corecv_form.application_form")}</h1>
            <p className="text-justify text-[22px] leading-tight">{t("candidate.corecv_form.continue_filling_form")}</p>
          </div>
          <ProgressBar percent={coreCV.data.identity_progress.value} />
        </div>
      </div>

      <div className="container2 flex gap-9 rounded-xl bg-primary-50 p-9">
        <div className="basis-1/5 self-start">
          <VerticalStepper currentStep={currentStep} steps={steps} />
        </div>
        <div className="flex basis-4/5 flex-col gap-6 rounded-xl bg-neutral-100 p-6">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default ApplicationForm;
