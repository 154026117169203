import { QRCodeSVG } from "qrcode.react";
import useIsMobile from "utils/useIsMobile";
import { ReactComponent as Logo } from "../assets/logo/humanas.svg";
import { ContinueWithAppleButton, ContinueWithGoogleButton } from "components/NewDesign/Button/AuthButtons";
import { DotOutlined } from "components/OldDesign/Icons";
import { useStore } from "store/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { REACT_BASE_DOMAIN } from "../api/constants";
import { useEffect, useState } from "react";
import { publicInvite } from "api/endpoints";
import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loading from "components/OldDesign/Loading";

const Invitation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const url = window.location.pathname + window.location.search;
  const id = searchParams.get("id");
  const isMobile = useIsMobile();
  const [campaign, setCampaign] = useState({});
  const handleGoogleLogin = () => {
    // localhost:3000
    // window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login-${campaign.campaignData.unique_id}&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com`;

    // apptest.humanas.io
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login-${campaign.unique_id}&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com`;
  };
  const handleAppleLogin = () => {
    // apptest.humanas.io
    // window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=query&client_id=com.humanas.lazygurusignin&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&state=apple-web-login`;
    window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&scope=email%20name&client_id=com.humanas.lazygurusignin&redirect_uri=${process.env.REACT_APP_BASE_URL}/login/redirect&state=apple-web-login-${campaign.unique_id}`;
  };
  useEffect(() => {
    const params = { campaign_name: id };
    publicInvite(
      params,
      (res) => {
        setCampaign(res.data);
        localStorage.setItem("autoLoginLink", url);
        localStorage.setItem("campaignData", JSON.stringify(res.data));
      },
      (err) => {
        toast.error(t("message.transaction_failed"));
        setCampaign({ campaignData: false });
        navigate("/login");
      },
    );
    return () => {
      localStorage.removeItem("campaign");
    };
  }, [id]);
  if (!campaign?.unique_id) {
    return <Loading />;
  }
  return (
    <div
      className={`${
        isMobile ? "bg-soft-blur-mobile" : "bg-soft-blur"
      } relative h-screen w-screen bg-cover bg-center bg-no-repeat`}
    >
      {!isMobile ? (
        <InvitationDesktop campaign={campaign} />
      ) : (
        <InvitationMobile
          campaign={campaign}
          handleAppleLogin={handleAppleLogin}
          handleGoogleLogin={handleGoogleLogin}
        />
      )}
    </div>
  );
};

export default Invitation;

const InvitationMobile = ({ campaign, handleAppleLogin, handleGoogleLogin }) => {
  const os = useStore((state) => state.os);
  const isIOS = os === "Mac OS";
  const isPositionInvite = campaign.is_job_position;
  const isEmployeeInvite = campaign.type === "employ";

  return (
    <>
      <div className="flex w-screen items-center justify-center border-b border-b-neutral-300 bg-white p-4">
        <img
          src={campaign.company_profile_picture}
          alt={campaign.company_name}
          className="max-h-[50px] max-w-[100px]"
        />
      </div>
      <div className="flex h-full flex-col items-center gap-4 p-6 text-center">
        <div className="flex h-[130px] flex-col gap-2">
          <div className="flex justify-center gap-1.5">
            <DotOutlined className={"w-[10px] text-primary-600"} />
            <DotOutlined className={"w-[10px] text-neutral-400"} />
          </div>
          <p className="text-sm font-medium leading-tight text-neutral-900">
            {isEmployeeInvite ? (
              <p className="text-lg font-semibold">
                <Trans i18nKey="auth.welcome_to_talent_program" values={{ companyname: campaign.company_name }}>
                  <span className="text-primary-600" />
                  <br />
                </Trans>
              </p>
            ) : (
              <Trans i18nKey="auth.wants_to_know_you" values={{ companyname: campaign.company_name }}>
                <span className="text-lg font-semibold" />
                <br />
              </Trans>
            )}
          </p>
          <p className="text-sm leading-tight text-neutral-900">
            {!isEmployeeInvite &&
              (isPositionInvite ? (
                <Trans i18nKey="auth.ready_to_work" values={{ positionname: campaign.name }}>
                  <span className="text-lg font-semibold text-primary-600" />
                  <br />
                </Trans>
              ) : (
                <Trans i18nKey="auth.want_to_know_open_positions">
                  <span className="text-lg font-semibold text-primary-600" />
                  <br />
                </Trans>
              ))}
          </p>
        </div>
        {isIOS ? (
          <ContinueWithAppleButton onClick={() => handleAppleLogin()} />
        ) : (
          <ContinueWithGoogleButton onClick={() => handleGoogleLogin()} />
        )}
        <p className="text-sm leading-tight text-neutral-800">
          <Trans i18nKey="auth.privacy_consent">
            <a href="https://humanas.io/privacy-policy" target="_blank" className="font-medium text-primary-600" />
            <a href="https://humanas.io/privacy-policy" target="_blank" className="font-medium text-primary-600" />
          </Trans>
        </p>
      </div>
    </>
  );
};

const InvitationDesktop = ({ campaign }) => {
  const { t } = useTranslation();
  const isPositionInvite = campaign.is_job_position;
  const isEmployeeInvite = campaign.type === "employ";
  return (
    <div className="absolute left-1/2 mt-10 flex w-[450px] -translate-x-1/2 flex-col items-center justify-center gap-6 rounded-2xl border border-neutral-400 bg-white px-6 py-9 pt-9">
      <div className="min-h-[70px]">
        <img
          src={campaign.company_profile_picture}
          alt={campaign.company_name}
          className="max-h-[50px] max-w-[100px]"
        />
      </div>
      <div className="flex flex-col gap-3 text-center">
        <p className="text-lg font-medium leading-tight text-neutral-900">
          {isEmployeeInvite ? (
            <p className="text-lg font-semibold">
              <Trans i18nKey="auth.welcome_to_talent_program" values={{ companyname: campaign.company_name }}>
                <span className="text-primary-600" />
                <br />
              </Trans>
            </p>
          ) : (
            <Trans i18nKey="auth.wants_to_know_you" values={{ companyname: campaign.company_name }}>
              <span className="text-lg font-semibold" />
              <br />
            </Trans>
          )}
        </p>
        <p className="text-lg leading-tight text-neutral-900">
          {!isEmployeeInvite &&
            (isPositionInvite ? (
              <Trans i18nKey="auth.ready_to_work" values={{ positionname: campaign.name }}>
                <span className="text-lg font-semibold text-primary-600" />
                <br />
              </Trans>
            ) : (
              <Trans i18nKey="auth.want_to_know_open_positions">
                <span className="text-lg font-semibold text-primary-600" />
                <br />
              </Trans>
            ))}
        </p>
      </div>
      <div className="rounded-2xl border border-neutral-300 p-4">
        <QrCode value={window.location.href} />
      </div>
      <p className="text-center text-base leading-tight text-neutral-900">{t("auth.continue_with_qr")}</p>
    </div>
  );
};

const QrCode = ({ value }) => {
  return <QRCodeSVG size={216} value={value} />;
};
