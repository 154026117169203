import { useEffect, useRef, useState } from "react";
import { GROUP_FILTERS } from "./constants";
import { FieldCheckbox } from "./Forms/Field";
import { Checkbox } from "./Forms/Input";
import Button from "./Button/Button";
import { ChevronDownOutlined, CloseCircleIcon } from "../OldDesign/Icons";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const GroupFilters = ({
  allUserCounts,
  selectedUserGroups,
  setSelectedUserGroups,
  userGroupFilters,
  workFilters,
  selectedWorkInfo,
  setSelectedWorkInfo,
  selectedSources,
  setSelectedSources,
  sourceFilters,
  fetching,
  setCurrentPage,
  personalityFilters,
  selectedPersonality,
  setSelectedPersonality,
  badgeFilters,
  selectedBadge,
  setSelectedBadge,
}) => {
  const { t } = useTranslation();
  const [userCounts, setUserCounts] = useState({
    all: 0,
    favourites: 0,
    archives: 0,
    reviews: 0,
    pool: 0,
    invited: 0,
    playing: 0,
    filling_core_cv: 0,
    completed: 0,
    employee: 0,
    candidate: 0,
  });

  const filtersContent = GROUP_FILTERS();
  // console.log("userGroupFilters", userGroupFilters);

  const handleToggleUserGroup = (group) => {
    setSelectedUserGroups((prevGroups) => {
      const newGroups = [...prevGroups].includes("archives")
        ? [...prevGroups].filter((item) => item !== "archives")
        : [...prevGroups];
      setCurrentPage && setCurrentPage(1);
      // console.log("prev groups:", prevGroups);
      const arr = newGroups.includes(group) ? newGroups.filter((i) => i !== group) : [...newGroups, group];

      return arr.length === 0 ? [] : arr;
    });
  };

  const handleToggleSourceFilter = (source) => {
    setCurrentPage && setCurrentPage(1); //set page 1
    selectedUserGroups.includes("archives") && //remove archives if exist
      setSelectedUserGroups((prev) => [...prev].filter((item) => item !== "archives"));
    setSelectedSources((prev) => (prev.includes(source) ? prev.filter((item) => item !== source) : [...prev, source]));
  };

  const handleApplyIdFilters = (idFilter) => {
    let isEqual = _.isEqual(idFilter, selectedBadge);
    //if there are changes on selected work filters do the jobs
    if (!isEqual) {
      setCurrentPage && setCurrentPage(1); //set page 1
      selectedUserGroups.includes("archives") && //remove archives if exist
        setSelectedUserGroups((prev) => [...prev].filter((item) => item !== "archives"));
      setSelectedBadge(idFilter); // finally set work info filters
    }
  };

  const handleApplyWorkFilter = (workFilter) => {
    let isEqual = _.isEqual(workFilter, selectedWorkInfo);
    //if there are changes on selected work filters do the jobs
    if (!isEqual) {
      setCurrentPage && setCurrentPage(1); //set page 1
      selectedUserGroups.includes("archives") && //remove archives if exist
        setSelectedUserGroups((prev) => [...prev].filter((item) => item !== "archives"));
      setSelectedWorkInfo(workFilter); // finally set work info filters
    }
  };

  const handleApplyPersonalityFilter = (personalityFilter) => {
    let isEqual = _.isEqual(personalityFilter, selectedPersonality);
    //if there are changes on selected work filters do the jobs
    if (!isEqual) {
      setCurrentPage && setCurrentPage(1); //set page 1
      selectedUserGroups.includes("archives") && //remove archives if exist
        setSelectedUserGroups((prev) => [...prev].filter((item) => item !== "archives"));
      setSelectedPersonality(personalityFilter); // finally set work info filters
    }
  };

  const resetFilters = () => {
    setCurrentPage && setCurrentPage(1);
    selectedSources && setSelectedSources([]);
    selectedUserGroups && setSelectedUserGroups([]);
    selectedWorkInfo && setSelectedWorkInfo([]);
    selectedPersonality && setSelectedPersonality([]);
    selectedBadge && setSelectedBadge([]);
  };

  useEffect(() => {
    if (allUserCounts) setUserCounts(allUserCounts);
  }, [allUserCounts]);

  return (
    <div
      id="filtersBar"
      className="scrollbar-hide mb-[-600px] flex w-full items-center gap-1 overflow-x-auto overflow-y-visible pb-[616px]"
    >
      {/* SOURCE (INVITEE) FILTERS */}
      {sourceFilters && (
        <>
          {sourceFilters.map((filter, index) => {
            return (
              <FilterButton
                loading={fetching}
                key={index}
                filtersContent={filtersContent}
                onClick={() => handleToggleSourceFilter(filter)}
                filterName={filter}
                userCount={userCounts[filter]}
                active={selectedSources.includes(filter)}
              />
            );
          })}
        </>
      )}
      {/* GROUP (CONNECTIONS,INVITEE) FILTERS */}
      {userGroupFilters && (
        <>
          <p className="text-secondary_recruiter-600">|</p>
          {userGroupFilters.map((filter, index) => {
            return (
              <FilterButton
                loading={fetching}
                key={index}
                filtersContent={filtersContent}
                onClick={() => handleToggleUserGroup(filter)}
                filterName={filter}
                userCount={userCounts[filter]}
                active={selectedUserGroups.includes(filter)}
              />
            );
          })}
        </>
      )}

      {/* WORK (CONNECTIONS) FILTERS */}
      {workFilters && (
        <>
          <p className="text-secondary_recruiter-600">|</p>
          <MultiFilterButton
            loading={fetching}
            onClick={handleApplyWorkFilter}
            filtersContent={filtersContent}
            filterName={t("filter.work_preference")}
            allFilters={workFilters}
            active={selectedWorkInfo.length > 0}
            selectedCount={selectedWorkInfo.length}
            selectedFilters={selectedWorkInfo}
          />
        </>
      )}

      {/* PERSONALITY (CONNECTIONS) FILTERS */}
      {personalityFilters && (
        <>
          <p className="text-secondary_recruiter-600">|</p>
          <MultiFilterButton
            loading={fetching}
            onClick={handleApplyPersonalityFilter}
            filtersContent={filtersContent}
            filterName={t("filter.personality_type")}
            allFilters={personalityFilters}
            active={selectedPersonality?.length > 0}
            selectedCount={selectedPersonality?.length}
            selectedFilters={selectedPersonality}
          />
        </>
      )}
      {/* BADGE (CONNECTIONS) FILTERS */}
      {badgeFilters && (
        <>
          <p className="text-secondary_recruiter-600">|</p>
          <MultiFilterButton
            loading={fetching}
            onClick={handleApplyIdFilters}
            filtersContent={filtersContent}
            filterName={t("filter.aid")}
            allFilters={badgeFilters}
            active={selectedBadge?.length > 0}
            selectedCount={selectedBadge?.length}
            selectedFilters={selectedBadge}
          />
        </>
      )}

      <button
        type="button"
        disabled={fetching}
        onClick={resetFilters}
        className="flex h-[33px] items-center rounded-3xl px-4 py-2 text-[14px] font-medium text-secondary_recruiter-600 transition-all ease-in hover:bg-secondary_recruiter-100"
      >
        {t("button.clear")}
      </button>
    </div>
  );
};

const FilterButton = (props) => {
  const { onClick, filterName, userCount, active, filtersContent, loading } = props;
  return (
    <button
      onClick={onClick}
      disabled={loading}
      className={`${
        active ? "bg-secondary_recruiter-600 text-neutral-100" : "bg-neutral-100 text-secondary_recruiter-600"
      } flex items-center gap-1 whitespace-nowrap rounded-3xl border border-secondary_recruiter-600 px-3 py-2 text-[13px] font-medium leading-[15.6px]`}
    >
      {filtersContent[filterName]} {`\u00B7`}{" "}
      {loading ? (
        <div
          className={`${
            active ? "border-neutral-100" : "border-secondary_recruiter-600"
          } h-2 w-2 animate-spin rounded-full border border-solid  border-t-transparent`}
        ></div>
      ) : (
        userCount
      )}
    </button>
  );
};

const MultiFilterButton = (props) => {
  const { t } = useTranslation();
  const {
    selectedFilters: selectedFiltersProp,
    onClick,
    allFilters,
    filterName,
    active,
    selectedCount,
    filtersContent,
    loading,
    multiField = false,
  } = props;

  const [selectedFilters, setSelectedFilters] = useState(selectedFiltersProp);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleMultiFilterToggle = (filter) => {
    setSelectedFilters((prev) => (prev.includes(filter) ? prev.filter((item) => item !== filter) : [...prev, filter]));
  };

  const resetMultiFilters = () => {
    if (multiField) {
      let emptyFilters = {};
      for (const key in selectedFilters) {
        emptyFilters[key] = [];
      }
      setSelectedFilters(emptyFilters);
    } else {
      setSelectedFilters([]);
    }
  };

  let checkSubset = (parentArray, subsetArray) => {
    if (subsetArray.length > 0) {
      return subsetArray.every((el) => {
        return parentArray.some((p) => p === el);
      });
    } else return false;
  };

  const handleSelectAllSubfield = (subFilter) => {
    let subFilters = subFilter.reduce((prev, current) => [...prev, current.value], []);
    if (checkSubset(selectedFilters, subFilters)) {
      setSelectedFilters(_.difference(selectedFilters, subFilters));
    } else {
      setSelectedFilters(_.concat(selectedFilters, subFilters));
    }
  };

  useEffect(() => {
    setSelectedFilters(selectedFiltersProp);
  }, [selectedFiltersProp]);
  return (
    <div className="relative">
      <button
        onClick={(e) => {
          e.stopPropagation();
          setOpen((prev) => !prev);
          buttonRef.current.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "end",
          });
        }}
        ref={buttonRef}
        disabled={loading}
        className={`${
          active ? "bg-secondary_recruiter-600 text-neutral-100" : "bg-neutral-100 text-secondary_recruiter-600"
        } flex items-center gap-1 whitespace-nowrap rounded-3xl border border-secondary_recruiter-600 px-4 py-2 text-[13px] font-medium leading-[15.6px]`}
      >
        <p>{filterName}</p>
        {active && (
          <span className="h-4 w-4 rounded-full bg-white p-0.5 text-[11px] leading-[12px] text-secondary_recruiter-600">
            {selectedCount}
          </span>
        )}
        {loading ? (
          <div
            className={`${
              active ? "border-neutral-100" : "border-secondary_recruiter-600"
            } h-2 w-2 animate-spin rounded-full border border-solid  border-t-transparent`}
          ></div>
        ) : (
          <span>
            <ChevronDownOutlined className={`${open && "rotate-180"} h-3 w-3 transition-all`} />
          </span>
        )}
      </button>
      {open && (
        <>
          <div className="scrollbar-hide absolute right-0 top-[40px] z-20 max-h-[580px] w-[454px] overflow-y-auto rounded-xl border border-neutral-300 bg-neutral-100 shadow-lg max-lg:max-h-[350px] max-lg:w-[300px]">
            <div className="relative flex flex-col gap-3  p-4 max-lg:gap-0 max-lg:p-2">
              <button className="absolute right-4 top-4 max-lg:right-2 max-lg:top-2" onClick={() => setOpen(false)}>
                <CloseCircleIcon className={`h-4 w-4 text-neutral-600`} />
              </button>
              {Object.keys(allFilters).map((subField, index) => {
                return (
                  <div className="pb-2" key={index}>
                    <div className="flex flex-row items-center gap-4 pb-1 max-lg:gap-2">
                      <h5 className="text-[17px] font-semibold leading-tight text-primary-800 max-lg:text-[14px]">
                        {filtersContent[subField]}
                      </h5>
                      <FieldCheckbox
                        key={index}
                        className={"border-none p-0 text-neutral-600 max-lg:py-2 max-lg:text-[13px]"}
                        label={t("button.select_all")}
                      >
                        <Checkbox
                          key={index}
                          checked={checkSubset(
                            selectedFilters,
                            allFilters[subField].reduce((prev, current) => [...prev, current.value], []),
                          )}
                          onChange={() => handleSelectAllSubfield(allFilters[subField])}
                        />
                      </FieldCheckbox>
                    </div>
                    <div className="grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-1">
                      {allFilters[subField].map((filter, index) => (
                        <FieldCheckbox
                          key={index}
                          className={"border p-4 text-start max-lg:py-2"}
                          label={filtersContent[filter.value]}
                        >
                          <Checkbox
                            key={index}
                            checked={selectedFilters.includes(filter.value)}
                            onChange={() => handleMultiFilterToggle(filter.value)}
                          />
                        </FieldCheckbox>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="sticky bottom-0 left-0 flex w-full flex-row gap-4 border-t border-neutral-300 bg-neutral-100 p-3 max-lg:gap-1 max-lg:p-2">
              <Button
                onClick={() => resetMultiFilters()}
                className="flex-1 border-neutral-100 bg-neutral-100 text-primary-600"
              >
                {(multiField ? !_.every(selectedFilters, _.isEmpty) : selectedFilters.length > 0)
                  ? t("button.clear")
                  : t("button.cancel")}
              </Button>
              <Button
                onClick={() => {
                  // console.log("button", selectedFilters);
                  onClick(selectedFilters);
                  setOpen(false);
                }}
                className="flex-1"
              >
                {t("button.show")}
              </Button>
            </div>
          </div>
          <div
            className="fixed left-0 top-0 z-10 h-screen w-screen opacity-0"
            onClick={() => {
              onClick(selectedFilters);
              setOpen(false);
            }}
          ></div>
        </>
      )}
    </div>
  );
};

export default GroupFilters;
