import Button from "./Button/Button";
import { BagIconFilled, BirthdayFilled, EditOutlined, RubbishOutlined } from "../OldDesign/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GroupCard = (props) => {
  const { t } = useTranslation();
  const { group, noButtons, small, handleDeleteGroup } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const handleGroupReport = (id) => {
    if (!noButtons) {
      navigate(`/group_report/${id}`, {
        state: { previousLocation: location, selfReport: true },
      });
    }
  };

  const handleGroupEdit = (group) => {
    navigate("/create-group", {
      state: { previousLocation: location, isEdit: true, group: group },
    });
  };

  return (
    <div
      className={`${
        small ? "h-[210px] w-[338px]" : "h-[284px] max-w-[420px]"
      } flex shrink-0 flex-col rounded-2xl border border-neutral-400 bg-neutral-100 p-4 max-lg:h-[180px] max-lg:w-[335px] max-lg:justify-between`}
    >
      <div className="flex flex-col items-start">
        <button
          onClick={() => handleGroupReport(group.id)}
          className="w-full rounded-lg pb-1 text-left text-[24px] font-semibold leading-tight text-primary-800 max-lg:text-[20px]"
        >
          {group.name}
        </button>
        <p className="text-[13px] font-medium leading-tight text-neutral-700 max-lg:hidden max-lg:text-[11px]">
          {group.create_date}
        </p>
        <p
          title={group.description}
          className="my-3 line-clamp-2 text-[16px] leading-tight text-neutral-700 max-lg:m-0 max-lg:line-clamp-1 max-lg:text-[14px]"
        >
          {group.description}
        </p>
      </div>

      <div className="mt-auto flex flex-row">
        <div className="flex items-center gap-1 p-1">
          <BagIconFilled className="h-6 w-6 text-secondary_recruiter-500 max-lg:h-5 max-lg:w-5" />
          <p
            className={`${
              small ? "text-[16px]  max-lg:text-[13px]" : "text-[18px]  max-lg:text-[15px]"
            }   font-medium leading-tight text-primary-800`}
          >
            {group.median_job_experience_title}
          </p>
        </div>
        <div className="flex items-center gap-1 p-1">
          <BirthdayFilled className="h-6 w-6 text-secondary_recruiter-500 max-lg:h-5 max-lg:w-5" />
          <p
            className={`${
              small ? "text-[16px]  max-lg:text-[13px]" : "text-[18px]  max-lg:text-[15px]"
            }   font-medium leading-tight text-primary-800`}
          >
            {Math.round(group.avg_age)}
          </p>
        </div>
        <div className="ml-auto inline-flex flex-row-reverse">
          {group.members.length > 4 && (
            <span className="relative -ml-5 h-[50px] w-[50px] overflow-hidden rounded-full border-2 border-neutral-100 last:ml-0 max-lg:h-[40px] max-lg:w-[40px]">
              <div className="flex h-full w-full items-center justify-center bg-secondary_recruiter-500">
                <span className="pl-2 text-sm font-semibold text-neutral-100">+{group.members.length - 4}</span>
              </div>
            </span>
          )}
          {group.members &&
            group.members.slice(0, 4).map((member, idx) => (
              <span
                key={idx}
                title={member.user_name}
                className="relative -ml-6 h-[50px] w-[50px] overflow-hidden rounded-full border-2 border-primary-75 last:ml-0 max-lg:h-[40px] max-lg:w-[40px]"
              >
                <img src={member.profile_picture} alt={member.user_name} title={member.user_name} />
              </span>
            ))}
        </div>
      </div>
      {!noButtons && (
        <div className="mt-3 flex items-center justify-center gap-2">
          <Button
            onClick={() => handleGroupReport(group.id)}
            className="flex-1 rounded-lg border-primary-300 bg-neutral-100 text-primary-600 transition-colors ease-in hover:bg-primary-75"
          >
            {t("button.review")}
          </Button>
          <Button
            onClick={() => handleGroupEdit(group)}
            icon={<EditOutlined className="h-5 w-5 text-card_candidate-300" />}
            className="rounded-lg border-none bg-success-100"
          ></Button>
          <Button
            onClick={() => handleDeleteGroup(group.id)}
            icon={<RubbishOutlined className="h-5 w-5 text-error-300" />}
            className="rounded-lg border-none bg-error-100 "
          ></Button>
        </div>
      )}
    </div>
  );
};

export default GroupCard;
