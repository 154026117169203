import { useEffect, useState } from "react";
import Button from "../../../components/NewDesign/Button/Button";
import { motion, AnimatePresence } from "framer-motion";
import { ASelect, Input, InputNumber } from "../../../components/NewDesign/Forms/Input";
import { Field } from "../../../components/NewDesign/Forms/Field";
import { PlusCircularOutlined } from "../../../components/OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import { REACT_BASE_URL } from "../../../api/constants";
import { getCnaEntranceSummmary, postCnaEntranceSummmary } from "../../../api/endpoints";
import Loading from "../../../components/OldDesign/Loading";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useStore } from "../../../store/useStore";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const Sectors = ({ firstAnalysisData, setPayload }) => {
  const { t } = useTranslation();
  const loadSectorOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/sector?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const handleSectorSelection = (sectorArray) => {
    setPayload((prev) => {
      return { ...prev, sectors: sectorArray };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4 max-lg:gap-2">
      <p className="text-sm font-semibold leading-tight text-neutral-700">Soru 1/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900 max-lg:text-center">
        {t("cna.first_analysis.which_sector")}
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadSectorOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.sectors}
          onChange={(e, v) => handleSectorSelection(e)}
          defaultOptions
          isMulti
          name={"sectors"}
        />
      </Field>
    </div>
  );
};

const EmployeeCount = ({ firstAnalysisData, setPayload }) => {
  const { t } = useTranslation();
  const loadEmployeeCountOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/employeecount?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const handleEmployeeCountSelection = (employeeCountOption) => {
    setPayload((prev) => {
      return { ...prev, employee_count: employeeCountOption };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4">
      <p className="text-sm font-semibold leading-tight text-neutral-700">Soru 2/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900">
        {t("cna.first_analysis.current_employee_number")}
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadEmployeeCountOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.employee_count}
          defaultOptions
          onChange={(e) => handleEmployeeCountSelection(e)}
          min={0}
          name={"employee_count"}
        />
      </Field>
    </div>
  );
};

const Departments = ({ firstAnalysisData, setPayload }) => {
  const { t } = useTranslation();
  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/departmenttype?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const handleDepartmentSelection = (departmentsArray) => {
    setPayload((prev) => {
      return { ...prev, departments: departmentsArray };
    });
  };
  return (
    <div className="flex flex-col items-center gap-4">
      <p className="text-sm font-semibold leading-tight text-neutral-700">{t("common.question")} 3/3</p>
      <p className="text-[14px] font-semibold leading-tight text-primary-900">
        {t("cna.first_analysis.which_departments")}
      </p>
      <Field className="w-full">
        <ASelect
          loadOptions={loadDepartmentOptions}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
          defaultValue={firstAnalysisData.departments}
          onChange={(e, v) => handleDepartmentSelection(e)}
          defaultOptions
          isMulti
          name={"departments"}
        />
      </Field>
    </div>
  );
};

const Positions = () => {
  const { t } = useTranslation();
  const addJobPosition = useStore((state) => state.addJobPosition);
  const emptyJobPos = { name: "", seat_count: 0 };
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      job_positions: [emptyJobPos],
    },
  });

  const { fields, append } = useFieldArray({
    control,
    name: "job_positions",
  });

  const handleSubmitJobPositions = async (formData) => {
    await addJobPosition(formData);
  };
  return (
    <div className="flex flex-col items-center gap-4 pt-6">
      <p className="text-sm leading-tight text-primary-800">{t("cna.first_analysis.decide_positions")}</p>
      <ul className="flex w-full flex-col items-center gap-2">
        {fields.map((item, index) => {
          return (
            <li key={item.id} className="flex w-full items-center gap-2">
              <Field
                label={index === 0 && t("placeholder.position_name")}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.name || errors.job_positions[1]?.name || errors.job_positions[2]?.name)
                }
                className={"w-full"}
              >
                <Input
                  {...register(`job_positions.${index}.name`, { required: true, maxLength: 30, minLength: 3 })}
                  placeholder={t("placeholder.position_name")}
                />
              </Field>
              <Field
                label={index === 0 && t("placeholder.number_of_people")}
                error={
                  errors.job_positions &&
                  (errors.job_positions[0]?.seat_count ||
                    errors.job_positions[1]?.seat_count ||
                    errors.job_positions[2]?.seat_count)
                }
                className={""}
                name={`job_positions.${index}.seat_count`}
              >
                <Controller
                  control={control}
                  name={`job_positions.${index}.seat_count`}
                  rules={{ required: true, min: 1 }}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return <InputNumber min={0} max={9999} onChange={onChange} />;
                  }}
                />
              </Field>
            </li>
          );
        })}
        {fields.length < 3 && (
          <button onClick={() => append(emptyJobPos)}>
            <PlusCircularOutlined className={"hidden h-6 w-6 text-primary-600"} />
          </button>
        )}
      </ul>
      <Button
        loading={isSubmitting}
        className="self-end bg-neutral-100 px-6 py-2 text-primary-600"
        onClick={handleSubmit((formData, e) => handleSubmitJobPositions(formData))}
      >
        {t("button.add")}
      </Button>
    </div>
  );
};

const CNAFirstAnalysis = () => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstAnalysisData, setFirstAnalysisData] = useState({});
  const [payload, setPayload] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const navigate = useNavigate();

  const steps = [
    {
      id: 1,
      component: Sectors,
      canGoForward:
        (firstAnalysisData.sectors && firstAnalysisData.sectors.length > 0) ||
        (payload.sectors && payload.sectors.length > 0),
    },
    {
      id: 2,
      component: EmployeeCount,
      canGoForward:
        (firstAnalysisData.employee_count && firstAnalysisData.employee_count.id >= 0) ||
        (payload.employee_count && payload.employee_count.id >= 0),
    },
    {
      id: 3,
      component: Departments,
      canGoForward:
        (firstAnalysisData.departments && firstAnalysisData.departments.length > 0) ||
        (payload.departments && payload.departments.length > 0),
    },
    {
      id: 4,
      component: Positions,
      canGoForward:
        (payload.job_positions && payload.job_positions.length > 0) ||
        (firstAnalysisData.job_positions && firstAnalysisData.job_positions.length > 0),
    },
  ];

  const forwardStep = () => {
    setDirection(1);
    setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
  };

  const nextStep = () => {
    postCnaEntranceSummmary(
      payload,
      (res) => {
        setFirstAnalysisData(res.data);
        (currentStep !== 4 || res.data.basic_needs.current_index > 10) && forwardStep();
      },
      (err) => console.log(err),
      (bool) => setSubmitting(bool),
    );
  };

  const backStep = () => {
    setDirection(-1);
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const CurrentComponent = steps[currentStep].component;

  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? 500 : -500,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction === 1 ? -500 : 500,
      opacity: 0,
    }),
  };

  useEffect(() => {
    //call cna entrance summary
    getCnaEntranceSummmary(
      (res) => {
        setFirstAnalysisData(res.data);

        res.data.state === "completed" && setCurrentStep(3);
      },
      (err) => console.log(err),
      (bool) => setLoading(bool),
    );
  }, [navigate]);

  return (
    <div className="flex h-full flex-col  overflow-y-visible">
      {loading ? (
        <Loading className={"h-full w-full max-lg:h-[324px]"} />
      ) : (
        <>
          <h3 className="pb-4 text-[20px] font-bold leading-tight text-primary-900 max-lg:text-lg">
            {currentStep === steps.length - 1
              ? t("cna.first_analysis.add_position")
              : t("cna.first_analysis.company_info")}
          </h3>
          <div className={`relative flex items-center max-lg:h-[240px] max-lg:items-start lg:h-[calc(100%-95px)] `}>
            <AnimatePresence initial={false} custom={direction}>
              <motion.div
                key={currentStep}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ duration: 0.5 }}
                style={{ position: "absolute", paddingBottom: "40px", zIndex: 50, width: "100%" }}
              >
                <CurrentComponent
                  setFirstAnalysisData={setFirstAnalysisData}
                  firstAnalysisData={firstAnalysisData}
                  setPayload={setPayload}
                  payload={payload}
                  forwardStep={forwardStep}
                />
              </motion.div>
            </AnimatePresence>
          </div>
          {currentStep !== steps.length - 1 && (
            <div className="flex items-center justify-center gap-4">
              <Button
                disabled={currentStep === 0}
                className={"border-transparent bg-transparent text-primary-600"}
                onClick={backStep}
              >
                {t("button.back")}
              </Button>
              <Button loading={submitting} disabled={!steps[currentStep].canGoForward} onClick={nextStep}>
                {t("button.forward")}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CNAFirstAnalysis;
