import { DrawnHeader1 } from "../../../components/NewDesign/DrawnHeader";
import { BookFilled, LightningFilled } from "../../../components/OldDesign/Icons";
import { useOutletContext } from "react-router-dom";
import { AidCharacteristic } from "../../../components/NewDesign/Illustrations";
import { useTranslation } from "react-i18next";

const AidCharacteristicSubPage = () => {
  const { t } = useTranslation();
  const [AIDContent] = useOutletContext();
  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-3 rounded-xl bg-neutral-100 p-9 max-lg:flex-col max-lg:p-4">
        <div
          style={{
            background: `linear-gradient(180deg, ${AIDContent.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-[210px] w-[210px] shrink-0 grow-0 rounded-full p-2 shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:hidden`}
        >
          <img src={AIDContent.logo.url} alt={AIDContent.name} />
        </div>
        <div className="flex flex-col items-center gap-6 max-lg:gap-3">
          <DrawnHeader1 className={"text-center max-lg:text-start max-lg:text-[20px]"}>
            {AIDContent.name}: {AIDContent.title}
            <br />
            {t("aid.subpages.how_do_you_know")}
          </DrawnHeader1>
          <ul className="flex flex-col items-center gap-3">
            {AIDContent.keyCharacteristic.map((char, index) => (
              <li
                key={index}
                className="text-center text-[16px] font-medium leading-tight text-primary-800 max-lg:text-[14px]"
              >
                - {char.characteristic}
              </li>
            ))}
            <li className="text-[16px] font-semibold leading-tight text-primary-800 max-lg:text-[14px]">
              {t("aid.subpages.probably_show_features", { aidname: AIDContent.name })} 🙂
            </li>
          </ul>
        </div>
        <div className="w-[200px]">
          <AidCharacteristic />
        </div>
      </div>
      <div className="relative grid grid-cols-2 gap-6 py-9 before:absolute before:left-1/2 before:top-0 before:h-full before:w-screen before:-translate-x-1/2 before:bg-neutral-100 max-lg:grid-cols-1">
        {AIDContent.areas.map((area, index) => (
          <div
            key={index}
            className={`${
              index === 0 ? "bg-primary-75" : "bg-secondary_recruiter-100"
            } relative flex flex-col gap-4 rounded-xl p-6 max-lg:p-4`}
          >
            <div className="flex items-center justify-start gap-2">
              {index === 0 ? (
                <div className="rounded-full bg-primary-500 p-2">
                  <LightningFilled className="h-6 w-6 text-neutral-100" />
                </div>
              ) : (
                <div className="rounded-full bg-secondary_recruiter-600 p-2">
                  <BookFilled className="h-6 w-6 text-neutral-100" />
                </div>
              )}

              <h4 className="text-[20px] font-semibold leading-tight text-primary-900">{area.title}</h4>
            </div>
            <ul className="flex flex-col gap-4">
              {area.Situation.map((item, index) => (
                <li key={index} className="font-medium leading-tight text-primary-800 max-lg:text-[14px]">
                  • <span className="font-semibold">{item.title};</span> {item.description}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AidCharacteristicSubPage;
