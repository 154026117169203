import Button from "../../../components/NewDesign/Button/Button";
import { RightArrowOutlined } from "../../../components/OldDesign/Icons";
import { useTranslation } from "react-i18next";

const ApplyNow = () => {
  const { t } = useTranslation();
  return (
    <section className="container2 ">
      <div className="flex items-center justify-between gap-16 rounded-xl bg-primary-500 px-9 py-6 max-lg:flex-col max-lg:gap-9 max-lg:px-6 max-lg:py-4">
        <div className="">
          <h2 className="pb-3 text-[22px] font-semibold leading-tight text-primary-75 max-lg:text-[18px]">
            {t("community.announce_your_potential")}
          </h2>
          <p className="text-[18px] font-medium leading-tight text-primary-75 max-lg:text-[14px]">
            {t("community.very_little_to_be_visible")}
          </p>
        </div>
        <div>
          <Button
            className="border-neutral-100 bg-neutral-100 text-primary-900"
            icon2={<RightArrowOutlined className="h-5 w-5" />}
          >
            {t("candidate.profile_completion_rate_button")}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default ApplyNow;
