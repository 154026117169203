import React, { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import SectionTitle from "../SectionTitle";
import { User } from "types/user";
import { useTranslation } from "react-i18next";

interface CvCoreProps {
  user: User;
}

const ListItems = ({ item, edu }: { item: string; edu?: any }) => {
  return (
    <li className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
      <p style={{ wordBreak: "break-word" }} className="text-[14px] font-medium leading-tight text-primary-800">
        &#x2022; {item}
      </p>
      {edu && (
        <div className="flex flex-1 justify-between max-lg:w-full">
          <p className="text-[14px] font-medium leading-tight text-neutral-700">{edu.department}</p>
          <p className="text-[14px] font-medium leading-tight text-neutral-700">
            {edu.start_date.replace("-", "/") + "-" + edu.end_date.replace("-", "/")}
          </p>
        </div>
      )}
    </li>
  );
};
const ListBox = ({ title, children, className }) => {
  const classes = twMerge("rounded-lg border border-neutral-300 bg-neutral-200 p-4", className);
  return (
    <div className={classes}>
      <h3 className="pb-3 text-[17px] font-semibold leading-tight text-primary-900">{title}</h3>
      {children}
    </div>
  );
};

const CvCore = forwardRef<HTMLDivElement, CvCoreProps>(({ user }, ref) => {
  const { t } = useTranslation();
  return (
    <div id="corecv" ref={ref} className="flex flex-col gap-4">
      <SectionTitle title={"Core CV"} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="grid grid-cols-10 gap-4 max-lg:grid-cols-1">
        <ListBox className={"lg:col-span-full lg:hidden"} title={t("cv_fields.personal_info")}>
          <ul className="flex flex-wrap gap-2">
            <ListItems item={user.communications.phone[0].name} />
            <ListItems item={user.basic.state[0].name + "," + user.basic.country[0].name} />
            <ListItems item={user.basic.birth_date[0].name} />
            <ListItems item={user.communications.email[0].name} />
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-full"} title={t("cv_fields.about")}>
          <ListItems item={user.basic.about[0]?.name} />
        </ListBox>
        <ListBox className={"lg:col-span-2"} title={t("cv_fields.work_location")}>
          <ul className="flex flex-wrap gap-2">
            {user.work.work_location.map((loc, index) => (
              <ListItems key={index} item={loc.name} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-3"} title={t("cv_fields.work_time")}>
          <ul className="flex flex-wrap gap-2">
            {user.work.work_time.map((option, index) => (
              <ListItems key={index} item={option.name} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-2"} title={t("cv_fields.work_state")}>
          <ul className="flex flex-wrap gap-2">
            <ListItems item={user.work.work_state[0]?.name} />
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-3"} title={t("cv_fields.languages")}>
          <ul className="flex flex-wrap gap-2">
            {user.core_cv.languages.map((language, index) => (
              <ListItems key={index} item={language.name + " - " + language.proficiency.name} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-full"} title={t("cv_fields.skills")}>
          <ul className="scrollbar-hide flex flex-wrap gap-2 overflow-auto lg:max-h-[250px]">
            {user.core_cv.skills.map((skill, index) => (
              <ListItems key={index} item={skill.name} />
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-5"} title={t("cv_fields.degree")}>
          <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
            {user.core_cv.educations.map((school, index) => (
              <li key={index} className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
                <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                  <p className="text-[14px] font-medium leading-tight text-primary-900">
                    &#x2022; {school.institute.name}
                  </p>
                  <p className="text-[14px] font-medium leading-tight text-neutral-700">
                    {school.department !== "-1" && school.department.name + ","} {school.degree.name}
                  </p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {school.start.name + " - " + school.end.name}
                </p>
              </li>
            ))}
          </ul>
        </ListBox>
        <ListBox className={"lg:col-span-5"} title={t("cv_fields.experience")}>
          <ul className="scrollbar-hide flex flex-col gap-2 overflow-auto lg:max-h-[250px]">
            {user.core_cv.experiences.map((exp, index) => (
              <li key={index} className="flex items-center gap-2 max-lg:flex-col max-lg:items-start max-lg:gap-1">
                <div className="flex flex-1 flex-col justify-between max-lg:w-full">
                  <p className="text-[14px] font-medium leading-tight text-primary-900">&#x2022; {exp.company.name}</p>
                  <p className="text-[14px] font-medium leading-tight text-neutral-700">{exp.job[0].name}</p>
                </div>
                <p className="text-[14px] font-medium leading-tight text-neutral-700">
                  {exp.start + " - " + (exp.end || t("common.continuing"))}
                </p>
              </li>
            ))}
          </ul>
        </ListBox>
      </div>
    </div>
  );
});

export default CvCore;
