import match from "../../../../assets/guides/guide_match.png";
import match2 from "../../../../assets/guides/guide_match2.png";
import howtomatch from "../../../../assets/guides/guide_howtomatch.png";
import matchreport from "../../../../assets/guides/guide_matchreport.png";
import myreports from "../../../../assets/guides/guide_myreports.png";
import myreports2 from "../../../../assets/guides/guide_myreports2.png";
import { useStore } from "store/useStore";
import renderRichText from "utils/renderRichText";

const Match = () => {
  const strapiGuideContent = useStore((state) => state.strapiGuideContent);
  const content = strapiGuideContent.data[0].guide[1].guidecontent[2].content;
  return (
    // <div className="flex flex-col gap-4">
    //   <div className="rounded-xl bg-neutral-200 p-4">
    //     <div className="flex flex-col gap-4">
    //       <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
    //         <div className="flex flex-col justify-center lg:w-2/5">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Match Nedir?</h3>
    //           <p className="text-sm leading-tight text-neutral-800">
    //             Match, firmanızın farklı gruplarını veya bireylerle grupları karşılaştırarak aralarındaki uyum
    //             seviyesini analiz etmenizi sağlar.
    //           </p>
    //         </div>
    //         <img src={match} alt="match" className="h-full object-cover max-lg:w-[300px] lg:w-3/5" />
    //       </div>
    //       <img src={match2} alt="match2" className="h-full object-cover max-lg:w-[300px] lg:w-full" />
    //     </div>
    //   </div>
    //   <div className="rounded-xl bg-neutral-200 p-4">
    //     <div className="flex flex-col gap-4">
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Nasıl Match Yapılır?</h3>
    //         <p className="text-sm leading-tight text-neutral-800">
    //           İlk adımda, referans olarak değerlendirmek istediğiniz grubu seçiniz. Ardından, karşılaştırmak istediğiniz
    //           hedef grubu belirleyerek eşleşme sürecini başlatabilirsiniz. Sürecin sonunda, iki grup arasındaki uyumu
    //           detaylandıran bir match raporuna erişebilirsiniz.
    //         </p>
    //       </div>
    //       <img src={howtomatch} alt="howtomatch" className="h-full w-full object-cover" />
    //     </div>
    //   </div>
    //   <div className="rounded-xl bg-neutral-200 p-4">
    //     <div className="flex flex-col gap-4">
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Match Raporu</h3>
    //         <p className="text-sm leading-tight text-neutral-800">
    //           Karşılaştırılan iki grup arasındaki uyum yüzdesini ve detaylarını incelemenizi sağlar. Ayrıca, hedef
    //           grubunuz içerisindeki üyelerden dilediğinizi seçerek, bireysel uyum skorlarını detaylı bir şekilde analiz
    //           edebilirsiniz.
    //         </p>
    //       </div>
    //       <img src={matchreport} alt="matchreport" className="h-full w-full object-cover" />
    //     </div>
    //   </div>
    //   <div className="rounded-xl bg-neutral-200 p-4">
    //     <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Raporlarım</h3>
    //     <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
    //       <p className="text-sm leading-tight text-neutral-800 lg:w-2/5">
    //         Daha önce kaydedilen inceleme ve Match raporlarınızı görüntüleyebilir, gerektiğinde düzenleyebilir veya
    //         silebilirsiniz. Raporları dilediğiniz zaman detaylı bir şekilde inceleyerek gruplarınızın uyum seviyeleri
    //         hakkında bilgi edinebilirsiniz.
    //       </p>
    //       <img src={myreports} alt="myreports" className="h-full object-cover max-lg:w-[300px] lg:w-3/5" />
    //     </div>
    //     <img src={myreports2} alt="myreports2" className="h-full object-cover max-lg:w-[300px] lg:w-full" />
    //   </div>
    // </div>
    <>{strapiGuideContent.isLoaded && renderRichText(content)}</>
  );
};

export default Match;
