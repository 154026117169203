import { AppleLogo, GoogleLogo } from "components/OldDesign/Icons";
import Button from "./Button";
import i18n from "i18n";

export const ContinueWithAppleButton = (props) => {
  return (
    <Button className="border-black bg-white text-black" {...props}>
      <AppleLogo />
      <p className="text-black-700 pl-1 text-[22px] font-medium leading-tight max-lg:text-[19px] lg:text-center">
        {i18n.t("button.continue_with", { name: "Apple" })}
      </p>
    </Button>
  );
};
export const ContinueWithGoogleButton = (props) => {
  return (
    <Button className="border-black bg-white text-black" {...props}>
      <GoogleLogo />
      <p className="text-black-700 pl-1 text-[22px] font-medium leading-tight max-lg:text-[19px] lg:text-center">
        {i18n.t("button.continue_with", { name: "Google" })}
      </p>
    </Button>
  );
};
export const DownloadFromAppStoreButton = (props) => {
  return (
    <a href="https://apps.apple.com/app/id6451207671" target="_blank" {...props}>
      <img
        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
        alt="Download on the App Store"
        width="150"
      />
    </a>
  );
};
export const DownloadFromGooglePlayButton = (props) => {
  return (
    <a href="https://play.google.com/store/apps/details?id=com.Humanas.LazyGuru" target="_blank" {...props}>
      <img
        src={`https://play.google.com/intl/en_us/badges/static/images/badges/${i18n.language.slice(
          0,
          2,
        )}_badge_web_generic.png`}
        alt="Get it on Google Play"
        width="150"
      />
    </a>
  );
};
