import { useTranslation } from "react-i18next";
import { LazyGuru, LazyGuruIcon } from "../../components/OldDesign/Icons";

const LazyGuruDialogue = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* MOBILE DIALOGUE */}
      <div
        className={`${
          window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
        } absolute left-1/2 inline-flex w-[360px] -translate-x-1/2 flex-col rounded-xl bg-white p-6 transition-all duration-500 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
      >
        <div className="absolute -top-16 self-center lg:hidden">
          <LazyGuru color="#FFF" className="h-9 w-40" />
        </div>
        <ul className="flex flex-col gap-6 text-[16px] font-medium leading-5 text-primary-900">
          <li className="relative pl-3">
            <svg
              className="absolute left-0 top-1.5"
              xmlns="http://www.w3.org/2000/svg"
              width="6"
              height="6"
              viewBox="0 0 6 6"
              fill="none"
            >
              <circle cx="3" cy="3" r="3" fill="#062B45" />
            </svg>
            {t("lazyguru_dialogue.description")}
          </li>
        </ul>
      </div>
      {/* TRANSPARENT LOGO */}
      <div className={` absolute bottom-4 left-4`}>
        <LazyGuruIcon color="#106BAC" className="h-[170px] w-[133px] opacity-5" />
      </div>
    </>
  );
};

export default LazyGuruDialogue;
