import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../../../store/useStore";
import { DownloadOutlined, InfoOutlined, RightArrowOutlined } from "../../../OldDesign/Icons";
import Button from "../../../NewDesign/Button/Button";
import LevelBar from "../../StatusBar/LevelBar";
import ProgressBar from "../../StatusBar/ProgressBar";
import Tooltip from "../../Tooltips/Tooltip";
import { Switch } from "../../Forms/Input";
import { useTranslation } from "react-i18next";

const LeftBoxMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <div>
          <h4 className="text-[20px] font-semibold leading-tight">{t("candidate.im_not_working")}</h4>
          <p className="leading-tight">{t("candidate.we_will_whow_your_potential")}</p>
        </div>
        <Switch />
      </div>
      <Button
        className="border-neutral-100 bg-neutral-100 text-primary-900"
        icon2={<RightArrowOutlined className="h-4 w-4" />}
        onClick={() => navigate("/community")}
      >
        {t("candidate.go_to_community_button")}
      </Button>
    </>
  );
};
const LeftBoxNotMember = ({ coreCvProgress }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between">
          <h3 className="text-[22px] font-semibold leading-tight max-lg:text-[18px]">
            {t("candidate.profile_completion_rate_header")}
          </h3>
          <Tooltip
            content={
              <div>
                <h2 className="font-bold">{t("candidate.profile_completion_rate_tooltip1")}</h2>
                <p className="pb-2">{t("candidate.profile_completion_rate_tooltip2")}</p>
                <h2 className="font-bold">{t("candidate.profile_completion_rate_tooltip3")}</h2>
                <p className="pb-2">{t("candidate.profile_completion_rate_tooltip4")}</p>
                <h2 className="font-bold">{t("candidate.profile_completion_rate_tooltip5")}</h2>
                <p className="pb-2">{t("candidate.profile_completion_rate_tooltip6")}</p>
              </div>
            }
          >
            <InfoOutlined className="h-5 w-5" />
          </Tooltip>
        </div>
        <p className="text-[15px] leading-tight max-lg:text-[13px]">
          {t("candidate.profile_completion_rate_explanation")}
        </p>
      </div>
      <div className="lg:py-4">
        <ProgressBar percent={coreCvProgress.value} />
      </div>
      <div>
        <Button
          className="border-neutral-100 bg-neutral-100 text-primary-900 max-lg:visible max-lg:w-full"
          icon2={<RightArrowOutlined className=" h-5 w-5" />}
          onClick={() => navigate("/community")}
        >
          {t("candidate.profile_completion_rate_button")}
        </Button>
      </div>
    </>
  );
};

const HeroCandidate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const downloadMetaCv = useStore((state) => state.downloadMetaCv);
  const userName = useStore((state) => state.overview.data.userName);
  const levels = useStore((state) => state.overview.data.scenarioProgress.scenario_states);
  const coreCvProgress = useStore((state) => state.overview.data.coreCvProgress);
  const welcomeMessage = useStore((state) => state.overview.data.welcomeMessage);
  const userPicture = useStore((state) => state.user.data.userPicture);
  const welcomeTitle = useStore((state) => state.overview.data.welcomeTitle);
  const scenarioProgress = useStore((state) => state.overview.data.scenarioProgress);
  const user = useStore((state) => state.user.data);
  const isMember = user.userCoreCvStatus === "completed";
  const metaCvLevel = scenarioProgress.scenario_states.filter((scenario) => scenario.completed).length;

  const handleDownloadMetaCv = () => {
    if (!isMember) {
      navigate("/join-community", { state: { previousLocation: location } });
    } else {
      downloadMetaCv(user.userId, userName);
    }
  };

  return (
    <div className="pb-6 max-lg:pb-2 max-lg:pt-[53px]">
      <div className="container2 rounded-xl bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-9 py-8 font-inter text-neutral-100 max-lg:bg-neutral-100 max-lg:bg-[linear-gradient(78deg,#FFFFFF_0%,#FFFFFF_100%)] max-lg:p-0">
        <div className="flex flex-col gap-6 max-lg:gap-2">
          {/* WELCOMING PART */}
          <div className="flex flex-row items-center gap-4 py-3 max-lg:gap-2 max-lg:px-1 max-lg:py-0 max-lg:text-primary-900">
            <img
              src={userPicture}
              className="h-[100px] w-[100px] rounded-full max-lg:h-[77px] max-lg:w-[77px]"
              alt="Profile"
            />
            <div className="flex w-full flex-col justify-center gap-2 max-lg:gap-1">
              <div className="flex justify-between">
                <p className="text-[32px] font-bold leading-tight max-lg:text-[18px]">{welcomeTitle}</p>
              </div>
              <p className="text-justify text-[22px] font-medium leading-tight max-lg:text-left max-lg:text-[13px] max-lg:font-normal">
                {welcomeMessage}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-9 max-lg:flex max-lg:grid-cols-1 max-lg:grid-rows-2 max-lg:flex-col max-lg:gap-3">
            {/* LEFT BOX */}
            <div className="flex flex-1 flex-col justify-between gap-8 rounded-xl bg-[linear-gradient(77deg,#060B28_-2.59%,rgba(10,14,35,0.28)_99.98%)] p-6 max-lg:gap-3 max-lg:bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] max-lg:p-4 lg:min-h-[220px]">
              {isMember ? <LeftBoxMember /> : <LeftBoxNotMember coreCvProgress={coreCvProgress} />}
            </div>
            {/* RIGHT BOX */}
            <div className="flex flex-1 flex-col justify-between rounded-xl bg-[rgba(255,255,255,0.15)] p-6 max-lg:gap-2 max-lg:bg-secondary_applicant-200 max-lg:p-4 max-lg:text-neutral-1000">
              <div className="flex flex-col gap-1">
                <div className="flex justify-between">
                  <h3 className="text-[22px] font-semibold leading-tight max-lg:text-[18px]">LVL {metaCvLevel}</h3>
                  <Tooltip
                    content={
                      <div>
                        <h2 className="font-bold">{t("candidate.progress_tooltip1")}</h2>
                        <p className="pb-2">{t("candidate.progress_tooltip2")}</p>
                        <h2 className="font-bold">{t("candidate.progress_tooltip3")}</h2>
                        <p className="pb-2">{t("candidate.progress_tooltip4")}</p>
                        <h2 className="">{t("candidate.progress_tooltip5")}</h2>
                      </div>
                    }
                  >
                    <InfoOutlined className="h-5 w-5 max-lg:text-primary-300" />
                  </Tooltip>
                </div>
                <p className="text-[15px] leading-tight max-lg:text-[13px]">{scenarioProgress.state_info}</p>
              </div>
              <div className="py-1">
                <LevelBar levels={levels} />
              </div>
              <div className="flex w-full flex-row flex-wrap justify-between gap-1 max-lg:flex-wrap max-lg:justify-center max-lg:gap-2">
                <Button
                  className="flex-1 border-neutral-100 bg-neutral-100 text-primary-900"
                  icon2={<DownloadOutlined className="h-4 w-4" />}
                  onClick={handleDownloadMetaCv}
                >
                  {t("candidate.download_metacv")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCandidate;
