import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ChevronRightOutlined, HomeFilled } from "../OldDesign/Icons";
import { useTranslation } from "react-i18next";

const Breadcrumb = () => {
  const { t } = useTranslation();
  const paths = useLocation();
  const pathNames = paths.pathname.split("/").slice(1);
  const crumbs = {
    aid: "Humanas AID",
    summary: t("common.summary"),
    identities: t("common.identities"),
    cna: "CNA",
    services: t("common.services"),
    overview: t("header.nav_links.overview"),
    sectors: t("aid.subpages.sectoral_approach"),
    interview: t("aid.subpages.interview_questions"),
    features: t("aid.subpages.features"),
    braveheart: "Braveheart",
    the_bridge: "The Bridge",
    wanderlust: "Wanderlust",
    social_thinker: "Social Thinker",
    the_warrior: "The Warrior",
    phoenix: "Phoenix",
    vanga: "Vanga",
    the_dealmaker: "The Dealmaker",
    natural_ai: "Natural AI",
    voyager_1: "Voyager 1",
    maverick: "Maverick",
    harmonious: "Harmonious",
    connector: "Connector",
    justitia: "Justitia",
  };
  return (
    <nav aria-label="Breadcrumb" className="flex py-6 max-lg:py-3">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {pathNames.map((path, index) => {
          let href = `/${pathNames.slice(0, index + 1).join("/")}`;
          let isActive = paths.pathname === href;
          return (
            <li
              key={index}
              className={`${
                isActive ? "font-semibold" : "font-normal"
              } flex items-center justify-center gap-1.5 text-neutral-700 max-lg:gap-1 max-lg:text-[11px]`}
            >
              {index > 0 && <ChevronRightOutlined className={"h-3 w-3"} />}
              <Link to={href} className="flex items-center gap-1">
                {index === 0 && <HomeFilled className={"h-5 w-5"} />}
                {crumbs[path]}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
