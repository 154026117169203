import React, { useState } from "react";
import {
  BagIconFilled,
  BirthdayFilled,
  ChevronDownOutlined,
  CloseCircleIcon,
  CommunityOutlined,
  EditFilled,
  InfoOutlined,
  MatchFilled,
} from "../OldDesign/Icons";
import CircularProgressBar from "./StatusBar/CircularProgressBar";
import SectionTitle from "./SectionTitle";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import Tooltip from "./Tooltips/Tooltip";
import IsMobile from "../../utils/IsMobile";
import GroupCard from "./GroupCard";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/useStore";
import Button from "./Button/Button";
import { useTranslation } from "react-i18next";

const isMobile = IsMobile();
const MobileBarChart = ({ bar_data, onlyRef, refName, targetName }) => {
  return (
    <BarChart width={340} height={250} data={bar_data} layout={"vertical"}>
      <CartesianGrid vertical={false} strokeDasharray="3 3" />
      <XAxis tick={{ fontSize: "9px" }} type={"number"} />
      <YAxis tick={{ fontSize: "9px" }} type={"category"} dataKey={"title"} />
      <Tooltip />
      <Legend
        // layout={"vertical"}
        iconType="circle"
        verticalAlign="bottom"
        align={"center"}
      />
      <Bar barSize={8} name={refName} dataKey={"reference_value"} fill={"#6359E9"} radius={5} />
      {!onlyRef && <Bar barSize={8} name={targetName} dataKey={"target_value"} fill={"#9ECBF6"} radius={5} />}
    </BarChart>
  );
};

const DesktopBarChart = ({ bar_data, onlyRef, refName, targetName }) => {
  return (
    <>
      <BarChart width={1080} height={250} data={bar_data} layout={"horizontal"}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis tick={{ fontSize: "13px" }} type={"category"} dataKey={"title"} />
        <YAxis tick={{ fontSize: "13px" }} type={"number"} dataKey={""} />
        <Tooltip />
        <Legend
          layout={"horizontal"}
          iconType="circle"
          verticalAlign="bottom"
          align={"center"}
          wrapperStyle={{ paddingLeft: "40px" }}
        />
        <Bar barSize={15} name={refName} dataKey={"reference_value"} fill={"#6359E9"} radius={5} />
        {!onlyRef && <Bar barSize={15} name={targetName} dataKey={"target_value"} fill={"#9ECBF6"} radius={5} />}
      </BarChart>
    </>
  );
};

const MatchOrientationCard = ({ dimension, onlyRef, refName, targetName }) => {
  const { t } = useTranslation();
  // REFERENCE LOCATION
  const referencePercent =
    dimension.reference_value === 20
      ? 21
      : dimension.reference_value === 50
      ? 51
      : dimension.reference_value === 80
      ? 81
      : dimension.reference_value;

  const referenceIndicatorLocation = dimension.reference_direction ? 50 + referencePercent : 50 - referencePercent;

  // TARGET LOCATION
  const targetPercent =
    dimension.target_value === 20
      ? 21
      : dimension.target_value === 50
      ? 51
      : dimension.target_value === 80
      ? 81
      : dimension.target_value;
  const targetIndicatorLocation = dimension.target_direction ? 50 + targetPercent : 50 - targetPercent;

  return (
    <div className="relative rounded-xl border border-neutral-300 bg-neutral-100 p-4 max-lg:p-2">
      <h3 className="pb-6 text-center text-[18px] font-semibold leading-tight text-primary-900">{dimension.title}</h3>
      <div className="relative flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-2">
          <div
            className={`${
              !dimension.reference_direction || !dimension.target_direction || dimension.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{dimension.title_left}</span>
              <Tooltip content={dimension.description_left}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
          <div
            className={`${
              dimension.reference_direction || dimension.target_direction || dimension.value === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-4 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-3 right-1/2 z-50 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[15px] font-medium max-lg:-top-2 max-lg:text-[12px]`}
            >
              <span className="whitespace-nowrap">{dimension.title_right}</span>
              <Tooltip content={dimension.description_right}>
                <InfoOutlined className={"h-4 w-4"} />
              </Tooltip>
            </div>
          </div>
        </div>
        {/* BAR */}
        <div className="relative w-full lg:mb-6 ">
          <div className="grid h-6 grid-cols-[repeat(20,minmax(0,1fr))] gap-2 max-lg:h-4 max-lg:gap-1">
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="rounded-l-full bg-[#135B91]"></div>
              <div className="bg-[#3469AD]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.extraordinary")}
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#4771BD]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#7986E8]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.strong")}
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#8D94F8]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#82B7EA]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.balanced")}
              </span>
            </div>
            <div className={` relative col-span-4 grid grid-cols-4`}>
              <div className="bg-[#7CCBE3]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#7CCBE3]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.fully_balanced")}
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#82B7EA]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#8D94F8]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.balanced")}
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#7986E8]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#4771BD]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.strong")}
              </span>
            </div>
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="bg-[#3469AD]"></div>
              <div className="rounded-r-full  bg-[#135B91]"></div>
              <span className="absolute left-1/2 top-6 -translate-x-1/2 whitespace-nowrap pt-1 text-[13px] font-medium leading-tight text-primary-800 max-lg:top-4 max-lg:text-[9px]">
                {t("common.extraordinary")}
              </span>
            </div>
          </div>

          {/* TARGET INDICATOR */}
          <div
            className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
            style={{ paddingLeft: `${targetIndicatorLocation}%` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              className="max-lg:w-5"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="#9ECBF6"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>
          {/* REF INDICATOR */}
          <div
            className={`absolute -left-[17px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
            style={{ paddingLeft: `${referenceIndicatorLocation}%` }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              className="max-lg:w-5"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="#6359E9"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="white"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>

          <div className="absolute right-1/2 top-1/2 h-4 w-4 -translate-y-1/2 translate-x-1/2 rotate-45 rounded bg-neutral-100 max-lg:h-3 max-lg:w-3 max-lg:rounded-[2px]"></div>
        </div>
        <div className="grid w-full gap-6 max-lg:grid-cols-1 max-lg:gap-2 lg:grid-cols-2">
          {/* REFERENCE GROUP */}
          <div
            className={`${
              onlyRef && "col-span-2"
            } rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
          >
            <div className="flex items-center justify-between pb-3">
              <h5 className="font-bold text-neutral-800">
                <span className="text-primary-800">{dimension.description_main_level_reference}</span>{" "}
                {dimension.description_main_level_reference === "Tam dengeli düzeyde"
                  ? dimension.title + " " + t("common.orientation_of")
                  : dimension.description_main_title_reference}
              </h5>
              <p className="flex items-center justify-center rounded-full bg-chart-purple2 px-3 py-1 text-[13px] font-medium leading-none  text-neutral-100">
                {refName}
              </p>
            </div>
            <p className="text-[14px] font-medium leading-tight text-neutral-800">
              {dimension.description_main_reference}
            </p>
          </div>
          {/* TARGET GROUP */}
          {!onlyRef && (
            <div
              className={`rounded-xl border border-none border-primary-800 bg-neutral-200 p-3 leading-tight  max-lg:text-[13px]`}
            >
              <div className="flex items-center justify-between pb-3">
                <h5 className="font-bold text-neutral-800">
                  <span className="text-primary-800">{dimension.description_main_level_target}</span>{" "}
                  {dimension.description_main_level_target === "Tam dengeli düzeyde"
                    ? dimension.title + " " + t("common.orientation_of")
                    : dimension.description_main_title_target}
                </h5>
                <p className="flex items-center justify-center rounded-full bg-chart-sky-blue px-3 py-1 text-[13px] font-medium leading-none text-primary-900">
                  {targetName}
                </p>
              </div>
              <p className="text-[14px] font-medium leading-tight text-neutral-800">
                {dimension.description_main_target}
              </p>
            </div>
          )}
        </div>
      </div>
      {/* {!onlyRef && (
        <div className="flex items-center justify-center gap-3">
          <p className="flex items-center gap-1 text-[15px] font-medium text-[#6359E9] max-lg:text-[12px]">
            <DotOutlined className="h-4 w-4 max-lg:h-2 max-lg:w-2" />
            {refName}: <span>{dimension.reference_value}</span>
          </p>

          <p className="flex items-center gap-1 text-[15px] font-medium text-[#9ECBF6] max-lg:text-[12px]">
            <DotOutlined className="h-4 w-4 max-lg:h-2 max-lg:w-2" />
            {targetName}: <span>{dimension.target_value}</span>
          </p>
        </div>
      )} */}
    </div>
  );
};

const RadarPersonalityChart = ({ radar_data, onlyRef, refName, targetName }) => {
  // onlyRef && radar_data.forEach((data) => delete data.value_target);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadarChart data={radar_data}>
        <defs>
          <linearGradient
            id="colorRefGroup"
            x1="148.912"
            y1="1.02734"
            x2="148.912"
            y2="255.707"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6002EE" />
            <stop offset="1" stopColor="#C09AF9" stopOpacity="0.31" />
          </linearGradient>
          <linearGradient
            id="colorMatchGroup"
            x1="107.176"
            y1="1.47974"
            x2="107.176"
            y2="195.356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#106BAC" />
            <stop offset="1" stopColor="#4064FF" stopOpacity="0" />
          </linearGradient>
        </defs>
        <PolarGrid />
        <PolarAngleAxis dataKey="title" fontWeight={600} fontSize={isMobile ? "13px" : "16px"} />
        <PolarRadiusAxis domain={[0, 100]} />
        <Radar
          dot={{ fillOpacity: 1, fill: "#6359E9" }}
          name={refName}
          dataKey="value_reference"
          stroke="#6359E9"
          fill="url(#colorRefGroup)"
          fillOpacity={0.3}
        />
        {!onlyRef && (
          <Radar
            dot={{ fillOpacity: 1, fill: "#9ECBF6" }}
            name={targetName}
            dataKey="value_target"
            stroke="#9ECBF6"
            fill="url(#colorMatchGroup)"
            fillOpacity={0.3}
          />
        )}
        <Legend
          iconType="circle"
          formatter={(value, entry, index) => (
            <span className="font-medium text-neutral-1000 max-lg:text-[13px]">{value}</span>
          )}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
};

const UserMatchCard = ({ user, handleResetGroup }) => {
  // const [selectedItemId, setSelectedItemId] = useState(false);
  // const [showOverlay, setShowOverlay] = useState(false);
  // const location = useLocation();
  // const navigate = useNavigate();
  // const handleOverlayOpen = (id) => {
  //   setSelectedItemId(id);
  //   setShowOverlay(true);
  // };
  // const handleModalOpen = (e) => {
  //   IsMobile()
  //     ? handleOverlayOpen(user.id)
  //     : navigate(`/view/${user.id}`, {
  //         state: { previousLocation: location },
  //       });
  // };
  return (
    <div className="relative flex h-[203px] w-[337px] flex-col justify-between p-4 max-lg:h-[180px] max-lg:w-[335px]">
      <button className="absolute right-4 top-4" onClick={() => handleResetGroup()}>
        <CloseCircleIcon className="h-5 w-5" />
      </button>
      <div className="flex gap-2.5 pb-1">
        <div className="relative flex h-[58px] w-[58px] items-center justify-center rounded-full bg-[#87C4FF] ">
          <img src={user.profile_picture} alt="profile" className="h-[50px] w-[50px] rounded-full" />
        </div>
        <div className="flex flex-1 flex-col gap-[3px]">
          <p className="font-medium leading-tight text-primary-900">{user.name}</p>
          <p className="text-[13px] leading-tight text-neutral-900">{user.job_title}</p>
          <p className="text-[13px] leading-tight text-neutral-900">
            {user.state}, {user.country}
          </p>
        </div>
        <div className="relative cursor-pointer self-start"></div>
      </div>
      <div className="flex gap-1">
        <div className="flex items-center gap-1 p-1">
          <BagIconFilled className="h-6 w-6 text-secondary_recruiter-500 max-lg:h-5 max-lg:w-5" />
          <p className="text-[16px] font-medium leading-tight text-primary-800 max-lg:text-[13px]">
            {user.job_experience_title}
          </p>
        </div>
        <div className="flex items-center gap-1 p-1">
          <BirthdayFilled className="h-6 w-6 text-secondary_recruiter-500 max-lg:h-5 max-lg:w-5" />
          <p className={`text-[16px] font-medium leading-tight text-primary-800 max-lg:text-[13px]`}>
            {Math.round(user.age)}
          </p>
        </div>
        {/* <div>
          <Button onClick={handleModalOpen}>Meta CV</Button>
        </div> */}
      </div>
    </div>
  );
};

const MatchReport = ({ report, isSelfReport, savedReport }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [membersDropdownOpen, setMembersDropdownOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState(report.match[0]);

  const initialReportName = report.report_name
    ? report.report_name
    : isSelfReport
    ? `${currentReport.reference_group_data.name} ${t("modal.report.analysis_report")}`
    : `${currentReport.reference_group_data.name} vs ${currentReport.target_group_data.name} ${t(
        "modal.report.comparison_report",
      )}`;
  const initialReportDescription = report.report_description ? report.report_description : t("placeholder.description");

  const [editMode, setEditMode] = useState(false);
  const [reportName, setReportName] = useState(initialReportName);
  const [reportDescription, setReportDescription] = useState(initialReportDescription);

  const editReport = useStore((state) => state.editReport);
  const saveReport = useStore((state) => state.saveReport);

  const [saving, setSaving] = useState(false);

  const handleReportChange = (report) => {
    setCurrentReport(report);
  };
  const handleResetGroup = () => {
    setCurrentReport(report.match[0]);
  };
  const handleSaveReport = async () => {
    setSaving(true);
    if (savedReport) {
      const editFormData = {
        id: report.report_id,
        name: reportName,
        description: reportDescription,
      };
      // console.log(editFormData);
      try {
        await editReport(editFormData);
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    } else {
      const saveFormData = {
        name: reportName,
        description: reportDescription,
        group_reference: currentReport.reference_group_data.id,
        group_target: currentReport.target_group_data.id,
      };
      try {
        await saveReport(saveFormData);
      } catch (error) {
        console.log(error);
      } finally {
        setSaving(false);
      }
    }
    setEditMode(false);
  };

  return (
    <div className="bg-neutral-200">
      {/* Report Header */}
      <div className="flex justify-start gap-2 bg-neutral-100 p-9 max-lg:flex-col max-lg:p-1">
        <div className="flex flex-1 items-center">
          {isSelfReport ? (
            <div className="rounded-full bg-primary-100 p-3">
              <CommunityOutlined className="h-9 w-9 text-primary-700" />
            </div>
          ) : (
            <div>
              <CircularProgressBar value={Math.round(currentReport.match_percent)} />
            </div>
          )}
          <div className="flex w-full flex-col justify-center gap-0.5">
            <input
              onChange={(e) => setReportName(e.target.value)}
              value={reportName}
              type="text"
              disabled={!editMode}
              readOnly={!editMode}
              className="rounded-md border border-primary-300 bg-neutral-200 px-1 text-[26px] font-bold leading-tight text-primary-900 outline-none disabled:border-transparent disabled:bg-neutral-100  max-lg:text-[14px]"
            />
            <p className="px-1 text-[13px] leading-tight text-neutral-700">{report.create_date}</p>
            <input
              onChange={(e) => setReportDescription(e.target.value)}
              value={reportDescription}
              type="text"
              disabled={!editMode}
              readOnly={!editMode}
              className="rounded-md border border-primary-300 bg-neutral-200 px-1 text-[15px] leading-tight text-primary-900 outline-none disabled:border-transparent disabled:bg-neutral-100 max-lg:text-[14px]"
            />
          </div>
        </div>
        <div className="flex items-center gap-2 max-lg:order-1 lg:ml-auto">
          <Button
            onClick={() => setEditMode((prev) => !prev)}
            className="bg-primary-100 text-primary-600 max-lg:flex-1 max-lg:px-4"
            icon={<EditFilled className="h-4 w-4" />}
          >
            {t("button.edit")}
          </Button>
          <Button disabled={saving} onClick={handleSaveReport} className=" max-lg:flex-1 max-lg:px-4">
            {t("button.submit")}
          </Button>
          <Button onClick={() => navigate(-1)} className="bg-neutral-100 text-primary-600 max-lg:flex-1 max-lg:px-4">
            {t("button.exit")}
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-[63fr_37fr] gap-4 px-9 py-4 max-lg:grid-cols-1 max-lg:gap-2 max-lg:px-1 max-lg:py-2">
        {isSelfReport ? (
          <>
            {/* SOLO GROUP */}
            <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 bg-neutral-100 p-6 max-lg:gap-2 max-lg:px-1 max-lg:py-3">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 max-lg:gap-1">
                  <p className="text-[24px] font-semibold leading-tight text-primary-900 max-lg:text-[15px]">
                    {!currentReport.is_group ? (
                      <>
                        {t("modal.report.compatibility_result_is", {
                          username: currentReport.user_data.name,
                          result: Math.round(currentReport.match_percent),
                        })}
                      </>
                    ) : (
                      t("modal.report.group_details", { groupname: currentReport.reference_group_data.name })
                    )}
                  </p>
                </div>
              </div>
              <div className="flex h-full items-center justify-center gap-2 max-lg:flex-col max-lg:items-center max-lg:gap-1">
                <div className="flex h-[203px] w-[337px] items-center justify-center rounded-2xl border border-secondary_recruiter-700 bg-neutral-100 max-lg:h-[180px] max-lg:w-[335px]">
                  <GroupCard group={currentReport.reference_group_data} noButtons small />
                </div>

                <MatchFilled
                  className={`${
                    currentReport.is_group ? "h-0 w-0 opacity-0" : "h-[48px] w-[56px] opacity-100 max-lg:w-[32px]"
                  } text-primary-500 transition-all ease-linear`}
                />
                <div
                  className={`${
                    currentReport.is_group
                      ? "h-0 w-0 opacity-0"
                      : "h-[203px] w-[337px] opacity-100 max-lg:h-[180px] max-lg:w-[335px]"
                  } flex items-center justify-center rounded-2xl border border-secondary_recruiter-700 bg-neutral-100 transition-all ease-linear `}
                >
                  {currentReport.is_group ? null : (
                    <UserMatchCard user={currentReport.user_data} handleResetGroup={handleResetGroup} />
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {/* GROUP VS */}
            <div className="flex flex-col gap-4 rounded-xl border border-neutral-300 bg-neutral-100 p-6 max-lg:gap-2 max-lg:px-1 max-lg:py-3">
              <div className="flex items-center gap-2 max-lg:gap-1">
                <p className="text-[24px] font-semibold leading-tight text-primary-900 max-lg:text-[15px]">
                  {t("modal.report.compatibility_result_btw_groups_is", {
                    result: Math.round(currentReport.match_percent),
                  })}
                </p>
              </div>
              <div className="flex justify-center gap-2 max-lg:flex-col max-lg:items-center max-lg:gap-1">
                <div className="flex h-[203px] w-[337px] items-center justify-center rounded-2xl border border-secondary_recruiter-700 bg-neutral-100 max-lg:h-[180px] max-lg:w-[335px]">
                  <GroupCard group={currentReport.reference_group_data} noButtons small />
                </div>
                <MatchFilled className="w-[56px] text-primary-500 max-lg:w-[32px]" />
                <div
                  className={`flex h-[203px] w-[337px] items-center justify-center rounded-2xl border border-secondary_recruiter-700 bg-neutral-100 max-lg:h-[180px] max-lg:w-[335px]`}
                >
                  {currentReport.is_group ? (
                    <GroupCard group={currentReport.target_group_data} noButtons small />
                  ) : (
                    <UserMatchCard user={currentReport.user_data} handleResetGroup={handleResetGroup} />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="rounded-xl border border-neutral-300 bg-neutral-100">
          <div className="flex flex-col gap-2 px-6 py-4 max-lg:gap-1 max-lg:px-2 max-lg:py-1">
            <h5 className="text-[20px] font-bold leading-tight text-primary-900 max-lg:text-[14px]">
              {currentReport.target_group_data.name}
            </h5>
            <p className="leading-tight text-neutral-700 max-lg:text-[13px]">
              {t("modal.report.select_user_to_compare")}
            </p>
            <button
              onClick={() => setMembersDropdownOpen((prev) => !prev)}
              className="absolute right-4 translate-y-1/2 lg:hidden"
            >
              <ChevronDownOutlined
                className={`${membersDropdownOpen ? "rotate-180" : "rotate-0"} h-5 w-5 transition-all`}
              />
            </button>
          </div>
          {/* MATCH LISTS */}
          <div
            className={`${
              membersDropdownOpen ? "max-lg:h-[200px] max-lg:p-2" : "max-lg:h-0 max-lg:border-none max-lg:p-0"
            } flex h-[334px] flex-col gap-3 overflow-y-auto border-t border-neutral-300 px-6 py-4 transition-all max-lg:gap-1`}
          >
            {report.match.slice(1).map((report, idx) => (
              <button
                type="button"
                key={idx}
                onClick={() => handleReportChange(report)}
                className="flex items-center justify-between rounded-xl border border-neutral-300 bg-neutral-100 px-6 py-3 hover:bg-primary-100 max-lg:px-2 max-lg:py-1"
              >
                <p className="text-[18px] font-semibold leading-tight max-lg:text-[13px]">
                  {report.is_group ? report.target_group_data.name : report.user_data.name}
                </p>

                <CircularProgressBar value={report.match_percent} small />
              </button>
            ))}
          </div>
        </div>
      </div>
      {/* PERSONALITY */}
      <div className="px-9 max-lg:px-1">
        <div className="rounded-xl border border-neutral-300 bg-neutral-100 p-6 max-lg:px-1 max-lg:py-3">
          <SectionTitle
            title={t("modal.metacv.personality_type")}
            className="w-1/4 items-start gap-1 pb-3 text-[22px] max-lg:w-full max-lg:items-center max-lg:pb-1"
          />
          <div className="grid grid-cols-2 max-lg:grid-cols-1">
            {/* RADAR CHART */}
            <div className="mx-auto h-[407px] w-[596px] max-lg:h-[220px] max-lg:w-[340px]">
              <RadarPersonalityChart
                refName={currentReport.reference_group_data.name}
                targetName={
                  currentReport.is_group ? currentReport.target_group_data.name : currentReport.user_data.name
                }
                onlyRef={isSelfReport && currentReport.is_group}
                radar_data={currentReport.radar_chart}
              />
            </div>
            {/* PERSONALITY DESCRIPTIONS */}
            <div className="flex flex-col gap-4 rounded-xl bg-primary-50 p-6 max-lg:px-2 max-lg:py-4">
              {currentReport.radar_chart.map((personality, idx) => (
                <div key={idx}>
                  <h5 className="mb-2 border-l-2 border-primary-900 pl-1 text-[15px] font-semibold leading-tight text-primary-900">
                    {personality.title}
                  </h5>
                  <p className="text-[14px] font-medium leading-tight text-primary-800 max-lg:text-justify max-lg:text-[13px] max-lg:font-normal max-lg:text-neutral-800">
                    {personality.description || "N/A"}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="px-9 py-4 max-lg:px-1">
        <div className="rounded-xl border border-neutral-300 bg-neutral-100 p-6 max-lg:p-1">
          <SectionTitle
            title={t("modal.metacv.occupational_orientation")}
            className="w-1/4 items-start gap-1 pb-3 text-[22px] max-lg:w-full max-lg:items-center max-lg:pb-1"
          />
          {/* DIMENSION BAR CHART*/}
          <div className="flex flex-col gap-3 pb-9">
            {currentReport.dimensions.map((dimension, idx) => (
              <MatchOrientationCard
                refName={currentReport.reference_group_data.name}
                targetName={
                  currentReport.is_group ? currentReport.target_group_data.name : currentReport.user_data.name
                }
                onlyRef={isSelfReport && currentReport.is_group}
                key={idx}
                dimension={dimension}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchReport;
