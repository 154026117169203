import {
  AppleStoreButton,
  DownloadOutlined,
  EnterIcon,
  GoogleStoreButton,
  HumanasLogo,
  LazyGuru,
  LazyGuruColoredOpaque,
  LazyGuruIcon,
  MetaCvOnlyLogoOpaque,
} from "../../components/OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CandidateSignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="lg:relative lg:basis-1/2">
        <div
          className={`${
            window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
          } absolute right-1/2 flex w-[360px] translate-x-1/2 flex-col justify-between rounded-xl bg-white p-6 lg:right-9 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:translate-x-0 lg:p-12`}
        >
          <div className="mb-6 flex flex-col gap-4">
            <div className="absolute -top-16 self-center lg:hidden">
              <HumanasLogo className="text-neutral-100" />
            </div>
            <div className="mb-3 self-center max-lg:hidden">
              <HumanasLogo className="text-primary-600" />
            </div>
            <p className="mb-2 border-l-2 border-l-primary-1000 pl-2 text-[20px] font-bold leading-6 text-primary-1000 lg:text-[24px] lg:leading-7">
              {t("auth.tap_into_business_potential")}
            </p>
            <div className="relative flex items-start justify-start gap-4">
              <div className="flex items-center justify-center rounded-full bg-secondary_applicant-100 p-2">
                <DownloadOutlined className="h-6 w-6 text-primary-600" />
              </div>
              <div className="leading-5">
                <span className="pb-0.5 text-[13px] font-bold leading-4 text-primary-900">
                  {t("auth.download_application")}
                </span>
                <p className=" text-sm text-neutral-800 lg:text-[15px]">{t("auth.get_lazy_guru_app")}</p>
              </div>
            </div>
            <div className="relative flex items-start justify-start gap-4">
              <div className="flex items-center justify-center rounded-full bg-secondary_applicant-100 p-2">
                <LazyGuruIcon className="h-6 w-6" color="#106BAC" />
              </div>
              <div className="leading-5">
                <span className="pb-0.5 text-[13px] font-bold leading-4 text-primary-900">{t("common.metacv")}</span>
                <p className=" text-sm text-neutral-800 lg:text-[15px]">{t("auth.start_your_journey")}</p>
              </div>
            </div>
            <div className="relative flex items-start justify-start gap-4">
              <div className="flex items-center justify-center rounded-full bg-secondary_applicant-100 p-2">
                <EnterIcon className="h-6 w-6" color="#106BAC" />
              </div>
              <div className="leading-5">
                <span className="pb-0.5 text-[13px] font-bold leading-4 text-primary-900">
                  {t("auth.login_to_system")}
                </span>
                <p className=" text-sm text-neutral-800 lg:text-[15px]">{t("auth.highlight_skills_potential")}</p>
              </div>
            </div>
            <div className="relative flex items-start justify-center gap-4 lg:justify-start">
              <div className="bg-none p-2 max-lg:hidden">
                <div className="h-6 w-6"></div>
              </div>
              <span className="center pb-0.5 text-[13px] font-semibold leading-4 text-primary-900">
                {t("auth.change_life_now")}
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.Humanas.LazyGuru&pcampaignid=web_share"
              target="_blank"
              rel="noreferrer"
            >
              <GoogleStoreButton className="max-lg:h-[44px] max-lg:w-[147px]" />
            </a>
            <a href="https://apps.apple.com/tr/app/lazy-guru/id6451207671" target="_blank" rel="noreferrer">
              <AppleStoreButton className="max-lg:h-[44px] max-lg:w-[147px]" />
            </a>
          </div>
          <p
            className={`${
              window.innerHeight < 700 ? "max-lg:-bottom-5" : "max-lg:-bottom-10"
            } mt-auto text-center text-[16px] leading-5 text-neutral-700 max-lg:absolute  max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap`}
          >
            {t("auth.already_have_account")}
            <span className="cursor-pointer font-medium text-primary-1000" onClick={() => navigate("/login")}>
              {" "}
              {t("auth.login")}
            </span>
          </p>
        </div>
      </div>
      {/* DESKTOP DIALOGUE */}
      <div className="relative leading-5 text-neutral-100 max-lg:hidden lg:ml-auto lg:flex lg:basis-1/2 lg:flex-col lg:justify-center lg:gap-6 lg:py-9 lg:pr-9">
        <div>
          <LazyGuru className="h-[36px] w-[159px]" color="#FFF" />
        </div>
        <p className="text-[16px] font-medium leading-5 text-neutral-100 opacity-90">
          {t("auth.explore_world_find_potential")}
        </p>
        <div className="absolute -bottom-10 right-0">
          <MetaCvOnlyLogoOpaque color="#FFF" opacity="0.1" />
        </div>
      </div>
      {/* MOBILE DIALOGUE BUTTON */}
      <div
        className={`${
          window.innerHeight < 700 ? "bottom-1" : "bottom-14"
        } absolute right-4 flex h-[80px] w-[80px] items-center justify-center rounded-full bg-primary-200 text-[13px] font-medium lg:hidden`}
        onClick={() => navigate("/lazyguru")}
      >
        <LazyGuruColoredOpaque />
        <span className="absolute">{t("common.lazy_guru")}</span>
      </div>
    </>
  );
};

export default CandidateSignUp;
