import { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import { getNetworkConnections } from "../../../api/endpoints";
import Loading from "../../../components/OldDesign/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import emptyusers_aid from "../../../assets/emptyusers_aid.png";
import { QuotationMark } from "../../../components/NewDesign/Illustrations";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import Pagination from "components/OldDesign/Pagination";
import { t } from "i18next";

const HumanasIds = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const strapiContent = useStore((state) => state.strapiContent);
  const [selectedAid, setSelectedAid] = useState(strapiContent.data[0].codeName);
  const [filteredUsers, setFilteredUsers] = useState({
    allUserCounts: {},
    paginationInfo: {},
    memberList: [],
    isLoaded: false,
    isLoading: false,
  });

  // PAGINATION
  const [pageSize, setPageSize] = useState(12); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  const selectedAidContent = strapiContent.data.find((item) => item.codeName === selectedAid);
  const handleAidSelect = (aid) => {
    setSelectedAid(aid);
  };

  const handleModalOpen = (user) => {
    navigate(`/view/${user.id}`, {
      state: { previousLocation: location },
    });
  };

  useEffect(() => {
    const payload = {
      page: currentPage,
      rows: pageSize,
      filter: {
        usergroups: [],
        usersource: [],
        work_info: [],
        personality: [],
        badge: [selectedAid],
      },
      sort: null,
    };
    getNetworkConnections(
      payload,
      null,
      (res) =>
        setFilteredUsers((prev) => {
          return {
            ...prev,
            allUserCounts: res.data.search_metadata.filter_counts,
            paginationInfo: res.data.search_metadata.pagination_info,
            memberList: res.data.rows,
            isLoaded: true,
          };
        }),
      (err) => {
        setFilteredUsers((prev) => {
          return { ...prev, isLoaded: false };
        });
      },
      (bool) =>
        setFilteredUsers((prev) => {
          return { ...prev, isLoading: bool };
        }),
    );
  }, [selectedAid, pageSize, currentPage]);
  return (
    <div className="grid h-full gap-4 lg:grid-cols-[313px,1fr]">
      <select
        onChange={(e) => handleAidSelect(e.target.value)}
        name="aid"
        id="aid"
        className="rounded-lg p-2 font-semibold lg:hidden"
      >
        {strapiContent.data.map((aid, index) => {
          return (
            <option key={index} value={aid.codeName}>
              {aid.name} {aid.title}
            </option>
          );
        })}
      </select>
      <div className="divide-y divide-neutral-100 rounded-xl bg-neutral-100 bg-opacity-30 max-lg:hidden lg:overflow-y-auto">
        {strapiContent.data.map((aid, index) => {
          return (
            <AidListItem
              key={index}
              aid={aid}
              onClick={() => handleAidSelect(aid.codeName)}
              active={selectedAid === aid.codeName}
            />
          );
        })}
      </div>
      <div className="flex h-full flex-col items-center justify-start">
        <div className="relative flex w-full flex-col gap-1.5 rounded-lg bg-primary-800 bg-opacity-30 px-4 py-2 max-lg:w-full max-lg:px-3 max-lg:py-1">
          <QuotationMark className={"absolute left-1 top-0 w-5 text-primary-800"} />
          <QuotationMark className={"absolute right-1 top-0 w-5 rotate-180 text-primary-800"} />
          <p className="px-2 text-center text-[13px] font-medium leading-tight text-primary-800 max-lg:text-[13px]">
            {selectedAidContent.definition}
          </p>
          <hr />
          <div className="flex flex-wrap gap-3 max-lg:justify-center max-lg:gap-2">
            {selectedAidContent.keyword.map((tag, index) => (
              <TagWithIcon
                key={index}
                text={tag.keyword}
                className={`border border-neutral-100 bg-neutral-100 bg-opacity-20 px-2 py-1 text-neutral-100`}
              />
            ))}
          </div>
        </div>
        {filteredUsers.isLoading ? (
          <Loading className={"h-full w-full"} />
        ) : (
          <>
            {filteredUsers.memberList.length < 1 ? (
              <div className="flex flex-1 flex-col items-center justify-center gap-1">
                <img src={emptyusers_aid} alt="" />
                <p className="text-sm font-semibold text-primary-800">{t("message.aid_users_empty")}</p>
              </div>
            ) : (
              <>
                <div className="scrollbar-hide flex w-full shrink grow basis-0 flex-wrap content-start gap-2.5 overflow-y-auto pt-2">
                  {filteredUsers.memberList.map((user, index) => {
                    const _aid = strapiContent.data.find((aid) => aid.codeName === user.meta_cv.aid.id);
                    return (
                      <div
                        onClick={() => handleModalOpen(user)}
                        key={index}
                        className="flex h-[100px] w-[353px] cursor-pointer items-center gap-2 rounded-xl border border-neutral-300 bg-neutral-200 bg-opacity-60 px-4 py-2 max-lg:p-3"
                      >
                        <div className="relative flex h-[65px] w-[65px] shrink-0 items-center justify-center rounded-full bg-[#069BF8] max-lg:h-[65px] max-lg:w-[65px]">
                          <img
                            src={user.basic.profile_picture[0].id}
                            alt="profile"
                            className="h-[58px] w-[58px] rounded-full max-lg:h-[57px] max-lg:w-[57px]"
                          />
                          <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-2 text-xs  font-semibold text-neutral-100 max-lg:px-1.5 max-lg:text-[11px]">
                            LVL {user.basic.level[0].id}
                          </span>
                        </div>
                        <div className="flex w-[calc(100%-116px)] flex-col gap-0.5">
                          <p
                            title={user.basic.name[0].id}
                            className="overflow-hidden text-ellipsis whitespace-nowrap font-medium leading-tight text-primary-800"
                          >
                            {user.basic.name[0].id}
                          </p>
                          <p
                            title={user.work.job[0]?.name}
                            className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-neutral-900"
                          >
                            {user.work.job[0]?.name}
                          </p>
                          <p
                            title={user.basic.state[0]?.name + "," + user.basic.country[0]?.name}
                            className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-tight text-neutral-900"
                          >
                            {user.basic.state[0]?.name}, {user.basic.country[0]?.name}
                          </p>
                        </div>
                        <div className="flex shrink-0 flex-row flex-wrap gap-1">
                          <img
                            key={index}
                            src={_aid.badge0.url}
                            alt={_aid.name}
                            title={_aid.name}
                            className="h-[35px] w-[35px]"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="mt-auto w-full pt-2">
                  <Pagination
                    dataLength={filteredUsers.paginationInfo.total_row_count}
                    setPageSize={setPageSize}
                    currentPage={currentPage}
                    totalCount={filteredUsers.paginationInfo.total_row_count}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const AidListItem = ({ onClick, aid, active }) => {
  return (
    <div className={`cursor-pointer`} onClick={onClick}>
      <div
        className={`${
          active ? "border-secondary_recruiter-600 bg-neutral-100 shadow-md" : "border-transparent bg-transparent"
        } flex  items-center gap-2 rounded-xl border-[1.5px] p-2`}
      >
        <div
          style={{
            background: `linear-gradient(180deg, ${aid.primaryColor} 0%, #FFFFFF00 100%)`,
          }}
          className={`h-8 w-8 rounded-full shadow-[inset_0px_10px_8px_3px_#00000018] max-lg:h-6 max-lg:w-6`}
        >
          <img src={aid.logo.url} alt={aid.name} />
        </div>
        <p className="w-[calc(100%-40px)] overflow-hidden text-ellipsis whitespace-nowrap font-semibold leading-tight text-neutral-800">
          {aid.name} <span className="text-sm font-normal">{aid.title}</span>
        </p>
      </div>
    </div>
  );
};

export default HumanasIds;
