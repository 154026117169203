import { useNavigate } from "react-router-dom";
import { RightArrowOutlined } from "../OldDesign/Icons";
import Button from "./Button/Button";
import SectionTitle from "./SectionTitle";
import StepCard from "./StepCard";
import { HorizontalStepper, VerticalStepper } from "./Stepper";
import { useStore } from "../../store/useStore";
import { useTranslation } from "react-i18next";

const ApplicationFormOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const coreCV = useStore((state) => state.coreCV);
  const steps = [
    {
      stepNumber: 1,
      name: t("candidate.corecv_form.user_profile"),
      status: coreCV.data.identity_progress.steps.user_profile.status,
      subSteps: [
        {
          stepName: t("candidate.corecv_form.personal_info"),
          status: coreCV.data.identity_progress.steps.user_profile.subSteps.personal_information.status,
        },
        {
          stepName: t("candidate.corecv_form.contact_info"),
          status: coreCV.data.identity_progress.steps.user_profile.subSteps.contact_information.status,
        },
      ],
    },
    {
      stepNumber: 3,
      name: t("candidate.corecv_form.professional_info"),
      status: coreCV.data.identity_progress.steps.profession_details.status,
      subSteps: [
        {
          stepName: t("candidate.corecv_form.experiences"),
          status: coreCV.data.identity_progress.steps.profession_details.subSteps.experience_details.status,
        },
        {
          stepName: t("candidate.corecv_form.skills"),
          status: coreCV.data.identity_progress.steps.profession_details.subSteps.skill_details.status,
        },
        {
          stepName: t("candidate.corecv_form.work_pref"),
          status: coreCV.data.identity_progress.steps.profession_details.subSteps.working_preferences.status,
        },
      ],
    },
    {
      stepNumber: 8,
      name: t("candidate.corecv_form.educational_status"),
      status: coreCV.data.identity_progress.steps.educational_status.status,
      subSteps: [
        {
          stepName: t("candidate.corecv_form.education_info"),
          status: coreCV.data.identity_progress.steps.educational_status.subSteps.education_details.status,
        },
        {
          stepName: t("candidate.corecv_form.language_level"),
          status: coreCV.data.identity_progress.steps.educational_status.subSteps.language_details.status,
        },
      ],
    },
  ];

  const current_step = coreCV.data.identity_progress.current_step;
  const continueStep =
    current_step < 2
      ? "step1"
      : current_step < 7
      ? "step2"
      : current_step < 8
      ? "step3"
      : current_step < 9
      ? "step4"
      : "confirmation";
  const stepperStep = current_step < 2 ? 1 : current_step < 7 ? 2 : current_step < 9 ? 3 : 4;

  if (!coreCV.isLoaded) {
    return null;
  }
  return (
    <section className="container2 py-9 max-lg:py-4">
      {/* DESKTOP */}
      <div className="flex flex-row items-center justify-between border-l-[3px] border-l-primary-900 pl-3 max-lg:hidden">
        <div className="flex flex-col gap-3">
          <h2 className="text-[32px] font-semibold leading-tight text-primary-900">
            {t("candidate.corecv_form.application_form")}
          </h2>
          <p className="text-[20px] font-medium leading-tight">
            {t("candidate.corecv_form.application_form_explanation")}
          </p>
        </div>
        <div>
          <Button icon2={<RightArrowOutlined className="h-5 w-5" />} onClick={() => navigate(`/apply/${continueStep}`)}>
            {t("button.continue")}
          </Button>
        </div>
      </div>
      <div className="py-9 max-lg:hidden">
        <HorizontalStepper steps={steps} currentStep={stepperStep} />
      </div>
      {/* MOBILE */}
      <div className="lg:hidden">
        <div className="flex flex-row items-center">
          <SectionTitle title={t("candidate.corecv_form.application_form")} className="items-start" />
          <Button
            icon2={<RightArrowOutlined className="h-3 w-3" />}
            onClick={() => navigate(`/apply`, { state: { stepNumber: current_step + 1 } })}
            className=" p-2 text-[14px]"
          >
            {t("button.continue")}
          </Button>
        </div>
        <p className="pt-2 text-[13px] leading-tight text-primary-800">
          {t("candidate.corecv_form.application_form_explanation")}
        </p>
      </div>
      <div className="flex flex-row justify-center gap-4 max-lg:pt-4">
        <div className="self-center py-4 lg:hidden">
          <VerticalStepper steps={steps} currentStep={stepperStep} />
        </div>
        <div className="flex w-full flex-row gap-12 max-lg:flex-col max-lg:gap-4">
          {steps.map((step, index) => (
            <StepCard key={index} step={step} currentStep={stepperStep === index + 1} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ApplicationFormOverview;
