import { useState } from "react";
import Button from "../Button/Button";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../Forms/Form";
import { Field } from "../Forms/Field";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CloseOutlined } from "../../OldDesign/Icons";
import { EDUCATION_DEGREE } from "../constants";
import { ASelect, Input } from "../Forms/Input";
import { REACT_BASE_URL } from "../../../api/constants";
import { addYears, subYears } from "date-fns";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

const EducationalStatusForm = () => {
  const { t } = useTranslation();
  const coreCV = useStore((state) => state.coreCV);
  const [addingSchool, setAddingSchool] = useState(false);
  const [showSchoolOptions, setShowSchoolOptions] = useState(false);
  const [degree, setDegree] = useState(null);
  const [selectedSchools, setSelectedSchools] = useState(coreCV.data.educations_array);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const educationDegrees = EDUCATION_DEGREE();
  const {
    handleSubmit,
    control,
    getValues,
    register,
    reset,
    watch,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      institution_id: "",
      institution: "",
      degree: "",
      department: "",
      department_id: "",
      end_date: "",
      start_date: "",
    },
    mode: "onSubmit",
  });

  const navigate = useNavigate();

  const submitEducationalStatusForm = async (formData) => {
    if (selectedSchools.length > 0) {
      const payload = {
        educations_array: selectedSchools,
      };
      await updateApplicationForm(payload, () => navigate("../step5"));
    } else {
      setError("degree", { message: "Required" });
    }
  };
  const handleFormNavigateBack = () => {
    navigate("../step3");
  };

  const loadDegreeOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/educationlevel`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadInstitutionOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/institute?rows=200&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadDepartmentOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/departments?page=1&rows=100&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const checkAndInsertData = () => {
    const selected_institution = getValues("institution");
    const selected_degree = getValues("degree");
    const selected_department = getValues("department");
    const selected_end_date = getValues("end_date");
    const selected_start_date = getValues("start_date");
    const elementaries = ["primaryeducation", "secondaryeducation", "highschool"];
    const licence = ["associatedegree", "bachelorsdegree", "mastersdegree", "doctoraldegree"];
    if (elementaries.includes(selected_degree)) {
      if (selected_degree && selected_institution && selected_end_date && selected_start_date) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution,
            institution_id: 0,
            degree: selected_degree,
            department: "-1",
            department_id: 0,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        reset();
        clearErrors();
        setShowSchoolOptions(false);
        setAddingSchool(false);
      } else {
        trigger();
      }
    } else if (licence.includes(selected_degree)) {
      if (selected_degree && selected_institution && selected_department && selected_end_date && selected_start_date) {
        setSelectedSchools((prev) => [
          ...prev,
          {
            institution: selected_institution.institute_name,
            institution_id: selected_institution.institute_id,
            degree: selected_degree,
            department: selected_department.name,
            department_id: selected_department.id,
            end_date: selected_end_date.getFullYear() + "-1",
            start_date: selected_start_date.getFullYear() + "-1",
          },
        ]);
        reset();
        clearErrors();
        setShowSchoolOptions(false);
        setAddingSchool(false);
      } else {
        trigger();
      }
    }
  };
  return (
    <section className="min-h-[520px]">
      <SectionTitle className="items-start" title={t("candidate.corecv_form.educational_status")} />
      <Form id="user_profile" onSubmit={handleSubmit(submitEducationalStatusForm)}>
        <div className="flex gap-8 py-6">
          {addingSchool ? (
            <>
              <fieldset className="flex basis-1/2 flex-col gap-6">
                <Field label={t("cv_fields.degree")} error={errors?.degree}>
                  <Controller
                    control={control}
                    name="degree"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <ASelect
                        onChange={(e) => {
                          if (e !== null) {
                            onChange(e.id);
                            setShowSchoolOptions(true);
                            setDegree(e.id);
                          } else {
                            setDegree(null);
                            setShowSchoolOptions(false);
                          }
                        }}
                        onBlur={onBlur}
                        defaultOptions
                        loadOptions={loadDegreeOptions}
                        placeholder={t("placeholder.select")}
                        isSearchable
                        isClearable
                        getOptionLabel={(opt) => opt.name}
                        getOptionValue={(opt) => opt.id}
                        styles={{
                          control: (styles, state) => ({
                            ...styles,
                            borderRadius: "8px",
                            borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                            borderWidth: "1px",
                          }),
                          valueContainer: (styles, state) => ({
                            ...styles,
                            height: "50px",
                            paddingLeft: "16px",
                          }),
                        }}
                      />
                    )}
                  />
                </Field>
                {showSchoolOptions &&
                  (["primaryeducation", "secondaryeducation", "highschool"].includes(degree) ? (
                    <Field label={t("placeholder.school_name")} error={errors?.institution}>
                      <Input
                        {...register("institution", {
                          required: "Required",
                        })}
                        id="institution"
                        placeholder={t("placeholder.write")}
                      />
                    </Field>
                  ) : (
                    <Field label={t("placeholder.school_name")} error={errors?.institution}>
                      <Controller
                        control={control}
                        name="institution"
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <ASelect
                            cacheOptions={true}
                            defaultOptions
                            onChange={(e) => {
                              onChange(e);
                            }}
                            onBlur={onBlur}
                            loadOptions={loadInstitutionOptions}
                            placeholder={t("placeholder.select")}
                            isSearchable
                            getOptionLabel={(opt) => opt.institute_name}
                            styles={{
                              control: (styles, state) => ({
                                ...styles,
                                borderRadius: "8px",
                                borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                                borderWidth: "1px",
                              }),
                              valueContainer: (styles, state) => ({
                                ...styles,
                                height: "50px",
                                paddingLeft: "16px",
                              }),
                            }}
                          />
                        )}
                      />
                    </Field>
                  ))}
              </fieldset>
              <fieldset className="flex basis-1/2 flex-col gap-6">
                {showSchoolOptions && !["primaryeducation", "secondaryeducation", "highschool"].includes(degree) && (
                  <Field label={t("placeholder.department_name")} error={errors?.department}>
                    <Controller
                      control={control}
                      name="department"
                      rules={{ required: "Required" }}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <ASelect
                          cacheOptions={true}
                          defaultOptions
                          onChange={(e) => {
                            onChange(e);
                          }}
                          onBlur={onBlur}
                          loadOptions={loadDepartmentOptions}
                          placeholder={t("placeholder.select")}
                          isSearchable
                          getOptionLabel={(opt) => opt.name}
                          getOptionValue={(opt) => opt.id}
                          styles={{
                            control: (styles, state) => ({
                              ...styles,
                              borderRadius: "8px",
                              borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                              borderWidth: "1px",
                            }),
                            valueContainer: (styles, state) => ({
                              ...styles,
                              height: "50px",
                              paddingLeft: "16px",
                            }),
                          }}
                        />
                      )}
                    />
                  </Field>
                )}
                {showSchoolOptions && (
                  <div className="flex flex-row gap-8">
                    <Field label={t("placeholder.start_date")} error={errors?.start_date}>
                      <Controller
                        control={control}
                        name="start_date"
                        rules={{ required: "Required" }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DatePicker
                            selected={Date.parse(value)}
                            onChange={onChange}
                            selectsStart
                            startDate={value}
                            endDate={watch("start_date")}
                            maxDate={addYears(new Date(), 10)}
                            minDate={subYears(new Date(), 100)}
                            placeholderText={t("placeholder.select")}
                            dateFormat="yyyy"
                            showYearPicker
                            className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                          />
                        )}
                      />
                    </Field>
                    <Field label={t("placeholder.end_date")} error={errors?.end_date}>
                      <Controller
                        control={control}
                        name="end_date"
                        rules={{ required: "Required" }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DatePicker
                            selected={Date.parse(value)}
                            onChange={onChange}
                            selectsEnd
                            startDate={watch("start_date")}
                            endDate={value}
                            maxDate={addYears(new Date(), 10)}
                            minDate={subYears(new Date(), 100)}
                            placeholderText={t("placeholder.select")}
                            dateFormat="yyyy"
                            showYearPicker
                            className="h-[52px] w-full rounded-lg border-[1px] p-4 focus:outline-none"
                          />
                        )}
                      />
                    </Field>
                  </div>
                )}
              </fieldset>
            </>
          ) : (
            <div className="grid grid-cols-4 gap-3">
              <button
                type="button"
                onClick={() => setAddingSchool(true)}
                className="flex w-full items-center justify-center rounded-xl border border-dashed border-primary-500 bg-primary-50 p-3 lg:h-[80px]"
              >
                <span className="text-[16px] font-medium leading-tight text-primary-700">{t("button.add")} +</span>
              </button>
              {selectedSchools.map((school, index) => (
                <div
                  key={index}
                  className="flex w-full flex-row justify-between gap-4 rounded-xl border border-primary-500 bg-primary-50 p-3"
                >
                  <div className="text-[15px] font-medium leading-tight text-primary-700">
                    <h4 className="line-clamp-1 leading-tight">{school.institution}</h4>
                    <p className="line-clamp-1 text-sm leading-tight">
                      {school.department !== "-1" && school.department}
                    </p>
                    <p className="line-clamp-1 text-sm leading-tight">
                      {school.start_date.substring(0, 4)}-{school.end_date.substring(0, 4)}
                    </p>
                  </div>
                  <div className="flex items-start gap-2">
                    <button
                      onClick={(e) => setSelectedSchools((prev) => prev.filter((e, i) => i !== index))}
                      type="button"
                    >
                      <CloseOutlined className="h-5 w-5 text-primary-700" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex basis-full justify-between">
          <Button
            type="button"
            className="bg-neutral-100 px-12 py-4 text-primary-600"
            onClick={addingSchool ? () => setAddingSchool(false) : handleFormNavigateBack}
          >
            {t("button.back")}
          </Button>
          <div>
            <Button
              type="button"
              onClick={() => {
                checkAndInsertData();
              }}
              className={`${!addingSchool && "hidden"} border-success-400 bg-success-400 px-12 py-4`}
            >
              {t("button.add")}
            </Button>
            <Button disabled={isSubmitting} type="submit" className={`${addingSchool && "hidden"} px-12 py-4`}>
              {t("button.submit")}
            </Button>
          </div>
        </div>
      </Form>
    </section>
  );
};

export default EducationalStatusForm;
