import {
  AnalysisOutlined,
  ApplicationFilled,
  BagIcon,
  CommunityOutlined,
  CompassFilled,
  DashboardOutlined,
  EmployeeFilled,
  EyeFilled,
  HeartFilled,
  KeyFilled,
  MagicFilled,
  MetaCvOnlyLogo,
  NetworkOutlined,
  PeopleOutlined,
  SendFilled,
  UnlockFilled,
  BadgeAidFilled,
  Book2Filled,
  CNAFilled,
  FolderFilled,
  People2Filled,
  BookFilled,
  BadgeFilled,
  StarFilled,
} from "../OldDesign/Icons";
import i18n from "../../i18n";

export const INVITEES_STATUS_OPTIONS = [
  "invited",
  "account_linked",
  "game_launched",
  "completed_istanbul",
  "playing_istanbul",
  "completed_barcelona",
  "playing_barcelona",
  "completed_amsterdam",
  "playing_amsterdam",
  "filling_core_cv",
  "completed",
];

export const ORIENTATIONS = [
  { title: "Çalışma Tarzı" },
  { title: "Yaratıcılık" },
  { title: "Değişim" },
  { title: "Planlama" },
  { title: "Motivasyon" },
  { title: "Tolerans" },
  { title: "Sosyallik" },
  { title: "Estetik" },
  { title: "Baskınlık" },
  { title: "Çoklu iş yapma" },
  { title: "İş odağı" },
  { title: "İş takip" },
  { title: "Çeviklik" },
  { title: "Tempo" },
];

export const SCORE_TAGS = {
  priority_promotion: {
    icon: <BadgeFilled className="h-4 w-4" />,
    color: "text-success-400",
  },
  promotion: {
    icon: <StarFilled className="h-4 w-4" />,
    color: "text-card_candidate-500",
  },
  development: {
    icon: <BookFilled className="h-4 w-4" />,
    color: "text-primary-400",
  },
  intervention: {
    icon: <CompassFilled className="h-4 w-4" />,
    color: "text-neutral-600",
  },
  immediate_interview: {
    icon: <BadgeFilled className="h-4 w-4" />,
    color: "text-success-400",
  },
  interview: {
    icon: <StarFilled className="h-4 w-4" />,
    color: "text-card_candidate-500",
  },
  standby: {
    icon: <BookFilled className="h-4 w-4" />,
    color: " text-primary-400",
  },
  incompatible: {
    icon: <CompassFilled className="h-4 w-4" />,
    color: "text-neutral-600",
  },
};

export const CNA_NAV_LINKS = () => [
  {
    path: "overview",
    title: i18n.t("cna.nav_links.overview"),
    icons: {
      active: <CNAFilled className={"h-6 w-6 text-neutral-100"} />,
      passive: <CNAFilled className={"h-6 w-6 text-primary-800"} />,
    },
  },
  {
    path: "ideals",
    title: i18n.t("cna.nav_links.ideal_profiles"),
    icons: {
      active: <People2Filled className={"h-6 w-6 text-neutral-100"} />,
      passive: <People2Filled className={"h-6 w-6 text-primary-800"} />,
    },
  },
  {
    path: "userreports",
    title: i18n.t("cna.nav_links.individual_reports"),
    icons: {
      active: <FolderFilled className={"h-6 w-6 text-neutral-100"} />,
      passive: <FolderFilled className={"h-6 w-6 text-primary-800"} />,
    },
  },
  {
    path: "positionreport",
    title: i18n.t("cna.nav_links.position_reports"),
    icons: {
      active: <Book2Filled className={"h-6 w-6 text-neutral-100"} />,
      passive: <Book2Filled className={"h-6 w-6 text-primary-800"} />,
    },
  },
  {
    path: "aid",
    title: i18n.t("cna.nav_links.humanas_ids"),
    icons: {
      active: <BadgeAidFilled className={"h-6 w-6 text-neutral-100"} />,
      passive: <BadgeAidFilled className={"h-6 w-6 text-primary-800"} />,
    },
  },
];

export const JOBPOS_CORECV_FIELD_KEYS = () => {
  return {
    job_title: i18n.t("cv_fields.job_title"),
    age_range: i18n.t("cv_fields.age_range"),
    degree: i18n.t("cv_fields.degree"),
    job_title_experience: i18n.t("cv_fields.job_title_experience"),
    sector_experience: i18n.t("cv_fields.sector_experience"),
    work_state: i18n.t("cv_fields.work_state"),
    work_location: i18n.t("cv_fields.work_location"),
    work_time: i18n.t("cv_fields.work_time"),
    location: i18n.t("cv_fields.location"),
    skills: i18n.t("cv_fields.skills"),
    languages: i18n.t("cv_fields.languages"),
    universities: i18n.t("cv_fields.universities"),
    university_departments: i18n.t("cv_fields.university_departments"),
    about: i18n.t("cv_fields.about"),
  };
};

export const INVITEES_SORT_OPTIONS = () => [
  {
    sortby: "invite_date",
    ascending: 1,
    name: i18n.t("sort.invite_date_asc"),
  },
  {
    sortby: "invite_date",
    ascending: 0,
    name: i18n.t("sort.invite_date_desc"),
  },
  { sortby: "metacv_level", ascending: 1, name: i18n.t("sort.metacv_lvl_asc") },
  { sortby: "metacv_level", ascending: 0, name: i18n.t("sort.metacv_lvl_desc") },
];
export const CONNECTIONS_SORT_OPTIONS = () => [
  {
    sortby: "invite_date",
    ascending: 1,
    name: i18n.t("sort.invite_date_asc"),
  },
  {
    sortby: "invite_date",
    ascending: 0,
    name: i18n.t("sort.invite_date_desc"),
  },
  { sortby: "metacv_level", ascending: 1, name: i18n.t("sort.metacv_lvl_asc") },
  { sortby: "metacv_level", ascending: 0, name: i18n.t("sort.metacv_lvl_desc") },
];

export const CONNECTIONS_GROUPS = ["favourites", "reviews"];
export const CONNECTIONS_SOURCES = ["candidate", "employee"];
export const CONNECTIONS_WORKINFO = {
  work_time: [
    { id: 0, value: "fulltime" },
    { id: 1, value: "parttime" },
    { id: 2, value: "freelance" },
  ],
  work_state: [
    { id: 0, value: "employed" },
    { id: 1, value: "unemployed" },
  ],
  work_location: [
    { id: 0, value: "office" },
    { id: 1, value: "remote" },
    { id: 2, value: "hybrid" },
  ],
};
export const CONNECTIONS_PERSONALITY = {
  disa_donuk: [
    { id: 13, value: "baskin" },
    { id: 14, value: "sosyal" },
    { id: 15, value: "enerjik" },
  ],
  sorumlu: [
    { id: 4, value: "basari_odakli" },
    { id: 5, value: "oz_disiplinli" },
    { id: 6, value: "temkinli" },
    { id: 7, value: "guvenilir" },
    { id: 8, value: "oz_yeterli" },
    { id: 9, value: "duzenli" },
  ],
  deneyime_acik: [
    { id: 16, value: "yaratici" },
    { id: 17, value: "estetik_egilimi_olan" },
    { id: 18, value: "acik_fikirli" },
    { id: 19, value: "ic_gorulu" },
    { id: 20, value: "entelektuel" },
  ],
  uyumlu: [
    { id: 10, value: "duyarli" },
    { id: 11, value: "uzlasmaci" },
    { id: 12, value: "alcak_gonullu" },
  ],
  duygusal_denge_sahibi: [
    { id: 0, value: "rahat" },
    { id: 1, value: "uysal" },
    { id: 2, value: "iyimser" },
    { id: 3, value: "sogukkanli" },
  ],
};
export const CONNECTIONS_BADGE = {
  humanas_ids: [
    { id: 0, value: "braveheart" },
    { id: 1, value: "the_dealmaker" },
    { id: 2, value: "the_bridge" },
    { id: 3, value: "social_thinker" },
    { id: 4, value: "the_warrior" },
    { id: 5, value: "wanderlust" },
    { id: 6, value: "vanga" },
    { id: 7, value: "natural_ai" },
    { id: 8, value: "phoenix" },
    { id: 9, value: "voyager_1" },
    { id: 10, value: "justitia" },
    { id: 11, value: "connector" },
    { id: 12, value: "harmonious" },
    { id: 13, value: "maverick" },
  ],
};

export const ORIENTATION_LEVEL_DESCRIPTIONS = () => ({
  fully_balanced: i18n.t("orientation.fully_balanced"),
  strong: i18n.t("orientation.strong"),
  balanced: i18n.t("orientation.balanced"),
  extraordinary: i18n.t("orientation.extraordinary"),
});

export const AID_PAGES = () => [
  { path: "summary", name: i18n.t("common.summary"), ready: true },
  { path: "identities", name: i18n.t("common.identities"), ready: true },
];

export const AID_SUBPAGES = () => [
  { path: "overview", name: i18n.t("header.nav_links.overview") },
  { path: "sectors", name: i18n.t("aid.subpages.sectoral_approach") },
  { path: "interview", name: i18n.t("aid.subpages.interview_questions") },
  { path: "features", name: i18n.t("aid.subpages.features") },
];

export const COMPATIBILITY_STAT_COLORS = {
  0: "#14B8A6",
  1: "#A2D7D0",
  2: "#C2C2D6",
  3: "#9A9ABA",
  4: "#7B7B95",
};

export const ORIENTATION_STAT_COLORS = {
  siradisi: "#3469AD",
  guclu: "#7986E8",
  dengeli: "#82B7EA",
  tam_dengeli: "#77DEDB",
  balanced: "#82B7EA",
  fully_balanced: "#77DEDB",
  strong: "#7986E8",
  extraordinary: "#3469AD",
};

export const WORK_PREF_STAT_COLORS = {
  fulltime: "#6359E9",
  parttime: "#9ECBF6",
  freelance: "#E4E1F2",
  office: "#0075FF",
  hybrid: "#D7D7D7",
  remote: "#14B8A6",
};

export const MY_NETWORK_TABS = () => {
  return [
    { path: "connections", title: i18n.t("button.connections") },
    { path: "invitees", title: i18n.t("button.invitees") },
    { path: "groups", title: i18n.t("button.groups") },
    { path: "reports", title: i18n.t("button.reports") },
  ];
};

export const GROUP_FILTERS = () => {
  const GROUP_FILTERS = {
    all: i18n.t("filter.all"),
    favourites: i18n.t("filter.favourites"),
    unlocks: i18n.t("filter.unlocks"),
    archives: i18n.t("filter.archives"),
    reviews: i18n.t("filter.reviews"),
    invited: i18n.t("filter.invited"),
    account_linked: i18n.t("filter.account_linked"),
    playing: i18n.t("filter.playing"),
    filling_core_cv: i18n.t("filter.filling_core_cv"),
    completed: i18n.t("filter.completed"),
    work_time: i18n.t("filter.work_time"),
    work_location: i18n.t("filter.work_location"),
    work_state: i18n.t("filter.work_state"),
    parttime: i18n.t("filter.parttime"),
    fulltime: i18n.t("filter.fulltime"),
    freelance: i18n.t("filter.freelance"),
    employed: i18n.t("filter.employed"),
    unemployed: i18n.t("filter.unemployed"),
    office: i18n.t("filter.office"),
    hybrid: i18n.t("filter.hybrid"),
    remote: i18n.t("filter.remote"),
    employee: i18n.t("filter.employee"),
    candidate: i18n.t("filter.candidate"),
    clear: i18n.t("filter.clear"),
    rahat: i18n.t("filter.rahat"),
    uysal: i18n.t("filter.uysal"),
    iyimser: i18n.t("filter.iyimser"),
    sogukkanli: i18n.t("filter.sogukkanli"),
    basari_odakli: i18n.t("filter.basari_odakli"),
    oz_disiplinli: i18n.t("filter.oz_disiplinli"),
    temkinli: i18n.t("filter.temkinli"),
    guvenilir: i18n.t("filter.guvenilir"),
    oz_yeterli: i18n.t("filter.oz_yeterli"),
    duzenli: i18n.t("filter.duzenli"),
    duyarli: i18n.t("filter.duyarli"),
    uzlasmaci: i18n.t("filter.uzlasmaci"),
    alcak_gonullu: i18n.t("filter.alcak_gonullu"),
    baskin: i18n.t("filter.baskin"),
    sosyal: i18n.t("filter.sosyal"),
    enerjik: i18n.t("filter.enerjik"),
    yaratici: i18n.t("filter.yaratici"),
    estetik_egilimi_olan: i18n.t("filter.estetik_egilimi_olan"),
    acik_fikirli: i18n.t("filter.acik_fikirli"),
    ic_gorulu: i18n.t("filter.ic_gorulu"),
    entelektuel: i18n.t("filter.entelektuel"),
    disa_donuk: i18n.t("filter.disa_donuk"),
    sorumlu: i18n.t("filter.sorumlu"),
    deneyime_acik: i18n.t("filter.deneyime_acik"),
    uyumlu: i18n.t("filter.uyumlu"),
    duygusal_denge_sahibi: i18n.t("filter.duygusal_denge_sahibi"),
    braveheart: i18n.t("filter.braveheart"),
    the_dealmaker: i18n.t("filter.the_dealmaker"),
    the_bridge: i18n.t("filter.the_bridge"),
    social_thinker: i18n.t("filter.social_thinker"),
    the_warrior: i18n.t("filter.the_warrior"),
    wanderlust: i18n.t("filter.wanderlust"),
    vanga: i18n.t("filter.vanga"),
    natural_ai: i18n.t("filter.natural_ai"),
    phoenix: i18n.t("filter.phoenix"),
    voyager_1: i18n.t("filter.voyager_1"),
    justitia: i18n.t("filter.justitia"),
    connector: i18n.t("filter.connector"),
    harmonious: i18n.t("filter.harmonious"),
    maverick: i18n.t("filter.maverick"),
    humanas_ids: i18n.t("filter.humanas_ids"),
  };
  return GROUP_FILTERS;
};

export const HEADER_MENU_LINKS = () => ({
  candidate: [
    { title: i18n.t("header.menu_links.settings"), path: "/settings" },
    { title: i18n.t("header.menu_links.logout"), path: "/logout" },
  ],
  recruiter: [
    { title: i18n.t("header.menu_links.settings"), path: "/settings" },
    { title: i18n.t("header.menu_links.logout"), path: "/logout" },
  ],
});

export const NAV_LINKS = () => ({
  recruiter: [
    { path: "dashboard", title: i18n.t("header.nav_links.dashboard"), ready: true },
    {
      path: "community",
      title: i18n.t("header.nav_links.community"),
      ready: true,
    },
    {
      path: "mynetwork",
      title: i18n.t("header.nav_links.mynetwork"),
      ready: true,
    },
    {
      path: "aid",
      title: i18n.t("header.nav_links.aid"),
      ready: true,
    },
    {
      path: "cna",
      title: i18n.t("header.nav_links.cna"),
      ready: true,
    },
    {
      path: "boost",
      title: i18n.t("header.nav_links.boost"),
      ready: false,
    },
  ],
  candidate: [
    { path: "overview", title: i18n.t("header.nav_links.overview"), ready: true },
    {
      path: "community",
      title: i18n.t("header.nav_links.community"),
      ready: true,
    },
    {
      path: "aiobservation",
      title: i18n.t("header.nav_links.aiobservation"),
      ready: false,
    },
  ],
});

export const MOBILE_NAV_LINKS = () => ({
  recruiter: [
    {
      path: "dashboard",
      title: i18n.t("header.nav_links.dashboard"),
      icon: <DashboardOutlined className="h-[16px] w-[16px]" />,
      active: true,
    },
    {
      path: "mynetwork",
      title: i18n.t("header.nav_links.mynetwork"),
      icon: <CommunityOutlined className="h-[21px] w-[21px]" />,
      active: true,
    },
    {
      path: "cna",
      title: i18n.t("header.nav_links.cna"),
      icon: <NetworkOutlined className="h-[16px] w-[16px]" />,
      active: true,
    },
    {
      path: "aid",
      title: i18n.t("header.nav_links.aid"),
      icon: <PeopleOutlined className="h-[16px] w-[16px]" />,
      active: true,
    },
  ],
  candidate: [
    {
      path: "overview",
      title: i18n.t("header.nav_links.overview"),
      icon: <DashboardOutlined className="h-[20px] w-[20px]" />,
      active: true,
    },
    {
      path: "community",
      title: i18n.t("header.nav_links.community"),
      icon: <CommunityOutlined className="h-[25px] w-[25px]" />,
      active: true,
    },
    {
      path: "learnmore",
      title: "Learn more",
      icon: <AnalysisOutlined className="h-[20px] w-[20px]" />,
      active: false,
    },
    {
      path: "account",
      title: "Account",
      icon: <PeopleOutlined className="h-[20px] w-[20px]" />,
      active: false,
    },
  ],
});

export const WORK_STATE = () => [
  { value: "employed", label: i18n.t("filter.employed") },
  { value: "unemployed", label: i18n.t("filter.unemployed") },
];

export const WORK_LOCATION = [
  { value: "office", label: "Office" },
  { value: "remote", label: "Remote" },
  { value: "hybrid", label: "Hybrid" },
];

export const EXPERIENCE = [
  { job_experience_id: 1, job_experience_title: "0-1 year" },
  { job_experience_id: 2, job_experience_title: "1-3 year" },
  { job_experience_id: 3, job_experience_title: "3-5 year" },
  { job_experience_id: 4, job_experience_title: "5+ year" },
];

export const WORK_TIME = [
  { value: "fulltime", label: "Full Time" },
  { value: "parttime", label: "Part Time" },
  { value: "freelance", label: "Freelance" },
];

export const EDUCATION_DEGREE = () => [
  {
    id: "primaryeducation",
    title: i18n.t("common.primaryschool"),
  },
  {
    id: "secondaryeducation",
    title: i18n.t("common.secondaryschool"),
  },
  {
    id: "highschool",
    title: i18n.t("common.highschool"),
  },
  {
    id: "associatedegree",
    title: i18n.t("common.associatedegree"),
  },
  {
    id: "bachelorsdegree",
    title: i18n.t("common.bachelorsdegree"),
  },
  {
    id: "mastersdegree",
    title: i18n.t("common.mastersdegree"),
  },
  {
    id: "doctoraldegree",
    title: i18n.t("common.doctoraldegree"),
  },
];

export const LANGUAGE_PROFICIENCY = [
  { value: 0, label: "A1" },
  { value: 1, label: "A2" },
  { value: 2, label: "B1" },
  { value: 3, label: "B2" },
  { value: 4, label: "C1" },
  { value: 5, label: "C2" },
];

export const COMPANY_STATS = {
  total_employee: {
    icon: <EmployeeFilled className="h-[26px] w-[26px] text-primary-500 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-primary-75",
    content: "Sorumluluk alanınızda bulunan çalışanlara ait toplam Meta CV sayınız",
  },
  unlocks: {
    icon: <UnlockFilled className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px] " />,
    bgColor: "bg-warning-100",
    content: "Tam görüntülüme hakkına sahip olduğunuz toplam Meta CV sayısı",
  },
  favourites: {
    icon: <HeartFilled className="h-[26px] w-[26px] text-secondary_recruiter-600 max-lg:h-[22px] max-lg:w-[22px] " />,
    bgColor: "bg-secondary_recruiter-100",
    content: "Favori listenize aldığınız ve henüz görüntülenmemiş toplam Meta CV sayısı",
  },
  applications: {
    icon: <ApplicationFilled className="h-[26px] w-[26px] text-primary-600 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-primary-75",
    content: "İlanlarınıza şimdiye kadar gelen başvuru sayısı",
  },
  invited: {
    icon: <SendFilled className="h-[26px] w-[26px] text-success-400 max-lg:h-[22px]  max-lg:w-[22px]" />,
    bgColor: "bg-success-100",
    content: "Network'ünüze davet ettiğiniz toplam kullanıcı sayısı",
  },
  views: {
    icon: <EyeFilled className="h-[26px] w-[26px] text-card_candidate-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-card_candidate-100",
    content: "Görüntülediğiniz toplam Meta CV sayısı",
  },
  headhunter: {
    icon: <BagIcon className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-warning-100",
    content: "Toplam Headhunter sayısı",
  },
  company: {
    icon: <BagIcon className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-warning-100",
    content: "Toplam Company sayısı",
  },
  metacv_count: {
    icon: <MetaCvOnlyLogo className="h-[26px] w-[26px] text-warning-400 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Meta Cv Sayisi",
  },
  need_analysis: {
    icon: <MagicFilled className="h-[26px] w-[26px] text-chart-purple2 max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Ihtiyac Analizi",
  },
  find_the_one: {
    icon: <CompassFilled className="h-[26px] w-[26px] text-chart-green max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Find the One",
  },
  authorization: {
    icon: <KeyFilled className="h-[26px] w-[26px] text-chart-yellow max-lg:h-[22px] max-lg:w-[22px]" />,
    bgColor: "bg-neutral-100",
    content: "Yetkilendirme",
  },
};
