const renderRichText = (nodes) => {
  return nodes.map((node, index) => {
    if (node.type === "paragraph") {
      return (
        <p style={{ fontSize: "14px" }} className="mb-1" key={index}>
          {renderRichText(node.children)}
        </p>
      );
    }
    if (node.type === "heading") {
      const textSize =
        node.level === 1
          ? "26px"
          : node.level === 2
          ? "24px"
          : node.level === 3
          ? "22px"
          : node.level === 4
          ? "20px"
          : node.level === 5
          ? "18px"
          : "16px";
      return (
        <h1 style={{ fontSize: textSize }} className="my-2" key={index}>
          {renderRichText(node.children)}
        </h1>
      );
    }
    if (node.type === "bulleted-list") {
      return <ul key={index}>{renderRichText(node.children)}</ul>;
    }
    if (node.type === "numbered-list") {
      return <ol key={index}>{renderRichText(node.children)}</ol>;
    }
    if (node.type === "list-item") {
      return <li key={index}>{renderRichText(node.children)}</li>;
    }
    if (node.type === "image") {
      return (
        <img
          key={index}
          alt=""
          src={node.image.url}
          style={{ width: node.image.width, height: "auto" }}
          className="object-fit mb-1"
        />
      );
    }
    if (node.bold) {
      return <strong key={index}>{node.text}</strong>;
    }
    if (node.italic) {
      return <em key={index}>{node.text}</em>;
    }
    if (node.underline) {
      return <u key={index}>{node.text}</u>;
    }
    return node.text;
  });
};

export default renderRichText;
