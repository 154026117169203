import { useTranslation } from "react-i18next";
import { RecruiterOutlined } from "../OldDesign/Icons";

const ButtonRecruiter = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="group flex h-[180px] w-[147px] flex-col items-center justify-end gap-6 rounded-xl border border-neutral-300 p-9 font-medium leading-5 hover:bg-[#0973E0] hover:text-white max-lg:bg-[#0973E0] max-lg:text-white lg:h-[240px] lg:w-[190px] lg:gap-12"
    >
      <RecruiterOutlined />
      {t("common.recruiter")}
    </button>
  );
};

export default ButtonRecruiter;
