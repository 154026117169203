import { CommunityWorld } from "../../Illustrations";
import { useStore } from "../../../../store/useStore";
import { WorldIllustration } from "../../Illustrations";
import { useTranslation } from "react-i18next";

const HeroCommunityCandidate = ({ joined }) => {
  const { t } = useTranslation();
  const userName = useStore((state) => state.user.data.userName);
  return (
    <div className="max-lg:pb-2 max-lg:pt-[53px]">
      {joined ? (
        <>
          <div className="container2 relative my-3 flex items-center gap-12 rounded-xl bg-[linear-gradient(261deg,#316BCD_24.64%,#0D568A_99.34%)] px-16 py-9 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:px-4 max-lg:py-6 lg:h-[650px]">
            <div>
              <p className="pb-6 text-[22px] font-semibold leading-tight text-primary-50 max-lg:pb-3 max-lg:text-[14px]">
                {t("common.welcome")} {userName}
              </p>
              <p className="pb-8 text-[52px] font-light leading-tight max-lg:pb-3 max-lg:text-[22px]">
                {t("candidate.get_community_benefits")}
              </p>
              {/* <p className="text-[13px] text-transparent">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce posuere cursus sem, id mollis velit
                tristique ac. Nunc vel magna mi. Quisque feugiat augue justo, at iaculis magna pellentesque nec.
              </p> */}
            </div>
            <div className="right-2 top-2 max-lg:absolute">
              <WorldIllustration className="max-lg:w-[120px] lg:w-[500px]" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container2 flex items-center gap-14 rounded-xl bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-9 py-8 font-inter text-neutral-100 max-lg:flex-col max-lg:gap-6 max-lg:p-4">
            <div className="flex basis-2/3 flex-col justify-between">
              <div className="flex flex-col gap-6 pb-6 max-lg:gap-2 max-lg:pb-4">
                <h1 className="text-[32px] font-medium leading-tight max-lg:text-[18px]">
                  {t("candidate.join_community_header")}
                </h1>
                <p className="text-[22px] font-medium leading-tight max-lg:text-[13px] lg:text-justify">
                  {t("candidate.join_community_explanation")}
                </p>
              </div>
            </div>
            <div className="basis-1/3 max-lg:hidden">
              <CommunityWorld />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default HeroCommunityCandidate;
