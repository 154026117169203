import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getNetworkConnections } from "../../../api/endpoints";
import { User } from "../../../types/user";
import Loading from "../../OldDesign/Loading";
import ActionBar from "./ActionBar";
import { ConnectionsWorld } from "../Illustrations";
import { BirthdayFilled, CloseOutlined, LocationFilled, MailFilled, PhoneFilled } from "../../OldDesign/Icons";
import useIsMobile from "../../../utils/useIsMobile";
import MobileMetaCv from "../../MobileMetaCv/MobileMetaCv";
import CvHeader from "./CvHeader";
import CvCore from "./CvCore";
import ProfessionalOrientationSection from "./ProfessionalOrientationSection";
import CvAID from "./CvAID";
import CvInterviewQuestions from "./CvInterviewQuestions";
import CvComments from "./CvComments";
import CvFooter from "./CvFooter";
import { useTranslation } from "react-i18next";

interface Section {
  title: string;
  id: SectionId;
  ref: React.RefObject<HTMLDivElement | null>;
}

export type SectionId = "header" | "corecv" | "orientation" | "aid" | "questions";

const MetaCvView: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showTopBar, setShowTopBar] = useState<boolean>(false);
  const [metaCv, setMetaCv] = useState<User>({} as User);
  const IsMobile = useIsMobile();
  const navigate = useNavigate();

  const [activeSection, setActiveSection] = useState<SectionId>("header");

  const headerRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const corecvRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const orientationRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const aidRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const questionsRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const commentRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;
  const metacvModalRef = useRef<HTMLDivElement>(null) as React.RefObject<HTMLDivElement>;

  const sections: Section[] = [
    { title: t("modal.metacv.metacv_summary"), id: "header", ref: headerRef },
    { title: t("modal.metacv.corecv"), id: "corecv", ref: corecvRef },
    { title: t("modal.metacv.occupational_orientation"), id: "orientation", ref: orientationRef },
    { title: t("modal.metacv.humanas_id"), id: "aid", ref: aidRef },
    { title: t("modal.metacv.interview_questions"), id: "questions", ref: questionsRef },
  ];

  const controlHeader = useCallback(() => {
    if (metacvModalRef.current?.scrollTop && metacvModalRef.current.scrollTop > 340) {
      setShowTopBar(true);
    } else {
      setShowTopBar(false);
    }
  }, []);

  const handleScrollToCommentSection = () => {
    commentRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const handleScrollToSection = (id: SectionId) => {
    const scrollRef = sections.find((item) => item.id === id)?.ref;
    scrollRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (!id) return;

    getNetworkConnections(
      null,
      `/${id}`,
      ((res: { data: { rows: User[] } }) => {
        setMetaCv(res.data.rows[0]);
      }) as any,
      ((err: Error) => console.log(err)) as any,
      ((bool: boolean) => setIsLoading(bool)) as any,
    );
  }, [id]);

  useEffect(() => {
    const scrollableCointainer = metacvModalRef.current;
    if (!scrollableCointainer) return;

    const handleScroll = () => {
      const sectionsRefs: Array<{ ref: React.RefObject<HTMLDivElement>; id: SectionId }> = [
        { ref: headerRef, id: "header" },
        { ref: corecvRef, id: "corecv" },
        { ref: orientationRef, id: "orientation" },
        { ref: aidRef, id: "aid" },
        { ref: questionsRef, id: "questions" },
      ];

      let currentSection: SectionId = "header";

      sectionsRefs.forEach(({ ref, id }) => {
        if (ref.current) {
          const sectionTop = ref.current.getBoundingClientRect().top;
          const sectionHeight = ref.current.getBoundingClientRect().height;

          if (
            sectionTop <= scrollableCointainer.offsetHeight / 2 &&
            sectionTop + sectionHeight > scrollableCointainer.offsetHeight / 2.5
          ) {
            currentSection = id;
          }
        }
      });

      if (currentSection !== activeSection) {
        setActiveSection(currentSection);
      }
    };

    if (!isLoading && metaCv.id) {
      scrollableCointainer.addEventListener("scroll", handleScroll);
      scrollableCointainer.addEventListener("scroll", controlHeader);

      return () => {
        scrollableCointainer.removeEventListener("scroll", handleScroll);
        scrollableCointainer.removeEventListener("scroll", controlHeader);
      };
    }
  }, [isLoading, metaCv, activeSection, controlHeader]);

  if (isLoading) {
    return <Loading className="h-[calc(100vh-70px)]" />;
  }

  if (!metaCv.id) {
    return (
      <div className="p-20">
        <ConnectionsWorld className="mx-auto w-[600px] pb-9" />
        <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">Meta Cv Bulunamadı</p>
      </div>
    );
  }

  return (
    <>
      {IsMobile ? (
        <MobileMetaCv setMetaCv={setMetaCv} metaCv={metaCv} onClose={() => navigate(-1)} />
      ) : (
        <>
          <div className="absolute -top-10">
            <div className="fixed">
              {sections.map((section, index) => (
                <button
                  key={index}
                  onClick={() =>
                    section.ref.current?.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                      inline: "nearest",
                    })
                  }
                  className={`${index !== 0 && "ml-4"} ${
                    activeSection === section.id
                      ? " bg-neutral-100 font-semibold text-primary-600"
                      : "bg-primary-75 font-medium text-primary-300"
                  } trapezoid-container pb-5 pl-3 pt-3 leading-tight  transition-all`}
                  style={{ zIndex: activeSection === section.id ? 11 : 10 - index }}
                >
                  {section.title}
                </button>
              ))}
            </div>
          </div>
          <div className="absolute -top-12 right-[208px]">
            <div className="fixed flex items-center rounded-t-xl bg-neutral-100">
              <ActionBar
                handleScrollToCommentSection={handleScrollToCommentSection}
                user={metaCv}
                setMetaCv={setMetaCv}
              />
              <button className="mx-1.5 rounded-lg bg-card_candidate-100 p-2.5" onClick={() => navigate(-1)}>
                <CloseOutlined className="h-5 w-5 text-card_candidate-400" />
              </button>
            </div>
          </div>
          <div
            className={`${
              showTopBar ? "h-[70px] bg-neutral-100" : "h-0 bg-neutral-300 text-opacity-0"
            } fixed z-[1] flex w-[1240px] items-center justify-between overflow-hidden  px-6 shadow-lg transition-all duration-500`}
          >
            <div className="flex items-center gap-6">
              <div className="relative mb-2 inline-flex h-[50px] w-[50px] items-center justify-center rounded-full bg-[#069BF8] max-lg:h-[80px] max-lg:w-[80px]">
                <img
                  src={metaCv.basic.profile_picture[0]?.name ?? undefined}
                  alt="profile"
                  className="h-[42px] w-[42px] rounded-full max-lg:h-[72px] max-lg:w-[72px]"
                />
                <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100 max-lg:text-[11px]">
                  LVL {metaCv.basic.level[0].name}
                </span>
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-lg font-bold leading-tight text-primary-900">{metaCv.basic.name[0].name}</p>
                <p className="text-base font-medium leading-tight text-neutral-700">{metaCv.work.job[0]?.name}</p>
              </div>
            </div>
            <ul className="flex gap-4">
              <li className="text-sm leading-tight text-primary-900">
                <MailFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.communications.email[0]?.name}</span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <LocationFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">
                  {metaCv.basic.state[0]?.name || "n/a"}, {metaCv.basic.country[0]?.name || "n/a"}
                </span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <PhoneFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.communications.phone[0]?.name || "n/a"}</span>
              </li>
              <li className="text-sm leading-tight text-primary-900">
                <BirthdayFilled className="inline-block h-4 w-4 text-secondary_recruiter-700" />
                <span className="pl-1">{metaCv.basic.birth_date[0]?.name || "n/a"}</span>
              </li>
            </ul>
          </div>
          <div
            ref={metacvModalRef}
            className={`${
              showTopBar ? "mt-[70px] h-[calc(90vh-70px)]" : "mt-0 h-[calc(90vh)]"
            } relative mx-auto  grid  w-[1240px] grid-cols-1 gap-8 overflow-y-auto bg-neutral-100 px-9 pb-9 transition-all duration-500`}
          >
            <CvHeader handleScrollToSection={handleScrollToSection} ref={headerRef} user={metaCv} />
            <CvCore ref={corecvRef} user={metaCv} />
            <ProfessionalOrientationSection ref={orientationRef} user={metaCv} />
            <CvAID ref={aidRef} user={metaCv} />
            <CvInterviewQuestions ref={questionsRef} user={metaCv} />
            {metaCv.company.reviews.length > 0 && <CvComments user={metaCv} />}
            <CvFooter ref={commentRef} setUser={setMetaCv} user={metaCv} />
          </div>
        </>
      )}
    </>
  );
};

export default MetaCvView;
