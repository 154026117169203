import axios from "axios";
import { ENDPOINTS, REACT_BASE_URL } from "./constants";
import i18n from "../i18n";

const getRequestObject = ({
  requestType,
  payload = null,
  params = null,
  extendUrl = null,
  responseType = null,
  headers = null,
}) => {
  const { path, method, isAuthenticated } = ENDPOINTS[requestType];
  const url = `${REACT_BASE_URL}${path}${extendUrl !== null ? extendUrl : ""}`;

  const requestConfig = {
    method: method,
    url,
    params,
    headers,
  };

  if (isAuthenticated) {
    const token = localStorage.getItem("token");
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  // const storedLang = localStorage.getItem("lang");
  // const deviceLang = navigator.language.substring(0, 2);

  requestConfig.headers = {
    ...requestConfig.headers,
    "X-localization": i18n.language.slice(0, 2),
  };

  if (payload) {
    requestConfig.data = payload;
  }
  if (responseType) {
    requestConfig.responseType = responseType;
  }

  const requestObject = axios(requestConfig);

  return requestObject;
};

const handleFailure = (err, onError, data) => {
  // console.log("request err", err);
  // TODO: handle generic failure by toasters and a console log

  if (onError) onError(err);
};

const isRequestSuccess = (responseData) => {
  // TODO: Check api specific failure conditions
  if (responseData.status > 0) {
    return true;
  }
  if (responseData.status < 0) {
    return false;
  }

  return true;
};

export const sendApiRequest = async (options, onSuccess = null, onError = null, onLoading = null) => {
  if (onLoading) onLoading(true);
  const awaitableRequestObject = getRequestObject(options);
  try {
    const response = await awaitableRequestObject;
    const data = response.data;
    if (isRequestSuccess(data)) {
      if (onSuccess) onSuccess(data);
    } else {
      handleFailure(response, onError);
    }
  } catch (err) {
    handleFailure(err, onError);
  } finally {
    if (onLoading) onLoading(false);
  }
};

export const sendParallelApiRequests = async (optionsList, onSuccess = null, onError = null) => {
  const requestObjects = optionsList.map((item) => getRequestObject(item));
  axios
    .all(requestObjects)
    .then(
      axios.spread((...responses) => {
        // console.log("res>>", responses);
        const failed = responses.some((res) => !isRequestSuccess(res.data));
        if (failed) {
          handleFailure(null, onError);
        } else {
          // console.log("responses", responses);
          const responsesData = responses.map((res) => res.data);
          if (onSuccess) onSuccess(responsesData);
        }
      }),
    )
    .catch((err) => {
      // console.log("err", err);
      handleFailure(err);
    });
};
