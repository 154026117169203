import cnaroadmap from "../../../../assets/guides/guide_cnaroadmap.png";
import addposition from "../../../../assets/guides/guide_addposition.png";
import { useStore } from "store/useStore";
import renderRichText from "utils/renderRichText";
const CNAGuide = () => {
  const strapiGuideContent = useStore((state) => state.strapiGuideContent);
  const content = strapiGuideContent.data[0].guide[3].guidecontent[0].content;
  return (
    // <div className="flex flex-col gap-4">
    //   <div className="rounded-lg bg-neutral-200 p-4">
    //     <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
    //       <div className="lg:w-3/5">
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">CNA Nedir?</h3>
    //         <p className="text-sm text-neutral-800">
    //           Firma ihtiyaçlarının akıllı algoritmalar aracılığıyla tanımlanarak ihtiyaca yönelik çözüm yöntemlerine
    //           (modüllere) ulaşabildiği alan.
    //           <br />
    //           <br />
    //           İhtiyaç Yol Haritası, firmanızın gereklilikleri ve ihtiyaçlarınız doğrultusunda size özel bir plan
    //           oluşturur. Süreçlerinize iş pozisyonu uygunluk analiziyle başlayabilir ve ardından belirlediğiniz
    //           gereklilikler doğrultusunda hazırlanan İhtiyaç Yol Haritası üzerinden ilerleyebilirsiniz. Bu yöntem, iş
    //           süreçlerinizi adım adım planlamanıza olanak tanır ve iş hedeflerinize daha etkili ve verimli bir şekilde
    //           ulaşmanızı destekler.
    //         </p>
    //       </div>
    //       <div className="lg:w-2/5">
    //         <img src={cnaroadmap} alt="cna" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="rounded-lg bg-neutral-200 p-4">
    //     <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
    //       <div className="lg:w-1/2">
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Nasıl Pozisyon Eklenir?</h3>
    //         <p className="text-sm text-neutral-800">
    //           Pozisyon ekleme işlemini <b>CNA anasayfası</b>ndan veya <b>"İdeal Çalışan Profilleri"</b> sekmesinden
    //           kolayca gerçekleştirebilirsiniz. Pozisyon ismini ve gerekli pozisyon sayısını girerek, pozisyonlarınızı
    //           sisteminize pratik bir şekilde ekleyebilirsiniz.
    //         </p>
    //       </div>
    //       <div className="lg:w-1/2">
    //         <img src={addposition} alt="cna" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>{strapiGuideContent.isLoaded && renderRichText(content)}</>
  );
};

export default CNAGuide;
