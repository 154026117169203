import { HumanasLogo, LockIcon } from "../../components/OldDesign/Icons";
import { InputPassword } from "../../components/OldDesign/Inputs";
import { Button } from "../../components/OldDesign/Button";
import { useForm } from "react-hook-form";
import { useStore } from "../../store/useStore";
import { useTranslation } from "react-i18next";

const PasswordSet = () => {
  const { t } = useTranslation();
  const confirmUser = useStore((state) => state.confirmUser);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();
  const handlePasswordConfirmation = (formData) => {
    const payload = {
      new_password: formData.password,
    };
    confirmUser(payload);
  };

  return (
    <div
      className={`${
        window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
      } absolute left-1/2 inline-flex w-[360px] basis-1/2 -translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
    >
      <div className="absolute -top-16 self-center lg:hidden">
        <HumanasLogo className="text-neutral-100" />
      </div>
      <div className="mb-9 self-center max-lg:hidden">
        <HumanasLogo className="text-primary-600" />
      </div>
      <div className="pb-6">
        <p className="mb-3 border-l-2 border-l-[#080F47] pl-2 text-[20px] font-bold leading-6 text-[#080F47] lg:text-[24px] lg:leading-7">
          {t("auth.create_password")}
        </p>
        <p className="text-[14px] leading-5 text-neutral-800 lg:text-[16px]">{t("auth.please_create_password")}</p>
      </div>
      <form className="flex flex-col gap-1" onSubmit={handleSubmit(handlePasswordConfirmation)}>
        <InputPassword
          name="password"
          errors={errors}
          rules={{
            require: true,
            minLength: { value: 8, message: t("validation.min_char_required", { char_count: 8 }) },
          }}
          register={register}
          placeholder={t("placeholder.password")}
          icon={<LockIcon />}
        />
        <InputPassword
          name="password-repeat"
          errors={errors}
          rules={{
            require: true,
            validate: (val) => {
              if (watch("password") !== val) {
                setError("password", t("validation.password_not_match"));
                return t("validation.password_not_match");
              }
            },
          }}
          register={register}
          placeholder={t("placeholder.repeat_password")}
          icon={<LockIcon />}
        />

        <Button className="mt-5 lg:mt-28" type="submit" bgColor="#106BAC" txtColor="white">
          {t("button.submit")}
        </Button>
      </form>
    </div>
  );
};

export default PasswordSet;
