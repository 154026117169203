import { forwardRef } from "react";
import SectionTitle from "../SectionTitle";
import OrientationCard from "../OrientationCard";
import { User } from "types/user";
import { useTranslation } from "react-i18next";

interface OrientationProps {
  user: User;
}

const ProfessionalOrientationSection = forwardRef<HTMLDivElement, OrientationProps>(({ user }, ref) => {
  const { t } = useTranslation();
  return (
    <div id="orientation" ref={ref} className="flex flex-col gap-4">
      <SectionTitle
        title={t("modal.metacv.occupational_orientation")}
        className="items-start gap-1 pb-2 text-[22px] max-lg:hidden"
      />
      <div className="flex flex-col gap-6">
        {user.meta_cv.dimensions.map((orientation) => {
          // @ts-ignore
          // TODO: fix here after backend is updated
          return <OrientationCard key={orientation.title} orientation={orientation} />;
        })}
      </div>
    </div>
  );
});

export default ProfessionalOrientationSection;
