import { NavLink } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { MOBILE_NAV_LINKS } from "./constants";

const MobileNavbar = ({ hide }) => {
  const userType = useStore((state) => state.user.data.userType);
  const mobile_nav_links = MOBILE_NAV_LINKS();
  return (
    <div
      className={`${
        hide && "hidden"
      } fixed bottom-0 z-50 grid w-full grid-cols-4 bg-neutral-100 bg-opacity-80 backdrop-blur-sm lg:hidden`}
    >
      {mobile_nav_links[userType].map((link) => {
        return (
          <NavLink
            key={link.path}
            to={link.path}
            style={({ isActive, isPending }) => {
              return {
                borderTop: isActive ? "2px solid #062B45" : "2px solid #00000000",
                color: isActive ? "#062B45" : "#9A9ABA",
                pointerEvents: link.active ? "auto" : "none",
              };
            }}
            className="flex basis-1/4 flex-col items-center justify-between overflow-hidden px-1 py-2"
          >
            {link.icon}
            <p className="whitespace-nowrap text-[13px] font-medium leading-tight">{link.title}</p>
          </NavLink>
        );
      })}
    </div>
  );
};

export default MobileNavbar;
