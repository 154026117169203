import VerificationInput from "react-verification-input";
import { HumanasLogo } from "../../components/OldDesign/Icons";
import { Button } from "../../components/OldDesign/Button";
import CountdownTimer from "../../components/OldDesign/CountdownTimer";
import { useStore } from "../../store/useStore";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CodeActivation = () => {
  const { t } = useTranslation();
  const confirmUser = useStore((state) => state.confirmUser);
  const initializeSession = useStore((state) => state.initializeSession);
  const loginData = useStore((state) => state.loginData);
  const loginAction = useStore((state) => state.loginAction);
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const handleCodeConfirmation = (formData) => {
    if (loginAction.type === "email_auth") {
      initializeSession({
        ...loginData,
        password: formData.confirmation_code,
      });
    } else {
      confirmUser(formData);
    }
  };
  const handleResendCode = () => {
    confirmUser();
  };

  return (
    <div
      className={`${
        window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
      } absolute left-1/2 inline-flex w-[360px] basis-1/2 -translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
    >
      <div className="absolute -top-16 self-center lg:hidden">
        <HumanasLogo className="text-neutral-100" />
      </div>
      <div className="mb-9 self-center max-lg:hidden">
        <HumanasLogo className="text-primary-600" />
      </div>
      <div className="pb-6">
        <p className="mb-3 border-l-2 border-l-[#080F47] pl-2 text-[20px] font-bold leading-6 text-[#080F47] lg:text-[24px] lg:leading-7">
          {t("auth.activation_code")}
        </p>
        <p className="text-[14px] leading-4 text-neutral-800 lg:text-[16px] lg:leading-5">
          {t("auth.enter_activation_code")}
        </p>
      </div>
      <form className="flex flex-col" onSubmit={handleSubmit(handleCodeConfirmation)}>
        <div className="mx-auto mb-4">
          <Controller
            control={control}
            name="confirmation_code"
            rules={{
              required: t("validation.code_invalid"),
              minLength: { value: 6, message: t("validation.code_invalid") },
              maxLength: { value: 6, message: t("validation.code_invalid") },
            }}
            render={({ field: { ref, ...field }, fieldState: { ...fieldState } }) => (
              <VerificationInput
                errors={errors}
                {...fieldState}
                {...field}
                ref={ref}
                placeholder=""
                validChars="0-9"
                classNames={{
                  container: "w-[310px] h-[46px] lg:w-[400px] lg:h-[60px]",
                  character: `${
                    errors.confirmation_code ? "text-error-400 border-error-400" : "text-primary-600 border-primary-200"
                  } bg-neutral-100 rounded-md outline-none text-[36px] font-bold flex items-center justify-center`,
                  characterSelected: `character-selected-verification-input ${
                    errors.confirmation_code ? "border-error-400" : "border-primary-600"
                  }`,
                  characterInactive: ` ${errors.confirmation_code ? "border-error-400" : "border-primary-200"}`,
                }}
              />
            )}
          />
          {errors.confirmation_code && (
            <p className="pt-1 text-[13px] text-error-400">{errors.confirmation_code.message}</p>
          )}
        </div>
        <div className="self-start">
          <CountdownTimer expire_time={180} />
        </div>
        <Button className="mt-9 lg:mt-40" type="submit" bgColor="#106BAC" txtColor="white">
          {t("button.code_send")}
        </Button>
      </form>
      <p
        className={`mt-auto text-center text-[16px] leading-5 text-neutral-700 max-lg:absolute max-lg:-bottom-10  max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap`}
      >
        {t("auth.didnt_receive_the_code")}
        <span className="cursor-pointer font-medium text-primary-1000" onClick={() => handleResendCode()}>
          {" "}
          {t("button.send_again")}
        </span>
      </p>
    </div>
  );
};

export default CodeActivation;
