import SectionTitle from "../../../components/NewDesign/SectionTitle";
import {
  MetaCVEnvelop,
  MetaCVMagnifier,
  PeopleAndGears,
  StarsSparkling,
} from "../../../components/NewDesign/Illustrations";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const WhoAreWe = () => {
  const { t } = useTranslation();
  const WHO_WE_ARE = [
    {
      title: t("community.who_we_are.we_are1_title"),
      content: t("community.who_we_are.we_are1_description"),
      icon: <MetaCVEnvelop />,
    },
    {
      title: t("community.who_we_are.we_are2_title"),
      content: t("community.who_we_are.we_are2_description"),
      icon: <MetaCVMagnifier />,
    },
    {
      title: t("community.who_we_are.we_are3_title"),
      content: t("community.who_we_are.we_are3_description"),
      icon: <PeopleAndGears />,
    },
  ];
  return (
    <div className="relative rounded-xl bg-primary-75 p-6 max-lg:p-3">
      <SectionTitle
        title={t("community.who_we_are.header")}
        className="pb-6 max-lg:items-start max-lg:pb-4 max-lg:text-[15px]"
      />
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".swiper-whoweare-pagination",
        }}
        className=""
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          1080: { slidesPerView: 3 },
        }}
      >
        {WHO_WE_ARE.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="relative flex h-[155px] items-center gap-4 rounded-xl bg-[linear-gradient(180deg,#106BAC_0%,#6FB6FF_100%)] px-4 py-6 max-lg:px-3 max-lg:py-4">
              <StarsSparkling className="absolute right-0 top-0 h-full" />
              <div className="">
                <h4 className="pb-3 text-[22px] font-semibold leading-tight text-neutral-100 max-lg:text-[18px]">
                  {item.title}
                </h4>
                <p className="text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-[14px]">
                  {item.content}
                </p>
              </div>
              <div className="relative">{item.icon}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-whoweare-pagination text-center" slot="pagination" />
    </div>
  );
};

export default WhoAreWe;
