import SectionTitle from "../SectionTitle";
import userPP from "../../../user.png";
import { User } from "types/user";
import { useTranslation } from "react-i18next";

interface CvCommentsProps {
  user: User;
}

const CvComments = ({ user }: CvCommentsProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SectionTitle title={t("modal.metacv.comments")} className={"items-start gap-1 text-[20px] max-lg:hidden"} />
      <div className="flex flex-col gap-2">
        {user.company.reviews.map((review, index) => (
          <div key={index} className="flex gap-2 max-lg:p-2">
            <img src={userPP} alt="" className="h-[50px] w-[50px] rounded-full" />
            <div className="flex flex-1 flex-col gap-1.5">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium leading-tight text-neutral-700">{review.reviewer?.name}</p>
                <span className="text-sm font-medium leading-tight text-neutral-700">{review.date?.id}</span>
              </div>
              <div className="rounded-r-3xl rounded-bl-3xl border border-neutral-300 bg-neutral-200 p-4">
                <p className="text-sm leading-tight text-primary-900">{review.review?.id}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CvComments;
