import { useEffect, useState } from "react";
import CircularProgressBar from "../../../components/NewDesign/StatusBar/CircularProgressBar";
import {
  CommunityOutlined,
  EditOutlined,
  EyeFilled,
  PeopleFilled,
  RubbishOutlined,
  TickOutlined,
} from "../../../components/OldDesign/Icons";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";
import { ConnectionsWorld } from "../../../components/NewDesign/Illustrations";
import DeleteConfirmation from "../../../components/NewDesign/Popups/ConfirmationPopup";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/NewDesign/Button/Button";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const getAllReports = useStore((state) => state.getAllReports);
  const savedReports = useStore((state) => state.savedReports);
  const deleteReport = useStore((state) => state.deleteReport);

  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteReportId, setDeleteReportId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleViewGroupReport = (id, isSelfReport) => {
    navigate(`/group_report/${id}`, {
      state: {
        previousLocation: location,
        selfReport: isSelfReport,
        savedReport: true,
      },
    });
  };

  const handleDeleteReport = (id) => {
    const deleteReportName = savedReports.data.find((report) => report.id === id).name;
    setDeleteMsg(t("message.are_you_sure_delete_group", { groupname: deleteReportName }));
    setDeleteReportId(id);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    getAllReports();
  }, [getAllReports]);

  return (
    <div className="flex flex-col gap-4">
      {/* columns */}
      <div className="flex items-center justify-between rounded-xl bg-neutral-200 px-4 py-3 max-lg:hidden">
        <div className="basis-[25%]">
          <p className="text-[13px] font-medium leading-tight text-primary-900">
            {t("table.name") + "/" + t("table.date")}
          </p>
        </div>
        <div className="basis-[5%] text-center">
          <p className="text-[13px] font-medium leading-tight text-primary-900">{t("table.score")}</p>
        </div>
        <div className="basis-[25%] text-center">
          <p className="text-[13px] font-medium leading-tight text-primary-900">{t("table.description")}</p>
        </div>
        <div className="basis-[15%] text-center">
          <p className="text-[13px] font-medium leading-tight text-primary-900">{t("table.ref_group")}</p>
        </div>
        <div className="basis-[15%] text-center">
          <p className="text-[13px] font-medium leading-tight text-primary-900">{t("table.target_group")}</p>
        </div>
        <div className="basis-[15%] text-center">
          <p className="text-[13px] font-medium leading-tight text-primary-900">{t("table.actions")}</p>
        </div>
      </div>
      {/* list */}
      {savedReports.isLoading ? (
        <Loading />
      ) : !savedReports.data.length ? (
        <div className="py-12">
          <ConnectionsWorld className="mx-auto w-1/2 pb-9" />
          <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
            {t("message.reports_empty")}
          </p>
        </div>
      ) : (
        <>
          {savedReports.data.map((report) => (
            <ReportRow
              key={report.id}
              report={report}
              handleDeleteReport={handleDeleteReport}
              handleViewGroupReport={handleViewGroupReport}
            />
          ))}
        </>
      )}
      <DeleteConfirmation
        message={deleteMsg}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        id={deleteReportId}
        confirmModal={deleteReport}
        confirmationTopic={t("button.delete")}
      />
    </div>
  );
};

const ReportRow = ({ report, handleDeleteReport, handleViewGroupReport }) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(report.name);
  const [description, setDescription] = useState(report.description);
  const editReport = useStore((state) => state.editReport);

  const handleEditReport = () => {
    const formData = {
      id: report.id,
      name: name,
      description: description,
    };
    const isChanged = name !== report.name || description !== report.description;
    if (editMode && isChanged) {
      editReport(formData);
    }
    setEditMode(false);
  };

  return (
    <div className="flex justify-between rounded-xl border border-secondary_recruiter-300 bg-white px-4 py-2 shadow max-lg:flex-col lg:items-center">
      {/* name */}
      <div className="flex basis-[25%] items-center justify-start max-lg:gap-2 ">
        <div className="flex justify-center lg:invisible lg:h-0 lg:w-0">
          {report.is_self_compare ? (
            <div className="rounded-full bg-primary-75 p-2">
              <CommunityOutlined className="h-5 w-5 text-primary-700" />
            </div>
          ) : (
            <CircularProgressBar value={report.match_value} small />
          )}
        </div>
        <div className="flex w-full flex-col items-start justify-center gap-1 lg:pr-2">
          <input
            type="text"
            disabled={!editMode}
            readOnly={!editMode}
            onChange={(e) => setName(e.target.value)}
            value={name}
            className="w-full rounded-md border border-primary-300 bg-neutral-200  text-base font-medium leading-tight text-primary-900 outline-none disabled:border-none disabled:bg-neutral-100"
          />
          <div className=" text-[13px] leading-tight text-neutral-700">{report.create_date}</div>
        </div>
      </div>
      {/* score */}
      <div className="flex basis-[5%] justify-center max-lg:hidden">
        {report.is_self_compare ? (
          <div className="rounded-full bg-primary-75 p-3">
            <CommunityOutlined className="h-7 w-7 text-primary-700" />
          </div>
        ) : (
          <CircularProgressBar value={report.match_value} small />
        )}
      </div>
      {/* description */}
      <div className="flex basis-[25%] items-center max-lg:py-2 lg:pr-2">
        <textarea
          type="text"
          rows={2}
          disabled={!editMode}
          readOnly={!editMode}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          className="line-clamp-2 w-full resize-none rounded-md border border-primary-300 bg-neutral-200 text-[15px] font-normal leading-tight text-primary-900 outline-none disabled:border-none disabled:bg-neutral-100 lg:text-center"
        />
      </div>
      {/* reference */}
      <div className="flex basis-[15%] flex-col items-center justify-center gap-1 self-stretch max-lg:flex-row max-lg:justify-start">
        <p className="text-[13px] leading-tight text-neutral-700 max-lg:w-[75px] lg:hidden">{t("common.reference")}:</p>
        <p className="text-[15px] font-medium leading-tight text-primary-900">{report.reference_group_name}</p>
        <div className="flex items-center justify-start gap-0.5 text-secondary_recruiter-500">
          <PeopleFilled className="h-[14px] w-[14px]" />
          <span className="text-[13px] font-medium leading-tight">{report.member_count_reference}</span>
        </div>
      </div>
      {/* target */}
      <div className="flex basis-[15%] flex-col items-center justify-center gap-1 self-stretch max-lg:flex-row max-lg:justify-start">
        {!report.is_self_compare && (
          <>
            <p className="text-[13px] leading-tight text-neutral-700 max-lg:w-[75px] lg:hidden">
              {t("common.target")}:
            </p>
            <p className="text-[15px] font-medium leading-tight text-primary-900">{report.target_group_name}</p>
            <div className="flex items-center justify-start gap-0.5 text-secondary_recruiter-500">
              <PeopleFilled className="h-[14px] w-[14px]" />
              <span className="text-[13px] font-medium leading-tight">{report.member_count_target}</span>
            </div>
          </>
        )}
      </div>
      {/* actions */}
      <div className="flex basis-[15%] items-start gap-2 max-lg:pt-2 lg:justify-center">
        <Button
          type="button"
          className="rounded-lg border-none bg-primary-75 px-2.5 py-2.5 max-lg:px-2.5 max-lg:py-2.5"
          onClick={() => handleViewGroupReport(report.id, report.is_self_compare)}
        >
          <EyeFilled className="h-5 w-5 text-primary-400" />
        </Button>
        {!editMode ? (
          <Button
            type="button"
            onClick={() => setEditMode(true)}
            className="rounded-lg border-none bg-success-100 px-2.5 py-2.5 max-lg:px-2.5 max-lg:py-2.5"
          >
            <EditOutlined className="h-5 w-5 text-card_candidate-300" />
          </Button>
        ) : (
          <Button
            onClick={handleEditReport}
            type="button"
            className="rounded-lg border-none bg-success-100 px-2.5 py-2.5 max-lg:px-2.5 max-lg:py-2.5"
          >
            <TickOutlined className="h-5 w-5 text-card_candidate-300" />
          </Button>
        )}

        <Button
          type="button"
          className="rounded-lg border-none bg-card_candidate-100 px-2.5 py-2.5 max-lg:px-2.5 max-lg:py-2.5"
          onClick={() => handleDeleteReport(report.id)}
        >
          <RubbishOutlined className="h-5 w-5 text-card_candidate-400" />
        </Button>
      </div>
    </div>
  );
};

export default Reports;
