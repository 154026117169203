import { useCallback, useEffect, useRef, useState } from "react";
import { BagIconFilled, CloseCircleIcon, EmployeeFilled, RightArrowOutlined, SendFilled } from "../OldDesign/Icons";
import { TagWithIcon } from "../NewDesign/Tags";
import ActionBar from "../NewDesign/PrintableCv/ActionBar";
import CvFooter from "../NewDesign/PrintableCv/CvFooter";
import Avatar from "../NewDesign/Avatars/Avatar";
import CvAID from "../NewDesign/PrintableCv/CvAID";
import CvHeader from "../NewDesign/PrintableCv/CvHeader";
import CvCore from "../NewDesign/PrintableCv/CvCore";
import ProfessionalOrientationSection from "../NewDesign/PrintableCv/ProfessionalOrientationSection";
import CvInterviewQuestions from "../NewDesign/PrintableCv/CvInterviewQuestions";
import CvComments from "../NewDesign/PrintableCv/CvComments";
import { User } from "types/user";
import { useTranslation } from "react-i18next";

interface MobileMetaCvProps {
  setMetaCv: (user: User) => void;
  metaCv: User;
  onClose: () => void;
}

const MobileMetaCv = ({ setMetaCv, metaCv, onClose }: MobileMetaCvProps) => {
  const { t } = useTranslation();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showTopScroller, setShowTopScroller] = useState(false);
  const metaCvRef = useRef(null);
  const controlHeader = useCallback(() => {
    if (metaCvRef.current.scrollTop > 340) {
      // if scroll down hide the navbar
      // console.log("gorun");
      setShowTopScroller(true);
    } else {
      // if scroll up show the navbar
      // console.log("gizle");
      setShowTopScroller(false);
    }
  }, []);
  const handleScrollToCommentSection = () => {
    setActiveTabIndex(tabs.length - 1);
  };
  const handleCloseOverlay = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleTabChange = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleTopScroll = () => {
    metaCvRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const tabs = [
    { title: t("modal.metacv.metacv_summary"), component: <CvHeader user={metaCv} /> },
    { title: t("modal.metacv.corecv"), component: <CvCore user={metaCv} /> },
    { title: t("modal.metacv.personality_type"), component: <ProfessionalOrientationSection user={metaCv} /> },
    { title: t("modal.metacv.humanas_id"), component: <CvAID user={metaCv} /> },
    { title: t("modal.metacv.interview_questions"), component: <CvInterviewQuestions user={metaCv} /> },
    {
      title: t("modal.metacv.comments"),
      component: (
        <>
          <CvComments user={metaCv} />
          <CvFooter setUser={setMetaCv} user={metaCv} />
        </>
      ),
    },
  ];

  useEffect(() => {
    const scrollableCointainer = metaCvRef.current;
    // We only attach the scroll listener once the data is loaded
    if (scrollableCointainer) {
      // Add the scroll event listener
      scrollableCointainer.addEventListener("scroll", controlHeader);
      // Remove the scroll event listener when component unmounts
      return () => {
        scrollableCointainer.removeEventListener("scroll", controlHeader);
      };
    }
  }, [controlHeader]);

  return (
    <div ref={metaCvRef} className="fixed left-0 top-0 z-[100] h-screen w-screen overflow-y-scroll bg-neutral-100">
      <button className="fixed right-2 top-2 z-50" onClick={handleCloseOverlay}>
        <CloseCircleIcon className="h-6 w-6" color="" />
      </button>

      <div className="w-full rounded-b-3xl bg-primary-50 ">
        <div className="px-4 pb-2 pt-6">
          <div className="flex flex-row justify-start gap-2 pb-2">
            <Avatar user={metaCv} />
            <div className="flex flex-col items-start justify-between">
              <h2 className="text-[14px] font-semibold leading-tight text-primary-900">{metaCv.basic.name[0]?.id}</h2>
              <p className="text-[13px] leading-tight text-neutral-900">{metaCv.work.job[0]?.name}</p>
              <div className="flex justify-center gap-2">
                <TagWithIcon
                  icon={
                    metaCv.company.is_employee[0].id ? (
                      <EmployeeFilled className="h-[14px] w-[14px]" />
                    ) : (
                      <SendFilled className="h-[14px] w-[14px]" />
                    )
                  }
                  className={`bg-secondary_recruiter-100 px-2 py-1 text-secondary_recruiter-700`}
                  text={metaCv.company.is_employee[0].name}
                />
                <TagWithIcon
                  icon={<BagIconFilled className="h-[14px] w-[14px]" />}
                  className={`${
                    metaCv.work.work_state[0].id === "unemployed"
                      ? "bg-success-100 text-success-400"
                      : "bg-error-100 text-error-300"
                  }  px-2 py-1 `}
                  text={metaCv.work.work_state[0].name}
                />
              </div>
            </div>
          </div>
          <ActionBar handleScrollToCommentSection={handleScrollToCommentSection} user={metaCv} setMetaCv={setMetaCv} />
        </div>
        <div className="scrollbar-hide flex items-center gap-1.5 overflow-x-auto p-1">
          {tabs.slice(0, tabs.length - 1).map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabChange(index)}
              className={`${
                activeTabIndex === index
                  ? "border-b-[1.5px] border-primary-700 font-semibold text-primary-700"
                  : "border-b-[0px] border-neutral-100 font-medium text-neutral-400"
              } mx-1 my-2 whitespace-nowrap text-sm  leading-tight transition-all`}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>
      <div className="p-2 pb-20">{tabs[activeTabIndex].component}</div>

      <button
        type="button"
        onClick={() => handleTopScroll()}
        className={`${
          showTopScroller ? "bottom-6 right-6" : "-bottom-20 right-6"
        } fixed z-50 rounded-full bg-primary-600 p-3 transition-all lg:hidden`}
      >
        <RightArrowOutlined className={"h-4 w-4 -rotate-90 text-neutral-100"} />
      </button>
    </div>
  );
};

export default MobileMetaCv;
