import Button from "../Button/Button";
import SectionTitle from "../SectionTitle";
import { useStore } from "../../../store/useStore";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form } from "../Forms/Form";
import { Field, FieldCheckbox } from "../Forms/Field";
import { WORK_STATE } from "../constants";
import { ASelect, Checkbox, CrASelect } from "../Forms/Input";
import { REACT_BASE_URL } from "../../../api/constants";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import i18n from "i18n";

const JobDescriptionForm = () => {
  const { t } = useTranslation();
  const coreCV = useStore((state) => state.coreCV);

  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const workStates = WORK_STATE();

  let defaultWorkStateValue = "";
  if (coreCV.data.work_state) {
    defaultWorkStateValue = workStates.find((s) => s.value === coreCV.data.work_state);
  }
  let defaultWorkTimeValue = "";
  if (coreCV.data.work_time) {
    defaultWorkTimeValue = Object.keys(coreCV.data.work_time).filter((key) => coreCV.data.work_time[key] === true);
  }
  let defaultWorkLocationValue = "";
  if (coreCV.data.work_location) {
    defaultWorkLocationValue = Object.keys(coreCV.data.work_location).filter(
      (key) => coreCV.data.work_location[key] === true,
    );
  }

  const {
    handleSubmit,
    control,
    watch,
    register,
    setError,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      user_job: coreCV.data.user_job.job_title_id
        ? {
            job_id: coreCV.data.user_job.job_title_id,
            job_name: coreCV.data.user_job.job_name,
            job_experience_id: 1,
            job_experience_title: 1,
          }
        : "",
      work_state: defaultWorkStateValue,
      work_location: defaultWorkLocationValue,
      work_time: defaultWorkTimeValue,
      skills_array: coreCV.data.skills_array.length > 0 ? coreCV.data.skills_array : [],
    },
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const submitJobDescriptionForm = async (formData) => {
    const work_state = formData.work_state.value;
    const work_time = {
      parttime: formData.work_time.includes("parttime"),
      fulltime: formData.work_time.includes("fulltime"),
      freelance: formData.work_time.includes("freelance"),
    };
    const work_location = {
      remote: formData.work_location.includes("remote"),
      hybrid: formData.work_location.includes("hybrid"),
      office: formData.work_location.includes("office"),
    };
    const user_job = {
      job_title_id: formData.user_job.job_id,
      job_name: formData.user_job.job_name,
      job_experience_name: 1,
      job_experience_id: 1,
    };
    const skills_array = formData.skills_array.map((skill) => {
      return { ...skill, skill_proficiency: 0 };
    });
    const payload = {
      skills_array: skills_array,
      user_job: user_job,
      work_state: work_state,
      work_location: work_location,
      work_time: work_time,
    };
    // console.log(formData, payload);
    if (formData.skills_array.length > 0) {
      await updateApplicationForm(payload, () => navigate("../step3"));
    } else {
      setError("skills_array", { message: "Required" });
    }
  };

  const handleFormNavigateBack = () => {
    navigate("../step1");
  };

  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "X-localization": i18n.language.slice(0, 2),
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const loadSkillsOptions = async (value = "") => {
    const apiKey = localStorage.getItem("token");
    const selectedJob = watch("user_job");
    const jobId = selectedJob?.job_id || "";

    const res = await fetch(
      `${REACT_BASE_URL}/content/skill?rows=100&page=1&job_id[]=${jobId}&keyword=${encodeURIComponent(value)}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "X-localization": i18n.language.slice(0, 2),
        },
      },
    );
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const atLeastOneOptionChecked = (selectedOpts) => {
    return selectedOpts.length > 0;
  };

  return (
    <section className="">
      <SectionTitle className="items-start" title={t("candidate.corecv_form.professional_info")} />
      <Form id="user_profile" onSubmit={handleSubmit(submitJobDescriptionForm)}>
        <div className="grid grid-cols-2 gap-6 py-6">
          <fieldset className="flex flex-col gap-6">
            <Field label={t("cv_fields.job_title")} error={errors?.user_job}>
              <Controller
                control={control}
                name="user_job"
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CrASelect
                    defaultValue={value}
                    selected={value}
                    onBlur={onBlur}
                    loadOptions={loadJobTitleOptions}
                    onChange={onChange}
                    placeholder={t("placeholder.select")}
                    getOptionLabel={(opt) => opt.job_name}
                    getOptionValue={(opt) => opt.job_id}
                    getNewOptionData={(inputValue, optionLabel) => ({
                      job_name: inputValue,
                      __isNew__: true,
                    })}
                  />
                )}
              />
            </Field>
            <Field label={t("candidate.corecv_form.skills")} error={errors?.skills_array}>
              <Controller
                control={control}
                rules={{ required: "Required" }}
                name="skills_array"
                key={watch("user_job")?.job_id}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <ASelect
                    isMulti
                    defaultValue={value}
                    selected={value}
                    onBlur={onBlur}
                    defaultOptions={watch("user_job")?.job_id ? loadSkillsOptions() : false}
                    loadOptions={loadSkillsOptions}
                    isOptionDisabled={(opt) => value.length >= 20}
                    onChange={onChange}
                    placeholder={t("placeholder.select")}
                    getOptionLabel={(opt) => opt.skill_name}
                    getOptionValue={(opt) => opt.skill_name}
                    className={"max-h-[210px]"}
                  />
                )}
              />
            </Field>
          </fieldset>
          <fieldset className="flex flex-col gap-6">
            <div>
              <h4
                className={`${
                  errors.work_location ? "text-error-400" : "text-neutral-900"
                } pb-1 text-[15px] leading-tight `}
              >
                {t("cv_fields.work_location")}
              </h4>
              <div className="grid grid-cols-3 gap-1">
                <FieldCheckbox label={t("filter.office")}>
                  <Checkbox
                    value="office"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Office"
                  />
                </FieldCheckbox>
                <FieldCheckbox label={t("filter.remote")}>
                  <Checkbox
                    value="remote"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Remote"
                  />
                </FieldCheckbox>
                <FieldCheckbox label={t("filter.hybrid")}>
                  <Checkbox
                    value="hybrid"
                    {...register("work_location", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="Hybrid"
                  />
                </FieldCheckbox>
              </div>
            </div>
            <div>
              <h4
                className={`${
                  errors.work_time ? "text-error-400" : "text-neutral-900"
                } pb-1 text-[15px] leading-tight `}
              >
                {t("cv_fields.work_time")}
              </h4>
              <div className="grid grid-cols-3 gap-1">
                <FieldCheckbox label={t("filter.parttime")}>
                  <Checkbox
                    value="parttime"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="parttime"
                  />
                </FieldCheckbox>
                <FieldCheckbox label={t("filter.fulltime")}>
                  <Checkbox
                    value="fulltime"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="fulltime"
                  />
                </FieldCheckbox>
                <FieldCheckbox label={t("filter.freelance")}>
                  <Checkbox
                    value="freelance"
                    {...register("work_time", {
                      validate: atLeastOneOptionChecked,
                    })}
                    id="freelance"
                  />
                </FieldCheckbox>
              </div>
            </div>
            <Field label={t("cv_fields.work_state")} error={errors?.work_state}>
              <Controller
                control={control}
                name="work_state"
                rules={{ required: "Required" }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    options={workStates}
                    placeholder={t("placeholder.select")}
                    getOptionLabel={(opt) => opt.label}
                    getOptionValue={(opt) => opt.value}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        borderRadius: "8px",
                        borderColor: state.isFocused ? "#106BAC" : "#DFDFEE",
                        borderWidth: "1px",
                      }),
                      valueContainer: (styles, state) => ({
                        ...styles,
                        height: "50px",
                        paddingLeft: "16px",
                      }),
                    }}
                  />
                )}
              />
            </Field>
          </fieldset>
        </div>
        <div className="flex basis-full justify-between">
          <Button className="bg-neutral-100 px-12 py-4 text-primary-600" onClick={handleFormNavigateBack}>
            {t("button.back")}
          </Button>
          <Button disabled={isSubmitting} type="submit" className="px-12 py-4">
            {t("button.submit")}
          </Button>
        </div>
      </Form>
    </section>
  );
};

export default JobDescriptionForm;
