import { useEffect, useState } from "react";
import { CloseCircleIcon, FlyingLetterIcon, HumanasLogo, LetterIcon, LockIcon } from "../../components/OldDesign/Icons";
import { InputPassword } from "../../components/OldDesign/Inputs";
import { Button } from "../../components/OldDesign/Button";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { resetPasswordSubmit } from "../../api/endpoints";
import Modal from "../../components/OldDesign/Modal";

const PasswordSet = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [authCode, setAuthCode] = useState(searchParams.get("code"));
  const [authCodeValid, setAuthCodeValid] = useState(false);
  // const resetPasswordSubmit = useStore((state) => state.resetPasswordSubmit);
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
    navigate("/login");
  };

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();
  const handlePasswordConfirmation = (formData) => {
    const payload = {
      new_password: formData.password,
      auth_code: authCode,
    };
    resetPasswordSubmit(
      payload,
      () => {
        setShowModal(true);
      },
      (err) => {
        console.log(err);
      },
    );
  };
  useEffect(() => {
    // if (authCode) resetPasswordSubmit({ auth_code: authCode });
    resetPasswordSubmit(
      { auth_code: authCode },
      () => {
        setAuthCodeValid(true);
      },
      () => {
        navigate("/login");
      },
    );
    console.log(authCode);
  }, [authCode, navigate]);

  if (!authCode) {
    return <Navigate to="/login" />;
  }
  if (!authCodeValid) {
    return null;
  }
  return (
    <>
      {/* PASSWORD FORM */}
      <div
        className={`${
          window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
        } absolute left-1/2 inline-flex w-[360px] basis-1/2 -translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
      >
        <div className="absolute -top-16 self-center lg:hidden">
          <HumanasLogo className="text-neutral-100" />
        </div>
        <div className="mb-9 self-center max-lg:hidden">
          <HumanasLogo className="text-primary-600" />
        </div>
        <div className="pb-6">
          <p className="mb-3 border-l-2 border-l-[#080F47] pl-2 text-[20px] font-bold leading-6 text-[#080F47] lg:text-[24px] lg:leading-7">
            Şifre Oluştur
          </p>
          <p className="text-[14px] leading-5 text-neutral-800 lg:text-[16px]">Lütfen şifrenizi oluşturunuz.</p>
        </div>
        <form className="flex flex-col gap-1" onSubmit={handleSubmit(handlePasswordConfirmation)}>
          <InputPassword
            name="password"
            errors={errors}
            rules={{
              require: true,
              minLength: { value: 8, message: "Minimum 8 character required" },
            }}
            register={register}
            placeholder="Yeni şifrenizi giriniz."
            icon={<LockIcon />}
          />
          <InputPassword
            name="password-repeat"
            errors={errors}
            rules={{
              require: true,
              validate: (val) => {
                if (watch("password") !== val) {
                  setError("password", "Your password do not match");
                  return "Your passwords do not match";
                }
              },
            }}
            register={register}
            placeholder="Şifre Tekrarı"
            icon={<LockIcon />}
          />

          <Button className="mt-5 lg:mt-28" type="submit" bgColor="#106BAC" txtColor="white">
            Kaydet
          </Button>
        </form>
      </div>
      {/* SUCCESS MODAL */}
      <Modal showModal={showModal} setShowModal={closeModal}>
        <div className="relative z-10 flex w-[340px] flex-col items-center justify-center p-9 lg:w-[500px]">
          <button
            className="absolute right-2 top-2"
            onClick={() => {
              closeModal();
            }}
          >
            <CloseCircleIcon className="text-neutral-100" />
          </button>
          <p className="mb-32 text-3xl font-bold text-white lg:text-[36px]">Tebrikler!</p>
          <div className="absolute right-4 top-12 lg:right-10 lg:top-10">
            <FlyingLetterIcon />
          </div>
          <div className="absolute top-14">
            <LetterIcon />
          </div>
          <p className="mb-6 text-[20px] font-bold lg:text-[22px]">Şifreniz Değiştirildi.</p>
          <p className="text-center text-sm lg:text-base">
            {/* Humanas ailesine katılmanıza çok az kaldı. Yakında e-posta
            adresinize şifrenizi oluşturmanız için bir link göndereceğiz. */}
          </p>
        </div>
        <div className="wave absolute -top-1 left-1/2 -translate-x-1/2">
          <svg xmlns="http://www.w3.org/2000/svg" width="500" height="200" viewBox="0 0 500 200" fill="none">
            <path
              d="M500 10C500 4.47715 495.523 0 490 0H10C4.47714 0 0 4.47715 0 10V168.394C0 175.019 6.2724 179.907 12.7241 178.401C50.6609 169.548 165.59 147.475 250 181.589C338.962 217.543 461.822 191.086 492.724 183.47C497.076 182.398 500 178.492 500 174.01V10Z"
              fill="url(#paint0_linear_563_208)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_563_208"
                x1="500"
                y1="6.16563"
                x2="7.36919"
                y2="256.849"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#6070CF" />
                <stop offset="0.101709" stopColor="#478BC6" />
                <stop offset="1" stopColor="#0DC9B1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </Modal>
    </>
  );
};

export default PasswordSet;
