import SectionTitle from "../../../components/NewDesign/SectionTitle";
import { FilterFilled, NotificationFilled, WorldFilled } from "../../../components/OldDesign/Icons";
import { SwiperSlide, Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t } = useTranslation();
  const HOW_IT_WORKS = [
    {
      title: t("community.how_it_works.data_share_title"),
      content: t("community.how_it_works.data_share_description"),
      icon: <WorldFilled className="h-5 w-5" />,
    },
    {
      title: t("community.how_it_works.smart_filters_title"),
      content: t("community.how_it_works.smart_filters_description"),
      icon: <FilterFilled className="h-5 w-5 " />,
    },
    {
      title: t("community.how_it_works.notifications_title"),
      content: t("community.how_it_works.notifications_description"),
      icon: <NotificationFilled className="h-5 w-5 " />,
    },
  ];
  return (
    <div className="rounded-xl bg-secondary_applicant-100 p-6 max-lg:p-3">
      <SectionTitle
        title={t("community.how_it_works.header")}
        className="pb-6 max-lg:items-start max-lg:pb-4 max-lg:text-[15px]"
      />
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".swiper-howitworks-pagination",
        }}
        className=""
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          1080: { slidesPerView: 3 },
        }}
      >
        {HOW_IT_WORKS.map((item, index) => (
          <SwiperSlide key={index}>
            <div className=" h-[205px] rounded-xl bg-[linear-gradient(113deg,#14557B_0.78%,#00CDAC_101.06%)] px-4 py-6 max-lg:h-[155px] max-lg:px-3 max-lg:py-4">
              <div className="flex items-center gap-4 max-lg:pb-1 lg:pb-3">
                <div className="rounded-full bg-neutral-100 p-1.5 text-secondary_applicant-500">{item.icon}</div>
                <h4 className="text-[22px] font-semibold leading-tight text-neutral-100 max-lg:text-[18px]">
                  {item.title}
                </h4>
              </div>
              <p className="text-[18px] font-medium leading-tight text-neutral-100 max-lg:text-[14px]">
                {item.content}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-howitworks-pagination text-center" slot="pagination" />
    </div>
  );
};

export default HowItWorks;
