import { useEffect } from "react";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "../../../../store/useStore";
import { PhoneInput } from "react-international-phone";
import Button from "../../Button/Button";
import VerificationInput from "react-verification-input";
import CountdownTimer from "../../../OldDesign/CountdownTimer";
import { useNavigate } from "react-router-dom";
import isPhoneNumValid from "../../../../utils/isPhoneNumValid";
import { useTranslation } from "react-i18next";

const ConfirmationCard = ({ phone }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const coreCVPhoneConfirmation = useStore((state) => state.coreCVPhoneConfirmation);
  const confirmationAction = useStore((state) => state.confirmationAction);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      confirmation_phone: phone || "",
      confirmation_code: "",
    },
    mode: "onSubmit",
  });

  const sendConfirmationCode = async (formData) => {
    await coreCVPhoneConfirmation(formData);
  };
  const resendCode = () => {
    coreCVPhoneConfirmation({
      confirmation_phone: getValues("confirmation_phone"),
    });
  };

  if (confirmationAction.params?.location === "overview") {
    navigate("/community/joined");
  }
  useEffect(() => {
    if (phone) {
      reset({ confirmation_phone: phone });
    }
  }, [phone, reset]);
  return (
    <>
      <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendConfirmationCode)}>
        {confirmationAction.params?.location === "applicationform" ? (
          <>
            <fieldset className="flex flex-col gap-4">
              <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
                {t("auth.phone_number_validation")}
              </h2>
              <p className="text-[15px] font-medium text-neutral-900">{t("auth.enter_activation_code")}</p>
              <Controller
                control={control}
                name="confirmation_code"
                rules={{
                  required: "Sorry, wrong code.",
                  minLength: {
                    value: 6,
                    message: "Sorry, wrong code.",
                  },
                  maxLength: {
                    value: 6,
                    message: "Sorry, wrong code.",
                  },
                }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { ...fieldState } }) => (
                  <VerificationInput
                    onChange={onChange}
                    errors={errors}
                    {...fieldState}
                    ref={ref}
                    placeholder=""
                    validChars="0-9"
                    classNames={{
                      container: "w-[310px] h-[46px] lg:w-[400px] lg:h-[60px]",
                      character: `${
                        errors.confirmation_code
                          ? "text-error-400 border-error-400"
                          : "text-primary-600 border-primary-200"
                      } bg-neutral-100 rounded-md outline-none text-[36px] font-bold flex items-center justify-center`,
                      characterSelected: `character-selected-verification-input ${
                        errors.confirmation_code ? "border-error-400" : "border-primary-600"
                      }`,
                      characterInactive: ` ${errors.confirmation_code ? "border-error-400" : "border-primary-200"}`,
                    }}
                  />
                )}
              />
              <div className="self-start">
                <CountdownTimer expire_time={parseInt(confirmationAction.params.parameters.expires_in)} />
                {/* {expireTime} */}
              </div>
            </fieldset>
            <div className="flex flex-col gap-8">
              <Button disabled={isSubmitting}>{t("button.submit")}</Button>
              <p className="self-center text-[14px] leading-tight text-primary-700">
                {t("auth.didnt_receive_the_code")}{" "}
                <button type="button" onClick={() => resendCode()} className="font-semibold">
                  {t("button.send_again")}
                </button>
              </p>
            </div>
          </>
        ) : (
          <>
            <fieldset className="flex flex-col gap-4">
              <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
                {t("auth.phone_number_validation")}
              </h2>
              <Field label={t("placeholder.phone_number")} error={errors?.confirmation_phone}>
                <Controller
                  control={control}
                  name="confirmation_phone"
                  rules={{
                    required: "Required",
                    validate: (number) => isPhoneNumValid(number),
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <PhoneInput
                      defaultCountry="tr"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      forceDialCode
                      inputClassName="border"
                      className="border-1 rounded-lg border border-neutral-300  hover:border-primary-600 focus:border-4 focus:border-primary-600"
                      inputProps={{
                        className: "pl-3 h-[49px] bg-neutral-100 text-[15px] lg:h-[52px] lg:text-[16px] outline-none",
                      }}
                      countrySelectorStyleProps={{
                        className: "bg-neutral-200 rounded-l-lg",
                        buttonClassName: "border-none h-full rounded-l-lg hover:rounded-l-lg px-3",
                      }}
                    />
                  )}
                />
              </Field>
            </fieldset>
            <div className="flex flex-col">
              <p className="text-[13px] leading-tight text-primary-700">
                {t("candidate.corecv_form.accept_data_protection_by_continue")}
              </p>
              <Button disabled={isSubmitting}>{t("button.submit")}</Button>
            </div>
          </>
        )}
      </Form>
    </>
  );
};

export default ConfirmationCard;
