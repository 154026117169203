import { useTranslation } from "react-i18next";
import { useStore } from "../../store/useStore";
import {
  AppleStoreButton,
  GoogleStoreButton,
  HumanasLogo,
  LocationOutlined,
  MailIcon,
  PhoneIcon,
} from "../OldDesign/Icons";

const Footer = ({ hide }) => {
  const { t } = useTranslation();
  const userType = useStore((state) => state.user.data.userType);
  return (
    <footer className={`${hide && "hidden"} bg-primary-600 leading-tight text-neutral-100 max-lg:pb-[75px]`}>
      <div className="container2 py-10 max-lg:py-6">
        <div className="grid grid-cols-4 border-b border-[#FFFFFF50] pb-9  max-lg:grid-cols-1 max-lg:gap-6 max-lg:pb-6">
          <div className="mr-auto flex flex-col justify-start gap-2">
            <HumanasLogo className="text-neutral-100" />
          </div>
          <ul className="flex flex-col gap-2.5 text-[16px] font-light ">
            <h4 className="text-[20px] font-semibold max-lg:text-[18px]">{t("footer.content")}</h4>
            <li className="text-[16px] max-lg:text-[14px]">
              <a href="/dashboard" rel="noreferrer">
                {t("header.nav_links.dashboard")}
              </a>
            </li>
            <li className="text-[16px] max-lg:text-[14px]">
              <a href="/community" rel="noreferrer">
                {t("header.nav_links.community")}
              </a>
            </li>
            {userType === "recruiter" && (
              <li className="text-[16px] max-lg:text-[14px]">
                <a href="/mynetwork" rel="noreferrer">
                  {t("header.nav_links.mynetwork")}
                </a>
              </li>
            )}
          </ul>
          <div className="flex flex-col gap-2.5 text-[16px] font-light ">
            <h4 className="text-[20px] font-semibold max-lg:text-[18px]">{t("footer.contact")}</h4>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <LocationOutlined className="h-[18px] w-[14px]" />
              <address className="not-italic max-lg:text-[14px]">{t("footer.address")}</address>
            </div>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <PhoneIcon className="h-[15px] w-[16px]" />
              <a className="max-lg:text-[14px]" href={`tel:${t("footer.phone")}`}>
                {t("footer.phone")}
              </a>
            </div>
            <div className="flex flex-row items-center gap-2.5 max-lg:gap-1">
              <MailIcon className="h-[12px] w-[15px]" />
              <a className="max-lg:text-[14px]" href={`mailto:${t("footer.mail")}`}>
                {t("footer.mail")}
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between pt-9 max-lg:flex-col max-lg:gap-6 max-lg:pt-6">
          <div className="flex flex-row gap-2">
            <a
              href="https://play.google.com/store/apps/details?id=com.Humanas.LazyGuru&pcampaignid=web_share"
              target="_blank"
              rel="noreferrer"
            >
              <GoogleStoreButton className="max-lg:w-[150px]" />
            </a>
            <a href="https://apps.apple.com/tr/app/lazy-guru/id6451207671" target="_blank" rel="noreferrer">
              <AppleStoreButton className="max-lg:w-[150px]" />
            </a>
          </div>
          <ul className="flex flex-row  gap-5 text-[15px] opacity-70 max-lg:gap-3 max-lg:text-[13px]">
            <li>
              <a
                href="https://humanas.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className="max-lg:font-light sm:whitespace-nowrap"
              >
                {t("footer.protection_of_personal_data")}
              </a>
            </li>
            <li>
              <a
                href="https://humanas.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className=" max-lg:font-light sm:whitespace-nowrap"
              >
                {t("footer.privacy_policy")}
              </a>
            </li>
            <li>
              <a
                href="https://humanas.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
                className=" max-lg:font-light sm:whitespace-nowrap"
              >
                {t("footer.cookie_policy")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
