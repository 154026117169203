import HowItWorks from "./HowItWorks";
import QuickTips from "./QuickTips";
import WhoAreWe from "./WhoAreWe";

const WhatIsCommunity = () => {
  return (
    <section className="container2 flex flex-col gap-9 max-lg:gap-6">
      <WhoAreWe />
      <HowItWorks />
      <QuickTips />
    </section>
  );
};

export default WhatIsCommunity;
