import { useState } from "react";
import { InputPhone, InputText } from "../../components/OldDesign/Inputs";
import {
  BagIcon,
  CloseCircleIcon,
  FlyingLetterIcon,
  HumanasLogo,
  LetterIcon,
  MailIcon,
  MetaCvLogoText,
  MetaCvOnlyLogo,
  MetaCvOnlyLogoOpaque,
  PeopleOutlined,
  PhoneIcon,
} from "../../components/OldDesign/Icons";

import Modal from "../../components/OldDesign/Modal";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { createCompany } from "../../api/endpoints";
import { toast } from "react-toastify";
import Button from "../../components/NewDesign/Button/Button";
import ChooseRecruiterType from "./ChooseRecruiterType";
import isPhoneNumValid from "../../utils/isPhoneNumValid";
import { Trans, useTranslation } from "react-i18next";

const RecruiterSignUp = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showClarificationText, setShowClarificationText] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [recruiterType, setRecruiterType] = useState(false);

  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm();
  const handleRegister = (formData) => {
    const countrycode = formData.phone.substring(0, formData.phone.indexOf(" ")).slice(1);
    const phone = formData.phone.substring(formData.phone.indexOf(" ")).replace(/ /g, "");
    const payload = {
      company_type: recruiterType,
      email: formData.email,
      country_code: countrycode,
      phone: phone,
      name: formData.name,
      company_name: formData.company_name,
    };

    createCompany(
      payload,
      () => setShowModal(true),
      () => toast.error("Gecersiz Bilgi"),
      (bool) => setRequesting(bool),
    );
  };
  const closeModal = () => {
    setShowModal(false);
    navigate("/login");
  };
  // TODO
  const openClarificationTextDialogue = () => {
    setShowClarificationText(true);
  };
  const openPrivacyPolicyDialogue = () => {
    setShowPrivacyPolicy(true);
  };

  return (
    <>
      {!recruiterType ? (
        <ChooseRecruiterType setRecruiterType={setRecruiterType} />
      ) : (
        <>
          {/* SIGNUP FORM */}
          <div className="lg:relative lg:basis-1/2">
            <div
              className={` ${
                window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
              } absolute right-1/2 inline-flex w-[360px] translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:right-9 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:translate-x-0 lg:p-12`}
            >
              <div className="absolute -top-16 self-center lg:hidden">
                <HumanasLogo className="text-neutral-100" />
              </div>
              <div className="mb-9 self-center max-lg:hidden">
                <HumanasLogo className="text-primary-600" />
              </div>
              <p className="mb-2 border-l-2 border-l-[#080F47] pl-2 text-[20px] font-bold leading-6 text-[#080F47] lg:mb-3 lg:text-[24px] lg:leading-7">
                {t("auth.sign_up")}
              </p>
              <p className="mb-4 text-[14px] leading-4 text-neutral-800 lg:text-[16px] lg:leading-5">
                {t("auth.join_community_as", {
                  rec_type: recruiterType === "company" ? t("common.company") : t("common.headhunter"),
                })}
              </p>
              <form className="flex flex-col gap-1" onSubmit={handleSubmit(handleRegister)}>
                <InputText
                  name="name"
                  register={register}
                  type="text"
                  rules={{ required: true }}
                  placeholder={t("placeholder.name_surname")}
                  icon={<PeopleOutlined className="h-5 w-5 text-neutral-700" />}
                />
                <InputText
                  name="email"
                  register={register}
                  rules={{ required: true }}
                  type="email"
                  placeholder={t("placeholder.email")}
                  icon={<MailIcon className="h-5 w-5 text-neutral-700" />}
                />
                <Controller
                  control={control}
                  name="phone"
                  type="phone"
                  rules={{ required: true, validate: (number) => isPhoneNumValid(number) }}
                  render={({ field: { ref, ...field } }) => (
                    <InputPhone
                      innerRef={ref}
                      {...field}
                      placeholder={t("placeholder.phone_number")}
                      icon={<PhoneIcon className="h-5 w-5 text-neutral-700" />}
                    />
                  )}
                />
                <InputText
                  name="company_name"
                  register={register}
                  type="text"
                  rules={{ required: true }}
                  placeholder={t("placeholder.company_name")}
                  icon={<BagIcon className="h-5 w-5 text-neutral-700" />}
                />
                <p className="text-[13px] leading-tight text-primary-800">
                  <Trans i18nKey="auth.privacy_consent">
                    <button type="button" className="font-semibold" onClick={() => openClarificationTextDialogue()}>
                      Information Text
                    </button>
                    <button type="button" className="font-semibold" onClick={() => openPrivacyPolicyDialogue()}>
                      Privacy Policy
                    </button>
                  </Trans>
                </p>
                <Button className="mt-2 lg:mt-3" disabled={requesting}>
                  {t("auth.sign_up")}
                </Button>
              </form>
              <p
                className={`${
                  window.innerHeight < 700 ? "max-lg:-bottom-5" : "max-lg:-bottom-10"
                } mt-auto text-center text-[16px] leading-5 text-neutral-700 max-lg:absolute  max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap`}
              >
                {t("auth.already_have_account")}
                <span className="cursor-pointer font-medium text-primary-1000" onClick={() => navigate("/login")}>
                  {" "}
                  {t("auth.login")}
                </span>
              </p>
            </div>
          </div>
          {/* DESKTOP DIALOGUE */}
          <div className="relative leading-5 text-neutral-100 max-lg:hidden lg:flex lg:basis-1/2 lg:flex-col lg:justify-between lg:py-9 lg:pr-9">
            <div>
              <MetaCvLogoText className="h-[36px] w-[163px]" />
            </div>
            <div className="">
              <p className="mb-4 font-bold">{t("metacv_dialogue.header")}</p>
              <ul className="flex flex-col gap-5">
                <li className="relative pl-3">
                  <svg
                    className="absolute left-0 top-1.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="white" />
                  </svg>
                  {t("metacv_dialogue.bullet1")}
                </li>
                <li className="relative pl-3">
                  <svg
                    className="absolute left-0 top-1.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="white" />
                  </svg>
                  {t("metacv_dialogue.bullet2")}
                </li>
                <li className="relative pl-3">
                  <svg
                    className="absolute left-0 top-1.5"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                  >
                    <circle cx="4" cy="4" r="4" fill="white" />
                  </svg>
                  {t("metacv_dialogue.bullet3")}
                </li>
              </ul>
            </div>
            <div className="absolute bottom-0 right-0">
              <MetaCvOnlyLogoOpaque color="#FFF" opacity="0.1" />
            </div>
          </div>
          {/* MOBILE DIALOGUE BUTTON */}
          <button
            onClick={() => navigate("/metacv")}
            className={`${
              window.innerHeight < 700 ? "bottom-1" : "bottom-14"
            } absolute right-4 flex h-[80px] w-[80px]  flex-col items-center justify-center rounded-[50%] bg-white lg:hidden`}
          >
            <div className={`flex flex-col items-center justify-center`}>
              <MetaCvOnlyLogo />
              {t("common.metacv")}
            </div>
          </button>
          {/* SUCCESS MODAL */}
          <Modal showModal={showModal} setShowModal={closeModal}>
            <div className="relative z-10 flex w-[340px] flex-col items-center justify-center p-9 lg:w-[500px]">
              <button
                className="absolute right-2 top-2"
                onClick={() => {
                  closeModal();
                }}
              >
                <CloseCircleIcon className="text-neutral-100" />
              </button>
              <p className="mb-32 text-3xl font-bold text-white lg:text-[36px]">{t("common.congratulations")}</p>
              <div className="absolute right-4 top-12 lg:right-10 lg:top-10">
                <FlyingLetterIcon />
              </div>
              <div className="absolute top-14">
                <LetterIcon />
              </div>
              <p className="mb-6 text-center text-[20px] font-bold lg:text-[22px]">
                {t("auth.sign_up_success_header")}
              </p>
              <p className="text-center text-sm lg:text-base">{t("auth.sign_up_success_message")}</p>
            </div>
            <div className="wave absolute -top-1 left-1/2 -translate-x-1/2">
              <svg xmlns="http://www.w3.org/2000/svg" width="500" height="200" viewBox="0 0 500 200" fill="none">
                <path
                  d="M500 10C500 4.47715 495.523 0 490 0H10C4.47714 0 0 4.47715 0 10V168.394C0 175.019 6.2724 179.907 12.7241 178.401C50.6609 169.548 165.59 147.475 250 181.589C338.962 217.543 461.822 191.086 492.724 183.47C497.076 182.398 500 178.492 500 174.01V10Z"
                  fill="url(#paint0_linear_563_208)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_563_208"
                    x1="500"
                    y1="6.16563"
                    x2="7.36919"
                    y2="256.849"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#6070CF" />
                    <stop offset="0.101709" stopColor="#478BC6" />
                    <stop offset="1" stopColor="#0DC9B1" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </Modal>
          {/* CLARIFICATION TEXT MODAL */}
          <Modal showModal={showClarificationText} setShowModal={setShowClarificationText}>
            <div className="container2 relative max-w-4xl overflow-y-auto p-9 max-lg:h-[70vh] max-lg:max-w-xs max-lg:px-0 max-lg:py-4">
              <h2 className="pb-9 text-center text-[18px] font-bold leading-tight text-primary-900 max-lg:pb-4 ">
                Aydinlatma Metni
              </h2>
              <button className={"absolute right-0 top-6 max-lg:top-4"} onClick={() => setShowClarificationText(false)}>
                <CloseCircleIcon className="max-lg:h-6 max-lg:w-6 " />
              </button>
              <p className="text-[17px] text-primary-800 max-lg:text-justify">
                HUMANAS TEKNOLOJİ ANONİM ŞİRKETİ KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ KULLANICI AYDINLATMA METNİ
                Veri Sorumlusu: Kişisel verileriniz, 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) uyarınca
                veri sorumlusu sıfatıyla hareket eden Humanas Teknoloji A.Ş (“Şirket”) tarafından bu Aydınlatma
                Metni’nde açıklanan kapsamda işlenmektedir. ve aşağıda sıralanan amaçlarla; 6698 Sayılı Kanun’un 5. ve
                6. maddelerinde belirtilen kişisel veri işleme şartları dahilinde işlenebilecektir. Kişisel Verileri
                İşlenen Taraflar: Lazy Guru Uygulaması kullanıcıları ve www.humanas.io web sitesi kullanıcıları İşlenen
                Kişisel Veriler: Kişisel veriler, kişisel olarak sizi tanımlayan veya doğrudan veya dolaylı olarak
                kimliğinizin belirlenmesinde kullanılabilecek her türlü bilgidir. Humanas hizmetlerini kullanımınız
                aracılığıyla veya Humanas temsilcileriyle yaptığınız etkileşimler sırasında kişisel verilerinizi
                toplayabiliriz. Sizden topladığımız kişisel veri kategorileri, bizimle olan etkileşiminizin niteliğine
                veya kullandığınız Humanas hizmetlerine bağlı olarak değişebilir, bu bağlamda aşağıdakilerinden birini
                ya da bir kaçını içerebilir: Kimlik ve İletişim Verileri; Adınız, soyadınız gibi kimlik ve pasaport
                üzerinde yer alan veriler, posta adresiniz, telefon numaranız, faks numaranız, e-posta adresiniz ve
                diğer benzer veriler ve tanımlayıcılar dahil olmak üzere kişisel ve/veya iş iletişim bilgilerinizi
                toplayabiliriz. Müşteri İşlem Verileri; Çağrı merkezi kayıtları, fatura, senet, çek bilgileri, sipariş
                bilgisi, talep bilgisi gibi verilerinizi toplayabiliriz. Finans Verileri; Banka hesap numaraları ve
                ilgili diğer faturalama bilgileri de dahil olmak üzere ödemelerin işlenmesi ve sahteciliğin önlenmesi
                için gerekli bilgileri toplarız. Fiziksel Mekan Güvenliği Verileri: Giriş çıkış kayıt bilgileri, kamera
                kayıtları gibi verilerinizi toplayabiliriz. İşlem Güvenliği Verileri; Humanas networküne bağlanmanız
                durumunda IP adresi bilgileri, İnternet sitesi giriş çıkış bilgileri gibi verilerinizi toplayabiliriz.
                Pazarlama Verileri: Alışveriş geçmişi bilgileri, anket, kampanya çalışmasıyla elde edilen bilgiler gibi
                verilerinizi toplayabiliriz. (bknz. çerez politikası) Görsel Ve İşitsel Kayıtlar; Görsel ve işitsel
                kayıtlar gibi verilerinizi toplayabiliriz. Sosyal Medya Verileri; Sosyal ağlarınızla bilgi paylaşmanıza
                ve çeşitli sosyal medya sitelerinde bizimle etkileşim kurmanıza olanak tanıyan sosyal medya özellikleri
                nedeniyle toplanan veriler. Diğer Benzersiz Tanımlama Bilgileri; Hizmet merkezlerimiz, yardım masası
                veya diğer müşteri destek kanalları ile çevrimiçi olarak, telefon veya posta aracılığıyla şahsen
                etkileşim kurduğunuzda sağladığınız bilgiler, müşteri anketlerine verdiğiniz yanıtlar veya Humanas
                hizmetlerinin sunumunu kolaylaştırabilmemiz ve sorularınıza yanıt verebilmemiz için tarafımıza
                sağladığınız ek bilgiler, sizden topladığımız diğer benzersiz tanımlama bilgilerine örnek olarak
                verilebilir. Gerekli olan durumlarda yolsuzlukla mücadele uyum programımızın bir parçası olarak ve yasal
                yükümlülüklerimiz doğrultusunda ticari iletişim verilerinizle ilgili durum tespiti denetimleri
                gerçekleştirmek için bilgi ediniriz. Kişisel Verilerin Korunması Kanunu 5 ve 6. Maddelerinde belirtilen
                veri işleme şartları doğrultusunda iş ilişkilerimiz kapsamında, tüm ilgili tarafların verilerini tabi
                olunan yasal mevzuatlar ve iş süreçlerimizin devamlılığı, hakkın tesisi, korunması, kullanılması,
                sözleşmesel yükümlülüklerimizi yerine getirmek, operasyonları yürütmek ve aşağıda belirtilen diğer
                amaçlar dahilinde verilerinizi toplamaktayız. Kişisel Verilerin İşlenme Amacı: Toplanan kişisel
                verileriniz, Şirket tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli
                çalışmaların iş birimlerimiz tarafından yapılması ve ilgili iş süreçlerinin yürütülmesi, Şirketimiz
                tarafından yürütülen ticari faaliyetlerin gerçekleştirilmesi için ilgili iş birimlerimiz tarafından
                gerekli çalışmaların yapılması ve buna bağlı iş süreçlerinin yürütülmesi ile Şirketimiz ve Şirketimizle
                iş ilişkisi içerisinde olan ilgili kişilerin hukuki, teknik ve ticari-iş güvenliğinin temini ile
                amaçları kapsamında aşağıdaki amaçlarla (“Amaçlar”) ve Kanun’un 5. ve 6. maddelerinde belirtilen kişisel
                veri işleme şartları dahilinde işlenebilecektir: ● Kullanıcı’ya Lazy Guru Uygulaması’nın, www.humanas.io
                web sitesinin ve sair ürün ve hizmetlerin sunulması, ● Kullanıcı ilişkileri yönetimi süreçlerinin
                yürütülmesi, ● Hukuk işlerinin takibi ve yürütülmesi, ● Resmi kurum taleplerinin yerine getirilmesi, ●
                Bilgi güvenliği süreçlerinin yürütülmesi, ● Denetim faaliyetlerinin gerçekleştirilmesi, ● Kullanıcı
                memnuniyeti, anket ve kurumsal iletişim faaliyetlerinin yürütülmesi, ● Açık rıza verilmiş olması halinde
                Şirketimiz tarafından sunulan ürün ve hizmetlerin beğeni, kullanım alışkanlıklarınıza ve ihtiyaçlarınıza
                göre özelleştirilerek size önerilmesi, profilleme ve segmentasyon faaliyetlerinin gerçekleştirilmesi ve
                Şirketimizin tanıtılması için gerekli olan aktivitelerin planlanması ve icrası. ● Açık rıza verilmiş
                olması halinde reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesi. Kişisel
                Verilerin Aktarıldığı Taraflar ve Aktarım Amacı:Toplanan kişisel verileriniz; Amaçlar’ın
                gerçekleştirilmesi için tedarikçilerimize, iş ortaklarımıza, kanunen yetkili kamu kurumlarına ve kanunen
                yetkili özel kişilere Kanun’un 5. ve 6. maddelerinde düzenlenen veri işleme şartları kapsamında Kanun’un
                8. ve 9. maddelerinde belirtilen kişisel verilerin aktarılmasına ilişkin kurallara uygun olarak
                aktarılabilecektir. Açık rıza verilmiş olması halinde kişisel veriler, Şirketimiz tarafından sunulan
                ürün ve hizmetlerin beğeni, kullanım alışkanlıklarınıza ve ihtiyaçlarınıza göre özelleştirilerek size
                önerilmesi, profilleme ve segmentasyon faaliyetlerinin gerçekleştirilmesi ve Şirketimizin tanıtılması
                için gerekli olan aktivitelerin planlanması ve icrası amaçları ile iş ortakları ve tedarikçilerle ve
                reklam, promosyon, kampanya ve benzeri ticari elektronik ileti gönderilmesi amacıyla ilgili
                tedarikçilerle paylaşılabilecektir. Kişisel Veri Toplamanın Yöntemi ve Hukuki Sebebi: Kişisel
                verileriniz Şirketimiz tarafından, Lazy Guru Uygulaması’nın, www.humanas.io web sitesinin ve sair ürün
                ve hizmetlerin sunulması kapsamında söz konusu ilişkinin kurulması ve işbu ilişkinin devamı süresince
                üçüncü kişilerden ve yasal mercilerden olmak kaydı ile elektronik ortamda internet sitemiz, mobil
                uygulamalarımız, sosyal medya hesaplarımız, telefon görüşmeleri ve e-posta vasıtasıyla ve fiziki,
                yazılı, sözlü ve elektronik mecralardan yukarıda belirtilen amaçlar dahlinde toplanmakta ve Kanun’un 5.
                ve 6. ve 8. Maddelerine uygun olarak toplanmakta, saklanmakta ve aşağıdaki hukuki sebepler ile
                işlenmektedir. Şirket’in meşru menfaatleri için veri işlenmesinin zorunlu olmasına ilişkin hukuki sebebe
                dayalı olarak; ● Kullanıcı ilişkileri yönetimi süreçlerinin yürütülmesi, ● Hukuk işlerinin takibi ve
                yürütülmesi ile hukuki yükümlülüğün yerine getirilebilmesi için zorunlu olması, ● Şirketin tabi olduğu
                mevzuatta açıkça öngörülmüş olması, ● Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması, talep edilen
                ürün ve hizmetleri sunabilmek ve akdettiğiniz sözleşmelerin gereğinin yerine getirilebilmesi, ● İlgili
                kişinin kendisi tarafından alenileştirilmiş olması, Bir hakkın tesisi, kullanılması veya korunması için
                veri işlemenin zorunlu olması, İlgili kişinin temel hal ve özgürlüklerine zarar vermemek kaydı ile, veri
                sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması, ● Bilgi güvenliği süreçlerinin
                yürütülmesi, ● Denetim faaliyetlerinin gerçekleştirilmesi, ● Kullanıcı memnuniyeti, anket ve kurumsal
                iletişim faaliyetlerinin yürütülmesi, Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması hukuki
                sebebine dayalı olarak; ● Kullanıcı’nın Lazy Guru Uygulaması’ndan, www.humanas.io web sitesinden ve sair
                ürün ve hizmetlerden faydalandırılması, Şirket’in hukuki yükümlülüğüne ilişkin hukuki sebebe dayalı
                olarak; ● Resmi kurum taleplerinin yerine getirilmesi, Veya açık rızanıza dayalı olarak ise kişisel
                verileriniz aşağıdaki kapsamda işlenebilecektir; ● Şirketimiz tarafından sunulan ürün ve hizmetlerin
                beğeni, kullanım alışkanlıklarınıza ve ihtiyaçlarınıza göre özelleştirilerek size önerilmesi, profilleme
                ve segmentasyon faaliyetlerinin gerçekleştirilmesi ve Şirketimizin tanıtılması için gerekli olan
                aktivitelerin planlanması ve icrası, ● Reklam, promosyon, kampanya ve benzeri ticari elektronik ileti
                gönderilmesi[16] . Veri Sahibinin Hakları: Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca
                aşağıdaki haklara sahipsiniz. ● Kişisel verilerinizin işlenip işlenmediğini öğrenme, ● Kişisel
                verileriniz işlenmişse, buna ilişkin bilgi talep etme, ● Kişisel verilerinizin işlenme amacını ve
                kişisel verilerinizin işlenme amacına uygun kullanılıp kullanılmadığını öğrenme, ● Yurt içinde veya yurt
                dışında kişisel verilerinizin aktarıldığı üçüncü kişileri bilme, ● Kişisel verilerinizin eksik veya
                yanlış işlenmiş olması halinde bunların düzeltilmesini talep etme, ● İlgili mevzuatta öngörülen şartlar
                çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme, ● İlgili mevzuat uyarınca
                yapılan düzeltme, silme ve yok edilme işlemlerinin, kişisel verilerinizin paylaşıldığı üçüncü kişilere
                bildirilmesini isteme, ● İşlenen kişisel verilerinizin münhasıran otomatik sistemler vasıtasıyla analiz
                edilmesi suretiyle sizin aleyhinize bir sonucun ortaya çıkmasına itiraz etme, ● Kişisel verilerinizin
                kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde, zararın giderilmesini talep etmek.
                Yukarıda sıralanan haklarınıza yönelik başvurularınızı, İlgili kanun ve sair mevzuat dâhilinde öngörülen
                yasal haklarınız uyarınca taleplerinizi sistemlerimizde kayıtlı bulunan elektronik posta adresini
                kullanmak suretiyle hq@humanas.io adresine iletebilirsiniz. . Talebinizin niteliğine göre en kısa sürede
                ve en geç otuz gün içinde başvurularınız ücretsiz olarak sonuçlandırılacaktır; ancak işlemin ayrıca bir
                maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu tarafından belirlenecek tarifeye göre
                tarafınızdan ücret talep edilebilecektir.
              </p>
            </div>
          </Modal>
          {/* PRIVACY POLICY MODAL */}
          <Modal showModal={showPrivacyPolicy} setShowModal={setShowPrivacyPolicy}>
            <div className="container2 relative max-w-4xl overflow-y-auto p-9 max-lg:h-[70vh] max-lg:max-w-xs max-lg:px-0 max-lg:py-4">
              <h2 className="pb-9 text-center text-[18px] font-bold leading-tight text-primary-900 max-lg:pb-4 ">
                Gizlilik Politikasi
              </h2>
              <button className={"absolute right-0 top-6 max-lg:top-4"} onClick={() => setShowPrivacyPolicy(false)}>
                <CloseCircleIcon className="max-lg:h-6 max-lg:w-6 " />
              </button>
              <p className="text-[17px] text-primary-800 max-lg:text-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In egestas, arcu et pellentesque mattis, mi
                orci rhoncus purus, quis bibendum tellus nunc sed nisi. Mauris ut mi ut ipsum efficitur mollis vitae
                fringilla massa. Nunc bibendum, metus fringilla ultricies interdum, dui felis ultrices eros, vel
                consequat magna nisi quis tortor. Pellentesque elementum consectetur sem sed sodales. Fusce laoreet eros
                nec cursus ullamcorper. Nam ac mattis ex, in aliquet massa. Vivamus aliquam nec nibh non dictum.
                Vestibulum elementum erat ut nunc congue tincidunt sit amet sit amet leo. Morbi aliquam sit amet nulla
                sit amet convallis. Sed consectetur at urna a bibendum. Etiam ut iaculis elit. Quisque tristique
                imperdiet lacus eget feugiat. Ut consequat eu sapien sed ultricies. Sed tortor eros, convallis sit amet
                condimentum eget, egestas quis ipsum. Morbi eu consequat ligula. Donec odio nunc, semper vitae diam ac,
                laoreet pretium purus. Donec nibh nisi, cursus feugiat metus ut, rhoncus convallis mauris. Nam posuere
                mollis posuere. Sed sit amet mauris varius, faucibus purus vel, sodales nisi. Cras vel eros a lorem
                convallis vehicula. Nunc tincidunt et ligula eu accumsan. Vestibulum gravida elementum ante, tempor
                imperdiet nunc posuere rhoncus. Sed auctor est vitae dui dignissim aliquet non nec tellus. Lorem ipsum
                dolor sit amet, consectetur adipiscing elit. Duis eu massa finibus, sollicitudin felis quis, rutrum
                augue. Nunc sit amet dui hendrerit, dignissim dolor vel, mattis lorem. Proin congue pellentesque massa,
                nec rhoncus elit. Nullam ullamcorper, lacus vel auctor faucibus, ex tortor tristique leo, at facilisis
                sapien lorem nec leo. Etiam velit lorem, tincidunt et quam quis, iaculis suscipit odio.
              </p>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default RecruiterSignUp;
