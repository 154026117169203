import Button from "./Button/Button";
import { RightArrowOutlined } from "../OldDesign/Icons";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const StartDiscussion = () => {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <section className="container2 flex flex-row items-center justify-between gap-4 pb-6 pt-2 max-lg:flex-col max-lg:items-center">
      <p className="text-[17px] leading-tight max-lg:text-[14px]">{t("dashboard.let_us_know")}</p>
      <Link to="/start-a-discussion" state={{ previousLocation: location }}>
        <Button className="text-neutral-100" icon2={<RightArrowOutlined className="h-5 w-5" />}>
          {t("button.start_discussion")}
        </Button>
      </Link>
    </section>
  );
};

export default StartDiscussion;
