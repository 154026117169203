import { useEffect, useState } from "react";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";
import GroupCard from "../../../components/NewDesign/GroupCard";
import DeleteConfirmation from "../../../components/NewDesign/Popups/ConfirmationPopup";
import { useTranslation } from "react-i18next";

const MyGroups = () => {
  const { t } = useTranslation();
  const getGroupList = useStore((state) => state.getGroupList);
  const groupList = useStore((state) => state.groupList);
  const deleteGroup = useStore((state) => state.deleteGroup);

  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteGroupId, setDeleteGroupId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteGroup = (id) => {
    const deleteGroupName = groupList.data.find((report) => report.id === id).name;
    setDeleteMsg(t("message.are_you_sure_delete_group", { groupname: deleteGroupName }));
    setDeleteGroupId(id);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  if (groupList.isLoading) {
    return <Loading />;
  }
  return (
    <div className="grid grid-cols-3 gap-4 max-lg:grid-cols-1 max-lg:justify-items-center">
      {groupList.data.map((group) => (
        <GroupCard key={group.id} group={group} handleDeleteGroup={handleDeleteGroup} />
      ))}
      <DeleteConfirmation
        message={deleteMsg}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        id={deleteGroupId}
        confirmModal={deleteGroup}
        confirmationTopic={t("button.delete")}
      />
    </div>
  );
};

export default MyGroups;
