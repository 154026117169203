import React, { forwardRef, useState } from "react";
import { Form } from "../Forms/Form";
import { Field } from "../Forms/Field";
import Button from "../Button/Button";
import { useForm } from "react-hook-form";
import { postNetworkConnections } from "../../../api/endpoints";
import { toast } from "react-toastify";
import { User } from "types/user";
import { useTranslation } from "react-i18next";

interface CvFooterProps {
  user: User;
  setUser: (user: User) => void;
}

const CvFooter = forwardRef<HTMLDivElement, CvFooterProps>(({ setUser, user }, ref) => {
  const { t } = useTranslation();
  const [submittingText, setSubmittingText] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      reviewText: "",
    },
    mode: "onSubmit",
  });

  const sendComment = (formData) => {
    const payload = {
      type: "review",
      action: {
        type: "add",
        review_name: "review name",
        review: formData.reviewText,
      },
    };
    postNetworkConnections(
      null,
      payload,
      `/${user.id}`,
      (res) => {
        toast.success(t("message.transaction_successful"));
        setUser(res.data.rows[0]);
      },
      (err) => {
        toast.error(t("message.transaction_failed"));
        console.log(err);
      },
      (bool) => {
        setSubmittingText(bool);
      },
    );
  };
  return (
    <div
      ref={ref}
      className="shadow-[0px_-10px_8px_-8px_rgba(0,0,0,0.08)] max-lg:bg-neutral-100 max-lg:px-4 max-lg:py-6 lg:pt-9"
    >
      <Form onSubmit={handleSubmit(sendComment)}>
        <Field label="" error={errors.reviewText}>
          <textarea
            {...register("reviewText", { required: true, minLength: 2 })}
            name="reviewText"
            placeholder="Write a review"
            rows={5}
            id="reviewText"
            className="mb-4 w-full rounded-lg bg-neutral-200 px-6 py-4 text-[18px] leading-tight outline-none placeholder:text-neutral-500 max-lg:text-[14px]"
          />
        </Field>
        <div className="flex justify-end gap-6">
          <Button loading={submittingText} disabled={submittingText} className="max-lg:w-full" type="submit">
            {t("button.submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
});

export default CvFooter;
