import AidCards from "./AidCards";
import Loading from "../../../components/OldDesign/Loading";
import { useStore } from "../../../store/useStore";

const AidIDsPage = () => {
  const aidCards = useStore((state) => state.strapiContent);
  if (!aidCards.isLoaded) {
    return null;
  }
  if (aidCards.isLoading) {
    return <Loading />;
  }
  return (
    <div>
      <AidCards aidCards={aidCards} />
    </div>
  );
};

export default AidIDsPage;
