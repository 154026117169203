import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useStore } from "../../../store/useStore";
import CNANavigationMenu from "./CNANavigationMenu";

const CNA = () => {
  const cnaDashboard = useStore((state) => state.cnaDashboard);
  const getCnaDashboard = useStore((state) => state.getCnaDashboard);
  useEffect(() => {
    getCnaDashboard();
  }, [getCnaDashboard]);

  return (
    <section className="bg-cna-gradientblur pt-[64px] max-lg:mb-[55px] max-lg:min-h-screen max-lg:bg-center max-lg:pt-[54px] lg:h-screen lg:w-screen lg:overflow-hidden lg:bg-cover">
      <div className="container2 relative grid  grid-cols-[298px,1fr] gap-6 py-4 max-lg:grid-cols-1">
        {/* NAVIGATION MENU */}
        <CNANavigationMenu cnaDashboard={cnaDashboard} />
        <div className="w-full max-lg:min-h-full lg:h-[calc(100vh-96px)] lg:max-h-[800px]">
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default CNA;
