import idealprofile from "../../../../assets/guides/guide_idealprofile.png";
import cnaanalysis from "../../../../assets/guides/guide_cnaanalysis.png";
import cnacorecv from "../../../../assets/guides/guide_cnacorecv.png";
import cnaimportance from "../../../../assets/guides/guide_cnaimportance.png";
import cnatournament from "../../../../assets/guides/guide_cnatournament.png";
import cnaidealmetacv from "../../../../assets/guides/guide_cnaidealmetacv.png";
import cnacorecvprefs from "../../../../assets/guides/guide_cnacorecvprefs.png";
import cnaaidorder from "../../../../assets/guides/guide_cnaaidorder.png";
import cnaorientation from "../../../../assets/guides/guide_cnaorientation.png";
import { useStore } from "store/useStore";
import renderRichText from "utils/renderRichText";
const IdealProfile = () => {
  const strapiGuideContent = useStore((state) => state.strapiGuideContent);
  const content = strapiGuideContent.data[0].guide[3].guidecontent[1].content;
  return (
    // <div className="flex flex-col gap-4">
    //   <div className="rounded-lg bg-neutral-200 p-4">
    //     <h3 className="pb-2 font-semibold leading-tight text-neutral-900">İdeal Çalışan Profili Nedir?</h3>
    //     <p className="text-sm text-neutral-800">
    //       Hem işe alım süreçlerinizde doğru adayları belirlemenizi hem de mevcut çalışanlarınızın pozisyon ihtiyaçlarına
    //       uygunluğunu değerlendirmenizi sağlar. Pozisyon ihtiyaçlarıyla bireysel yetenekleri en iyi şekilde
    //       eşleştirmenizi sağlar. Bu alan sayesinde yeni pozisyonlar için iş pozisyonu uygunluk analizini başlatabilir,
    //       sürecin ilerlemesini yüzdelik olarak takip edebilir ve daha önce tamamlanan analizlere erişerek pozisyonlara
    //       dair detaylı değerlendirmeler yapabilirsiniz.
    //     </p>
    //     <img src={idealprofile} alt="cna" className="object-cover" />
    //   </div>
    //   <div className="rounded-lg bg-neutral-200 p-4">
    //     <div className="flex flex-col gap-4">
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Nasıl Oluşturulur?</h3>
    //         <p className="text-sm text-neutral-800">
    //           Pozisyonlara yönelik ideal profillerin oluşturulması iki adımdan oluşur: Core CV ve Kimlik Turnuvası.
    //           İhtiyacınıza uygun olarak dilediğiniz adımdan başlayarak süreci kolayca başlatabilir ve pozisyon
    //           gereksinimlerine uygun en iyi profilleri belirleyebilirsiniz.
    //         </p>
    //         <img src={cnaanalysis} alt="cna" className="object-cover" />
    //       </div>
    //       <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
    //         <div className="lg:w-1/2">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Core CV</h3>
    //           <p className="text-sm text-neutral-800">
    //             Pozisyona uygun aday ve çalışanların eğitim, yetenek, iş pozisyonu ve çalışma zaman tercihleri gibi
    //             verilerin detaylı bir şekilde tanımlanmasını sağlar.
    //             <br />
    //             <br />
    //             Bu sayede, pozisyon için kapsamlı bir profil oluşturarak işe alım ve pozisyon ihtiyaçlarınızı en etkili
    //             şekilde karşılamanıza ve değerlendirmenize yardımcı olur.
    //           </p>
    //         </div>
    //         <div className="max-lg:w-[300px] lg:w-1/2">
    //           <img src={cnacorecv} alt="cna" className="object-cover" />
    //         </div>
    //       </div>
    //       <div className="max-lg:w-[300px]">
    //         <img src={cnaimportance} alt="cnaimportance" className="object-cover" />
    //       </div>
    //       <div className="flex gap-4 max-lg:flex-col max-lg:gap-2">
    //         <div className="lg:w-1/3">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Önem Derecesi Nedir?</h3>
    //           <p className="text-sm text-neutral-800">
    //             Belirli bir koşulun pozisyon için ne kadar kritik olduğunu belirtmenize olanak tanır. Bu özellik,
    //             tercihlerinizin önem sırasını belirlemenize yardımcı olur. Böylece, kullanıcılar arasındaki sıralama,
    //             belirlenen kriterlere göre önceliklendirilmiş olur.
    //           </p>
    //         </div>
    //         <div className="lg:w-1/3">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Olmazsa Olmaz Nedir?</h3>
    //           <p className="text-sm text-neutral-800">
    //             Bu özellik, bir pozisyon için <b>kesinlikle gerekli</b> olan koşulları tanımlamanızı sağlar. Örneğin,
    //             bir pozisyon için çalışanın mutlaka remote bir çalışma modelinde yer alması gerekiyorsa, bu koşulu
    //             "Olmazsa Olmaz" olarak işaretleyebilirsiniz. Bu şekilde, belirlenen kriterlere uygun olmayan
    //             kullanıcılar daha düşük puan alır.
    //           </p>
    //         </div>
    //         <div className="lg:w-1/3">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Hariç Tut Nedir?</h3>
    //           <p className="text-sm text-neutral-800">
    //             Belirli bir koşulun pozisyon için <b>kesinlikle istenmediğini</b> belirtmek için kullanılır. Örneğin,
    //             bir pozisyon için "uzaktan çalışma" uygun değilse, bu koşul "Hariç Tut" olarak işaretlenebilir. Bu
    //             sayede, yalnızca pozisyon gereksinimlerine uygun kullanıcıları filtreleyerek değerlendirme sürecinde
    //             daha verimli sonuçlar elde edebilirsiniz.
    //           </p>
    //         </div>
    //       </div>
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Kimlik Turnuvası</h3>
    //         <p className="text-sm text-neutral-800">
    //           Pozisyon için uygun gördüğünüz kimliğin oluşturulması amacıyla uygulanan bir önceliklendirme turnuvasıdır.
    //           Turnuvada ihtiyaçlarınıza göre öncelik sıralaması yapılarak pozisyona en uygun kimlik belirlenir.
    //         </p>
    //         <img src={cnatournament} alt="cna" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    //   <div className="rounded-lg bg-neutral-200 p-4">
    //     <div className="flex flex-col gap-4">
    //       <div className="flex gap-4 max-lg:flex-col max-lg:gap-2 lg:items-center">
    //         <div className="lg:w-2/3">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">İdeal Meta CV</h3>
    //           <p className="text-sm text-neutral-800">
    //             Pozisyonunuzun ihtiyaçlarına göre belirlediğiniz kriterler doğrultusunda oluşturulmaktadır.
    //             Bağlantılarınızdan kişileri bu listeye <b>ekleyebilir</b> veya yeni kullanıcıları <b>davet</b>{" "}
    //             edebilirsiniz. Düzenlemelerinizi tamamladıktan sonra, <b>uyum raporu</b>nu inceleyerek pozisyon ve
    //             kullanıcının uyum skoruna, Core CV ve mesleki yönelim karşılaştırmalarına kolayca ulaşabilirsiniz.
    //           </p>
    //         </div>
    //         <div className="max-lg:w-[300px] lg:w-1/3">
    //           <img src={cnaidealmetacv} alt="cna" className="object-cover" />
    //         </div>
    //       </div>
    //       <div className="lg-items-center flex gap-4 max-lg:flex-col max-lg:gap-2">
    //         <div className="lg:w-1/2">
    //           <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Core CV</h3>
    //           <p className="text-sm text-neutral-800">
    //             Pozisyon için belirlediğiniz tercihler bu alanda görüntülenir. <b>"Olmazsa Olmaz"</b> olarak
    //             belirttiğiniz seçenekler, yeşil etiketlerle öne çıkar. İhtiyaçlarınıza göre tercihlerinizde değişiklik
    //             yapabilir ve bu alanı kolayca güncelleyebilirsiniz.
    //           </p>
    //         </div>
    //         <div className="max-lg:w-[300px] lg:w-1/2">
    //           <img src={cnacorecvprefs} alt="cna" className="object-cover" />
    //         </div>
    //       </div>
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Humanas AID</h3>
    //         <p className="text-sm text-neutral-800">
    //           Kimliklerin öne çıkan özelliklerini ve yetkinliklerini detaylı bir şekilde inceleyerek, pozisyon
    //           ihtiyaçlarınıza en uygun kimlikleri öncelik sırasına göre <b>sıralayabilirsiniz</b>.
    //         </p>
    //         <img src={cnaaidorder} alt="cna" className="object-cover" />
    //       </div>
    //       <div>
    //         <h3 className="pb-2 font-semibold leading-tight text-neutral-900">Mesleki Yönelim</h3>
    //         <p className="text-sm text-neutral-800">
    //           Mesleki yönelim grafiği varsayılan olarak "Tam Dengeli" düzeyde başlamaktadır. İhtiyaçlarınıza göre bu
    //           yönelimi <b>"Dengeli"</b>, <b>"Güçlü"</b> veya <b>"Sıradışı"</b> düzeylere çekerek ayarlamalar
    //           yapabilirsiniz.
    //         </p>
    //         <img src={cnaorientation} alt="cna" className="object-cover" />
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>{strapiGuideContent.isLoaded && renderRichText(content)}</>
  );
};

export default IdealProfile;
