import React from "react";
import ButtonRecruiter from "../../components/NewDesign/ButtonRecruiter";
import ButtonCandidate from "../../components/NewDesign/ButtonCandidate";
import { HumanasLogo } from "../../components/OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ChooseUserType = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"}
      absolute left-1/2 inline-flex w-[360px] basis-1/2 -translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:p-12`}
      >
        <div className="absolute -top-16 self-center lg:hidden">
          <HumanasLogo className="text-neutral-100" />
        </div>
        <div className="mb-9 self-center max-lg:hidden">
          <HumanasLogo className="text-primary-600" />
        </div>
        <div className="pb-6">
          <p className="mb-3 border-l-2 border-l-primary-900 pl-2 text-[20px] font-bold leading-6 text-primary-900 lg:text-[24px] lg:leading-7">
            {t("auth.join_us")}
          </p>
          <p className="text-[13px] leading-4 text-neutral-800 lg:text-[16px] lg:leading-5">
            {t("auth.eager_to_see_you")} {t("auth.choose_community_section")}
          </p>
        </div>
        <div className="flex flex-row justify-between">
          <ButtonCandidate onClick={() => navigate("/register/candidate")}></ButtonCandidate>
          <ButtonRecruiter onClick={() => navigate("/register/recruiter")}></ButtonRecruiter>
        </div>
        <p className="text-center text-neutral-700 max-lg:absolute max-lg:-bottom-10  max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap lg:mt-auto">
          {t("auth.already_have_account")}
          <span className="cursor-pointer font-medium text-primary-1000" onClick={() => navigate("/login")}>
            {" "}
            {t("auth.login")}
          </span>
        </p>
      </div>
    </>
  );
};

export default ChooseUserType;
