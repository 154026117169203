import { useEffect, useState } from "react";
import { CloseCircleIcon } from "../../../components/OldDesign/Icons";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { getJobPosition } from "../../../api/endpoints";
import Loading from "../../../components/OldDesign/Loading";
import { useTranslation } from "react-i18next";

const JobPositionAnalysis = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [jobPosData, setJobPosData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getJobPosition(
      null,
      `/${id}`,
      (res) => {
        setJobPosData(res.data);
      },
      (err) => {
        setError(true);
        console.log(err);
      },
      (bool) => setLoading(bool),
    );
  }, [id]);

  if (loading) {
    return <Loading className={"h-full w-full"} />;
  }
  if (error) {
    return <>{t("message.error_occured_try_again")}</>;
  }
  const corecvCompleted = jobPosData.core_cv.state === "completed";
  const identityCompleted = jobPosData.identity.state === "completed";
  const isCoreCv = location.pathname.includes("corecv");

  return (
    <div
      className={`${
        isCoreCv
          ? "bg-cnaCoreCv-modal"
          : corecvCompleted && identityCompleted
          ? "bg-cnaAnalysisComplete-modal"
          : "bg-analysis-modal"
      } h-[650px] w-[1020px] rounded-xl border-2 bg-cover bg-[center] max-lg:h-screen max-lg:w-screen max-lg:bg-[-120px_0] `}
    >
      <div className="flex justify-between p-9 max-lg:p-4">
        <div className="flex flex-col border-l-2 border-neutral-100 pl-1">
          <p className="text-[13px] leading-tight text-neutral-100">{t("cna.job_position")}</p>
          <p className="text-[20px] font-semibold leading-tight text-neutral-100">{jobPosData.name}</p>
        </div>
        <button onClick={() => navigate(location.state.previousLocation)}>
          <CloseCircleIcon className="h-8 w-8 text-neutral-100 max-lg:h-6 max-lg:w-6" />
        </button>
      </div>
      <Outlet context={[id, jobPosData, setJobPosData]} />
    </div>
  );
};

export default JobPositionAnalysis;
