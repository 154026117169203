import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseCircleIcon } from "../../../components/OldDesign/Icons";

import { useStore } from "../../../store/useStore";
import ProgressBar from "../../../components/NewDesign/StatusBar/ProgressBar";
import PersonalInfoCard from "../../../components/NewDesign/MultiStepForm/MobileForm/PersonalInfoCard";
import ContactInfoCard from "../../../components/NewDesign/MultiStepForm/MobileForm/ContactInfoCard";
import ExperienceDetailsCard from "../../../components/NewDesign/MultiStepForm/MobileForm/ExperienceDetailsCard";
import SkillsCard from "../../../components/NewDesign/MultiStepForm/MobileForm/SkillsCard";
import WorkingSituationCard from "../../../components/NewDesign/MultiStepForm/MobileForm/WorkingSituationCard";
import WorkingStyleCard from "../../../components/NewDesign/MultiStepForm/MobileForm/WorkingStyleCard";
import WorkingTimeCard from "../../../components/NewDesign/MultiStepForm/MobileForm/WorkingTimeCard";
import EducationDetailsCard from "../../../components/NewDesign/MultiStepForm/MobileForm/EducationDetailsCard";
import LanguageLevelCard from "../../../components/NewDesign/MultiStepForm/MobileForm/LanguageLevelCard";
import ConfirmationCard from "../../../components/NewDesign/MultiStepForm/MobileForm/ConfirmationCard";
import AboutCard from "../../../components/NewDesign/MultiStepForm/MobileForm/AboutCard";
import { useTranslation } from "react-i18next";

const MobileApplicationForm = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const getCoreCV = useStore((state) => state.getCoreCV);
  const coreCV = useStore((state) => state.coreCV);
  const [stepNumber, setStepNumber] = useState(1);

  const stepTitles = {
    1: { title: t("candidate.corecv_form.user_profile"), step: "1/3" },
    2: { title: t("candidate.corecv_form.user_profile"), step: "2/3" },
    3: { title: t("candidate.corecv_form.user_profile"), step: "3/3" },
    4: { title: t("candidate.corecv_form.professional_info"), step: "1/5" },
    5: { title: t("candidate.corecv_form.professional_info"), step: "2/5" },
    6: { title: t("candidate.corecv_form.professional_info"), step: "3/5" },
    7: { title: t("candidate.corecv_form.professional_info"), step: "4/5" },
    8: { title: t("candidate.corecv_form.professional_info"), step: "5/5" },
    9: { title: t("candidate.corecv_form.educational_status"), step: "1/2" },
    10: { title: t("candidate.corecv_form.educational_status"), step: "2/2" },
    11: { title: t("auth.phone_number_validation"), step: "1/1" },
  };

  const handleForwardForm = () => {
    const deck = document.getElementById("deck");
    const cards = document.querySelectorAll("#deck > .card");

    if (!deck.classList.contains("sliding")) {
      deck.classList.add("sliding");
    }
    const endTransition = (ev) => {
      if (ev.propertyName === "transform") {
        first.removeEventListener("transitionend", endTransition);
        transitionEnded({ deck: deck, cards: cards });
      }
    };
    console.log("cards", cards);
    console.log("stepnumber", stepNumber);
    const first = document.querySelector(".card:first-child");
    first.addEventListener("transitionend", endTransition);
    setStepNumber(stepNumber + 1);
  };

  const handleBackwardForm = () => {
    const deck = document.getElementById("deck");
    const cards = document.querySelectorAll("#deck > .card");
    const last = cards[cards.length - 1];
    deck.insertBefore(last, deck.firstChild);
    // console.log(deck);
    setStepNumber(stepNumber - 1);
  };

  function transitionEnded(params) {
    // console.log("transition ended");
    params.deck.classList.remove("sliding");
    const first = params.cards[0];
    params.deck.appendChild(first);
  }
  const exitForm = () => {
    navigate("/community");
  };

  const bringInitialStepOnTop = (initialStep) => {
    const cards = document.querySelectorAll("#deck > .card");
    // console.log("cards:", cards);
    const deck = document.getElementById("deck");
    // console.log("deck:", deck);
    [].forEach.call(cards, function (element) {
      if (parseInt(element.id) < initialStep) {
        deck.appendChild(element);
      }
    });
  };

  useEffect(() => {
    if (state?.stepNumber > 0) {
      // console.log("stateStep", state.stepNumber);
      setTimeout(() => {
        bringInitialStepOnTop(state.stepNumber);
        setStepNumber(state.stepNumber);
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (coreCV.isLoaded) {
      if (coreCV.data.identity_progress.current_step === 10) {
        bringInitialStepOnTop(11);
      }
    }
  }, []);

  useEffect(() => {
    getCoreCV();
  }, [getCoreCV]);

  if (!coreCV.isLoaded) {
    return null;
  }

  return (
    <div className="mobile-form h-screen bg-primary-75">
      <div className="container2 relative h-[2.4rem] py-2">
        <button className="absolute" onClick={() => exitForm()}>
          <CloseCircleIcon className=" h-[25px] w-[25px] text-primary-700" />
        </button>
      </div>
      <div className="container2 mb-3 rounded-xl bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-4 py-3 text-neutral-100">
        <div className="flex flex-col gap-3">
          <div className="flex flex-row">
            <div className="flex w-full flex-col items-start justify-center gap-[10px] text-[18px] font-semibold leading-tight text-neutral-100">
              {stepTitles[stepNumber].title || ""}
              <div className="w-[75px] rounded-sm border-b-[2px] border-neutral-100"></div>
            </div>
            <p className="text-[18px] font-semibold text-neutral-100">{stepTitles[stepNumber].step || ""}</p>
          </div>
          <p className="text-[15px] leading-tight">{t("candidate.corecv_form.continue_filling_form")}</p>
          <ProgressBar percent={coreCV.data.identity_progress.value} />
        </div>
      </div>
      <ul className="container2" id="deck">
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="1">
          <PersonalInfoCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="2">
          <ContactInfoCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="3">
          <AboutCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="4">
          <SkillsCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="5">
          <ExperienceDetailsCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>

        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="6">
          <WorkingSituationCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="7">
          <WorkingStyleCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="8">
          <WorkingTimeCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="9">
          <EducationDetailsCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="10">
          <LanguageLevelCard handleBackwardForm={handleBackwardForm} handleForwardForm={handleForwardForm} />
        </li>
        <li className="card min-h-[386px] rounded-xl bg-neutral-100 p-4 shadow-xl" id="11">
          <ConfirmationCard
            handleBackwardForm={handleBackwardForm}
            handleForwardForm={handleForwardForm}
            phone={coreCV.data.phone}
          />
        </li>
      </ul>
    </div>
  );
};

export default MobileApplicationForm;
