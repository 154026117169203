import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { CloseCircleIcon } from "components/OldDesign/Icons";
import Sidebar from "components/NewDesign/Sidebar/Sidebar";
import Loading from "components/OldDesign/Loading";
import { useStore } from "store/useStore";
import { useTranslation } from "react-i18next";

const GuideModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const contentRef = useRef(null);
  const strapiGuideContent = useStore((state) => state.strapiGuideContent);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [location.pathname]);
  if (strapiGuideContent.isLoading || !strapiGuideContent.isLoaded) {
    return <Loading />;
  }
  const guides = [
    {
      title: strapiGuideContent.data[0].guide[0]?.about,
      path: "metacv",
      sub: [
        {
          title: strapiGuideContent.data[0].guide[0].guidecontent[0]?.header,
          path: "metacv/1",
        },
        {
          title: strapiGuideContent.data[0].guide[0].guidecontent[1]?.header,
          path: "metacv/2",
        },
      ],
    },
    {
      title: strapiGuideContent.data[0].guide[1]?.about,
      path: "network",
      sub: [
        {
          title: strapiGuideContent.data[0].guide[1]?.guidecontent[0].header,
          path: "network/1",
        },
        {
          title: strapiGuideContent.data[0].guide[1]?.guidecontent[1].header,
          path: "network/2",
        },
        {
          title: strapiGuideContent.data[0].guide[1]?.guidecontent[2].header,
          path: "network/3",
        },
      ],
    },
    {
      title: strapiGuideContent.data[0].guide[2]?.about,
      path: "aid",
      sub: [],
    },
    {
      title: strapiGuideContent.data[0].guide[3]?.about,
      path: "cna",
      sub: [
        {
          title: strapiGuideContent.data[0].guide[3]?.guidecontent[0].header,
          path: "cna/1",
        },
        {
          title: strapiGuideContent.data[0].guide[3]?.guidecontent[1].header,
          path: "cna/2",
        },
        {
          title: strapiGuideContent.data[0].guide[3]?.guidecontent[2].header,
          path: "cna/3",
        },
        {
          title: strapiGuideContent.data[0].guide[3]?.guidecontent[3].header,
          path: "cna/4",
        },
      ],
    },
  ];
  return (
    <div className="relative flex h-[700px] w-[1238px] p-9 pb-3 max-lg:h-auto max-lg:w-screen max-lg:flex-col max-lg:p-0">
      <button onClick={() => navigate("/dashboard")} className="absolute right-2 top-2">
        <CloseCircleIcon className={"h-6 w-6"} />
      </button>
      {/* SIDEBAR */}
      <Sidebar title={t("common.guide")} items={guides} />
      {/* OUTLET */}
      <div ref={contentRef} className="h-full w-full max-w-full flex-1 overflow-y-auto px-6 max-lg:px-4">
        <Outlet />
      </div>
    </div>
  );
};

export default GuideModal;
