import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { InputText } from "../../components/OldDesign/Inputs";
import { Form } from "../../components/NewDesign/Forms/Form";
import {
  AppleLogo,
  GoogleLogo,
  HumanasLogo,
  MetaCvLogoText,
  MetaCvOnlyLogo,
  MetaCvOnlyLogoOpaque,
} from "../../components/OldDesign/Icons";
import Button from "../../components/NewDesign/Button/Button";
import { REACT_BASE_DOMAIN } from "../../api/constants";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const loginCheck = useStore((state) => state.loginCheck);
  const loginData = useStore((state) => state.loginData);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user: loginData.user,
    },
  });

  const handleLogin = (formData) => {
    if (validateUser(formData.user)) {
      loginCheck(formData);
      // console.log(formData);
    }
  };

  const handleGoogleLogin = () => {
    // localhost:3000
    // window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com";

    // apptest.humanas.io
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&response_type=code&access_type=offline&prompt=consent&state=google-web-login&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&client_id=894808935805-4fkhfecnsjd1ronct2nkepkj6muomh2a.apps.googleusercontent.com`;
  };
  const handleAppleLogin = () => {
    // apptest.humanas.io
    // window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=query&client_id=com.humanas.lazygurusignin&redirect_uri=https%3A%2F%2F${REACT_BASE_DOMAIN}%2Flogin&state=apple-web-login`;
    window.location.href = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=form_post&client_id=com.humanas.lazygurusignin&redirect_uri=${process.env.REACT_APP_BASE_URL}/login/redirect&state=apple-web-login&scope=email%20name`;
  };

  function validateUser(user) {
    const mailFormat = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
    if (!mailFormat.test(user)) {
      setError("user", {
        message: "Please provide a valid email or phone number",
      });
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      {/* LOGIN FORM */}
      <div className="lg:relative lg:basis-1/2">
        <div
          className={`${
            window.innerHeight < 700 ? "top-[14%]" : "top-[20%]"
          } absolute right-1/2 inline-flex w-[360px] translate-x-1/2 flex-col rounded-xl bg-white p-6 lg:right-9 lg:top-1/2 lg:h-[680px] lg:w-[500px] lg:-translate-y-1/2 lg:translate-x-0 lg:p-12`}
        >
          <div className="absolute -top-16 self-center lg:hidden">
            <HumanasLogo className="text-neutral-100" />
          </div>
          <div className="mb-9 self-center max-lg:hidden">
            <HumanasLogo className="text-primary-600" />
          </div>
          <div className="mb-6">
            <p className="mb-3 border-l-2 border-l-primary-900 pl-2 text-[20px] font-bold leading-6 text-primary-900 lg:text-[24px] lg:leading-7">
              {t("auth.login")}
            </p>
          </div>
          <div className="mb-6">
            <div className="my-4 h-[12px] w-full border-b text-center lg:mb-6 lg:h-[16px]">
              <span className="bg-white px-4 text-[14px] font-semibold text-primary-700">{t("common.company")}</span>
            </div>
            <Form className="flex flex-col gap-2" onSubmit={handleSubmit(handleLogin)}>
              <InputText
                name="user"
                type="text"
                register={register}
                errors={errors}
                rules={{
                  required: t("validation.email_or_number_required"),
                }}
                placeholder={t("placeholder.email_or_number")}
                id="login-input"
              />
              <Button disabled={loginData.isLoading} loading={loginData.isLoading} type="submit">
                {t("auth.login")}
              </Button>
            </Form>
          </div>
          <div>
            <div className="my-4 h-[12px] w-full border-b text-center lg:mb-6 lg:h-[16px]">
              <span className="bg-white px-4 text-[14px] font-semibold text-primary-700">{t("common.individual")}</span>
            </div>
            <div className="flex flex-col gap-3 lg:gap-2">
              <button
                onClick={() => handleAppleLogin()}
                className={`flex w-full basis-1/2 items-center justify-center gap-1 rounded-[10px] border border-[#D2D2D7] bg-white py-4 text-[13px] leading-5 text-black lg:text-[16px]`}
              >
                <AppleLogo />
                {t("auth.login_with_apple")}
              </button>
              <button
                onClick={() => handleGoogleLogin()}
                className={`flex w-full basis-1/2 items-center justify-center gap-1 rounded-[10px] border border-[#D2D2D7] bg-white py-4 text-[13px] leading-5 text-black lg:text-[16px]`}
              >
                <GoogleLogo />
                {t("auth.login_with_google")}
              </button>
            </div>
          </div>
          <p
            className={`${
              window.innerHeight < 700 ? "max-lg:-bottom-5" : "max-lg:-bottom-10"
            } mt-auto text-center text-[16px] leading-5 text-neutral-700 max-lg:absolute  max-lg:left-1/2 max-lg:-translate-x-1/2 max-lg:whitespace-nowrap`}
          >
            {t("auth.dont_have_an_account")}
            <span className="cursor-pointer font-medium text-primary-1000" onClick={() => navigate("/register")}>
              {" "}
              {t("auth.join_us")}
            </span>
          </p>
        </div>
      </div>
      {/* MOBILE DIALOGUE BUTTON */}
      <button
        onClick={() => navigate("/metacv")}
        className={`${
          window.innerHeight < 700 ? "bottom-1" : "bottom-14"
        } absolute right-4 flex h-[80px] w-[80px] flex-col  items-center justify-center rounded-[50%] bg-white transition-all lg:hidden`}
      >
        <div className={`flex flex-col items-center justify-center`}>
          <MetaCvOnlyLogo />
          Meta CV
        </div>
      </button>
      {/* DESKTOP DIALOGUE */}
      <div className="relative leading-5 text-neutral-100 max-lg:hidden lg:flex lg:basis-1/2 lg:flex-col lg:justify-center lg:gap-8 lg:py-9 lg:pr-9">
        <div>
          <MetaCvLogoText className="h-[36px] w-[163px]" />
        </div>
        <div className="">
          <ul className="flex flex-col gap-5">
            <li className="relative pl-3 text-xl">{t("auth.welcome")}</li>
          </ul>
        </div>
        <div className="absolute bottom-0 right-0">
          <MetaCvOnlyLogoOpaque color="#FFF" opacity="0.1" />
        </div>
      </div>
    </>
  );
};

export default Login;
