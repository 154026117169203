import React, { useEffect, useRef } from "react";
import { HumanasLogo } from "../../../components/OldDesign/Icons";
import ccLogo from "../../../assets/logo/cocacola.svg";
import vestelLogo from "../../../assets/logo/vestel.svg";
import bekoLogo from "../../../assets/logo/beko.svg";
import humanasLogo from "../../../assets/logo/humanas.svg";
import { useStore } from "../../../store/useStore";

const CompanyWeWork = () => {
  const scrollerRef = useRef(null);

  useEffect(() => {
    const addAnimation = () => {
      scrollerRef.current.setAttribute("data-animated", true);
    };
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }
  }, []);

  return (
    <div className="mx-auto my-6 flex max-h-[100px] max-w-[1440px] flex-col justify-between bg-neutral-200 px-9 py-4 max-lg:my-3 max-lg:p-4">
      <div ref={scrollerRef} className="scroller">
        <ul className="scroller__inner flex w-max items-baseline gap-8">
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
          {/* <li>
            <img src={ccLogo} alt="cola" />
          </li> */}
          {/* <li>
            <img src={vestelLogo} alt="vestel" />
          </li> */}
          {/* <li>
            <img src={bekoLogo} alt="beko" />
          </li> */}
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
          {/* <li>
            <img src={ccLogo} alt="cola" />
          </li> */}
          {/* <li>
            <img src={vestelLogo} alt="vestel" />
          </li> */}
          {/* <li>
            <img src={bekoLogo} alt="beko" />
          </li> */}
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
          {/* <li>
            <img src={ccLogo} alt="cola" />
          </li> */}
          {/* <li>
            <img src={vestelLogo} alt="vestel" />
          </li> */}
          {/* <li>
            <img src={bekoLogo} alt="beko" />
          </li> */}
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
          {/* <li>
            <img src={ccLogo} alt="cola" />
          </li> */}
          {/* <li>
            <img src={vestelLogo} alt="vestel" />
          </li> */}
          {/* <li>
            <img src={bekoLogo} alt="beko" />
          </li> */}
          {/* <li>
            <img src={humanasLogo} alt="humanas" />
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default CompanyWeWork;
