import React, { forwardRef, Fragment } from "react";
import qrCode from "../../assets/humanasio_qr.png";
import humanasLogo from "../../assets/humanasLogo.svg";
import worldIcon from "../../assets/worldIcon.svg";
import "./printableMetaCv.css";
import {
  BirthdayFilled,
  DotOutlined,
  LocationFilled,
  MailFilled,
  MetaCvLogoText,
  MetaCvOnlyLogoColored,
  PhoneFilled,
} from "../OldDesign/Icons";
import userPP from "../../user.png";
import SectionTitle from "../NewDesign/SectionTitle";
import { Wordcloud } from "@visx/wordcloud";
import { ORIENTATION_LEVEL_DESCRIPTIONS, ORIENTATION_STAT_COLORS } from "../NewDesign/constants";

const PrintableMetaCv = forwardRef(({ user }, ref) => {
  const work_locations = [];
  for (const [key, value] of Object.entries(user.work_location)) {
    if (value) {
      work_locations.push(key[0].toUpperCase() + key.substring(1));
    }
  }
  const descriptions = ORIENTATION_LEVEL_DESCRIPTIONS();
  return (
    <div id="printableMetaCv" ref={ref} className="flex w-[595px] flex-col">
      {/* PAGE 1 */}
      <div id="page1" className="printable relative flex h-[842px] w-[595px] flex-wrap bg-[#FFF]">
        {/* HEADER */}
        <div className="mb-6 mt-8 flex h-[51px] w-full items-center justify-between px-4">
          <div className="border-l border-primary-900 pl-1">
            <p className="text-[20px] font-bold text-primary-900">{user.name}</p>
            <p className="text-[12px] text-primary-900">{user.job.job_name}</p>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <h1 className="text-[8px] font-medium text-neutral-700">Discover Your Potential!</h1>
            <div className="flex flex-col gap-1">
              <MetaCvLogoText className="w-[113px]" />
              <div className="flex justify-end gap-1">
                <span className="text-[8px] leading-tight text-primary-900">by</span>
                <img src={humanasLogo} alt="humanas" />
              </div>
            </div>
          </div>
        </div>
        {/* SIDE */}
        <div className="relative flex h-[740px] w-[170px] flex-col rounded-tr-[30px] bg-primary-75 px-3 py-4">
          {/* BADGES */}
          <div className="absolute right-3 flex flex-row flex-wrap gap-1">
            {user.metacv.aid?.length > 0 &&
              user.metacv.aid.map((badge, index) => (
                <img
                  key={index}
                  src={"https://strapi.humanas.io" + badge.media}
                  alt={badge.name}
                  title={badge.name}
                  className="h-[25px] w-[25px]"
                />
              ))}
          </div>
          {/* PP */}
          <div className="relative flex h-[80px] w-[80px] items-center justify-center self-center rounded-full bg-[#87C4FF]">
            <img src={userPP} alt="profile" className="h-[73px] w-[73px] rounded-full" />
            <span className="absolute -bottom-1 rounded-full bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] px-1.5 text-[8px] font-semibold text-neutral-100">
              LVL {user.metacv_level}
            </span>
          </div>
          {/* INFO */}
          <div className="flex flex-col gap-2 pt-3">
            {/* PERSONAL */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Kişisel Bilgiler
              </h2>
              <ul className="flex flex-col gap-0.5">
                <li className="flex items-center gap-1">
                  <MailFilled className="h-4 w-4 text-primary-400" />
                  <p className="text-[8px] leading-tight text-primary-900">{user.name}</p>
                </li>
                <li className="flex items-center gap-1">
                  <PhoneFilled className="h-4 w-4 text-primary-400" />
                  <p className="text-[8px] leading-tight text-primary-900">{user.phone}</p>
                </li>
                <li className="flex items-center gap-1">
                  <LocationFilled className="h-4 w-4 text-primary-400" />
                  <p className="text-[8px] leading-tight text-primary-900">
                    {user.location_state.state_name},{user.location_country.country_name}
                  </p>
                </li>
                <li className="flex items-center gap-1">
                  <BirthdayFilled className="h-4 w-4 text-primary-400" />
                  <p className="text-[8px] leading-tight text-primary-900">{user.birth_date}</p>
                </li>
              </ul>
            </div>
            {/* SKILLS */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Yetenekler
              </h2>
              <ul className="flex flex-col gap-0.5">
                {user.skills_array.slice(0, 10).map((skill, idx) => (
                  <li key={idx} className="text-[8px] text-primary-900">
                    <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                    {skill.skill_name}
                  </li>
                ))}
              </ul>
            </div>
            {/* EXPERIENCE */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Sektörel Tecrübe
              </h2>
              <ul className="flex flex-col gap-0.5">
                <li className="text-[8px] text-primary-900">
                  <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                  {user.job.job_experience_title}
                </li>
              </ul>
            </div>
            {/* WORKING STATUS */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Çalışma Durumu
              </h2>
              <ul className="flex flex-col gap-0.5">
                <li className="text-[8px] text-primary-900">
                  <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                  {user.work_state === "unemployed" ? "Not working" : "Working"}
                </li>
              </ul>
            </div>
            {/* WORKING PREF. */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Çalışma Tercihi
              </h2>
              <ul className="flex flex-col gap-0.5">
                {work_locations.map((loc, index) => (
                  <li key={index} className="text-[8px] text-primary-900">
                    <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                    {loc}
                  </li>
                ))}
              </ul>
            </div>
            {/* LANGUAGE */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Dil Bilgisi
              </h2>
              <ul className="flex flex-col gap-0.5">
                {user.languages_array.map((lang, index) => (
                  <li key={index} className="text-[8px] text-primary-900">
                    <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                    {lang.language_name} {lang.language_proficiency}
                  </li>
                ))}
              </ul>
            </div>
            {/* EDUCATION */}
            <div>
              <h2 className="mb-1 border-l border-primary-900 pl-1 text-[10px] font-bold leading-tight text-primary-900">
                Eğitim Durumu
              </h2>
              <ul className="flex flex-col gap-0.5">
                {user.educations_array.map((edu, idx) => (
                  <li key={idx} className="text-[8px] text-primary-900">
                    <DotOutlined className="mr-1 inline-block w-1 text-primary-400" />
                    {edu.institution}
                    <br /> {edu.department !== "-1" && edu.department + ", " + edu.degree}{" "}
                    {edu.start_date.replace("-", "/")}-{edu.end_date.replace("-", "/")}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <div className="h-[740px] w-[425px] pl-3 pr-1">
          <p className="text-[8px] text-primary-900">Sayın {user.name};</p>
          <p className="mb-2 text-[8px] font-semibold text-primary-900">
            Kişilik Tipi ve Mesleki Yönelim özetleriniz aşağıdaki gibidir.
          </p>
          {/* PERSONALITY TYPE */}
          <h2 className="mb-2 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
            Kişilik Tipi Özeti
          </h2>
          {/* PIE CHART */}
          <div>
            <Wordcloud
              words={user.metacv.word_cloud}
              fontSize={(word) => Math.sqrt(word.value) * 0.8}
              // fontWeight={(word) => Math.sqrt(word.value) * 6}
              padding={8}
              font={"Inter"}
              rotate={(word) => 0}
              spiral="archimedean"
              // fill={(word, index) => colorScale(index)}
              width={420}
              height={320}
              random={() => 0.5}
            >
              {(cloudWords) =>
                cloudWords.map((word, index) => {
                  // console.log(index, word.text);
                  return (
                    <Fragment key={index}>
                      <defs key={index}>
                        <filter x="-4%" y="-5%" width="108%" height="110%" id={index}>
                          <feFlood floodColor={word.bgColor} />
                          <feGaussianBlur stdDeviation="2" />
                          <feComponentTransfer>
                            <feFuncA type="table" tableValues="0 0 0 1" />
                          </feComponentTransfer>

                          <feComponentTransfer>
                            <feFuncA type="table" tableValues="0 1 1 1 1 1 1 1" />
                          </feComponentTransfer>
                          <feComposite operator="over" in="SourceGraphic" />
                        </filter>
                      </defs>
                      <text
                        style={{
                          fontSize: word.size,
                          fontWeight: Math.log(word.value) * 100,
                          fill: word.color,
                          fontFamily: word.font,
                        }}
                        filter={`url(#${index})`}
                        textAnchor="middle"
                        transform={`translate(${word.x}, ${word.y}) rotate(${word.rotate})`}
                      >
                        {word.text}
                      </text>
                    </Fragment>
                  );
                })
              }
            </Wordcloud>
            <div className="flex flex-wrap justify-center">
              <div className="flex basis-1/3 items-center justify-center gap-1">
                <span className="text-[8px] text-[#7B7B95] max-lg:text-xs">{"\u2B24"}</span>
                <p className="whitespace-nowrap text-[8px] max-lg:text-xs">Deneyime Açık</p>
              </div>
              <div className="flex basis-1/3 items-center justify-center  gap-1">
                <span className="text-[8px] text-[#169C8A] max-lg:text-xs">{"\u2B24"}</span>
                <p className="whitespace-nowrap text-[8px] max-lg:text-xs">Uyumlu</p>
              </div>
              <div className="flex basis-1/3 items-center justify-center  gap-1">
                <span className="text-[8px] text-[#106BAC] max-lg:text-xs">{"\u2B24"}</span>
                <p className="whitespace-nowrap text-[8px] max-lg:text-xs">Sorumlu</p>
              </div>
              <div className="flex basis-1/3 items-center justify-center  gap-1">
                <span className="text-[8px] text-[#EF988F] max-lg:text-xs">{"\u2B24"}</span>
                <p className="whitespace-nowrap text-[8px] max-lg:text-xs">Duygusal Dengeye Sahip</p>
              </div>
              <div className="flex basis-1/3 items-center justify-center  gap-1">
                <span className="text-[8px] text-[#EC4899] max-lg:text-xs">{"\u2B24"}</span>
                <p className="whitespace-nowrap text-[8px] max-lg:text-xs">Dışa Dönük</p>
              </div>
            </div>
          </div>
          <h2 className="mb-2 mt-6 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
            Mesleki Yönelim Özeti
          </h2>
          {/* <OrientationSummary metacv={user.metacv} /> */}
          <div className="col-span-3 grid grid-cols-2 gap-1.5">
            {user.metacv.dimensions_stats.map((stat, index) => (
              <div key={index} className="rounded-xl bg-primary-50 px-2 py-3 odd:mb-1 even:mt-2">
                <SectionTitle
                  title={
                    <div className="flex items-center">
                      <span className={`pr-1 text-[8px]`} style={{ color: ORIENTATION_STAT_COLORS[stat.id] }}>
                        {"\u2B24"}
                      </span>
                      {stat.title}: {stat.value}
                    </div>
                  }
                  className="items-start gap-1 pb-2 text-[9px] font-semibold leading-tight text-primary-900"
                />
                <p className="text-justify text-[8px] leading-tight">{descriptions[stat.id]}</p>
              </div>
            ))}
          </div>
        </div>
        {/* PAGEINFO */}
        <BottomPageInfo totalPage={5} currentPage={1} />
      </div>
      {/* PAGE 2 */}
      <div id="page2" className="printable relative flex h-[842px] w-[595px] flex-wrap bg-[#FFF]">
        {/* HEADER */}
        <div className="mb-3 mt-8 flex h-[51px] w-full items-center justify-between px-4 ">
          <div className="border-l border-primary-900 pl-1">
            <p className="text-[20px] font-bold text-primary-900">{user.name}</p>
            <p className="text-[12px] text-primary-900">{user.job.job_name}</p>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <h1 className="text-[8px] font-medium text-neutral-700">Discover Your Potential!</h1>
            <div className="flex flex-col gap-1">
              <MetaCvLogoText className="w-[113px]" />
              <div className="flex justify-end gap-1">
                <span className="text-[8px] leading-tight text-primary-900">by</span>
                <img src={humanasLogo} alt="humanas" />
              </div>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <div className="h-[765px] w-full px-4">
          {/* TITLE */}
          <h2 className="mb-2 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
            Mesleki Yönelim Detayları
          </h2>
          {/* <p className="text-[8px] text-primary-900">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              convallis at purus finibus ullamcorper.
            </p> */}
          {/* YEK YONLU YONELIMLER */}
          {/* <div className="my-12 flex flex-wrap items-center justify-center">
              {user.metacv.polars.map((polar, idx) => {
                return (
                  <div
                    key={idx}
                    className="relative mx-8 flex h-[95px] w-[120px] items-center justify-center"
                  >
                    <div className="max-hidden absolute h-[125px] w-[125px] rotate-45 rounded-lg bg-primary-100"></div>
                    <div className="absolute mb-10 flex flex-col items-center gap-1">
                      <div className="flex flex-col items-center gap-1">
  
                        <div className="relative h-[35px] w-[35px]">
                          <svg className="h-full w-full" viewBox="0 0 100 100">
  
                            <circle
                              className="stroke-[#106BAC]"
                              strokeOpacity={0.2}
                              strokeWidth="5"
                              cx="50"
                              cy="50"
                              r="40"
                              fill="transparent"
                            ></circle>
  
                            <circle
                              className="progress-ring__circle"
                              strokeWidth="5"
                              strokeLinecap="round"
                              stroke="url(#circular_progress)"
                              cx="50"
                              cy="50"
                              r="40"
                              fill="transparent"
                              strokeDashoffset={`calc(400 - (250 * ${polar.value}) / 100)`}
                            ></circle>
  
  
                            <text
                              x="50"
                              y="53"
                              textAnchor="middle"
                              className="text-[22px] font-semibold text-primary-400"
                              fill="currentColor"
                              alignmentBaseline="middle"
                            >
                              {polar.value}%
                            </text>
                            <defs>
                              <defs>
                                <linearGradient
                                  id="circular_progress"
                                  x1="40.4238"
                                  y1="0.152954"
                                  x2="40.4238"
                                  y2="80.153"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stopColor="#106BAC" />
                                  <stop offset="1" stopColor="#6FB6FF" />
                                </linearGradient>
                              </defs>
                            </defs>
                          </svg>
                        </div>
                        <h2 className="text-[8px] font-semibold leading-tight text-primary-900 ">
                          {polar.title}
                        </h2>
                      </div>
                      <p className="text-center text-[6px] leading-tight text-primary-700">
                        {polar.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div> */}
          {/* KUTUPLU YONELIMLER */}
          <div className="flex flex-col gap-2">
            {user.metacv.dimensions.slice(0, 5).map((orientation, idx) => (
              <PrintableOrientation key={idx} orientation={orientation} />
            ))}
          </div>
        </div>
        {/* PAGEINFO */}
        <BottomPageInfo totalPage={5} currentPage={2} />
      </div>
      {/* PAGE 3 */}
      <div id="page3" className="printable relative flex h-[842px] w-[595px] flex-wrap bg-[#FFF]">
        {/* HEADER */}
        <div className="mb-3 mt-8 flex h-[51px] w-full items-center justify-between px-4 ">
          <div className="border-l border-primary-900 pl-1">
            <p className="text-[20px] font-bold text-primary-900">{user.name}</p>
            <p className="text-[12px] text-primary-900">{user.job.job_name}</p>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <h1 className="text-[8px] font-medium text-neutral-700">Discover Your Potential!</h1>
            <div className="flex flex-col gap-1">
              <MetaCvLogoText className="w-[113px]" />
              <div className="flex justify-end gap-1">
                <span className="text-[8px] leading-tight text-primary-900">by</span>
                <img src={humanasLogo} alt="humanas" />
              </div>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <div className="h-[765px] w-full px-4">
          {/* TITLE */}
          <h2 className="mb-2 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
            Mesleki Yönelim Detayları
          </h2>
          {/* KUTUPLU YONELIMLER */}
          <div className="flex flex-col gap-2">
            {user.metacv.dimensions.slice(5, 10).map((orientation, idx) => (
              <PrintableOrientation key={idx} orientation={orientation} />
            ))}
          </div>
        </div>
        {/* PAGEINFO */}
        <BottomPageInfo totalPage={5} currentPage={3} />
      </div>
      {/* PAGE 4 */}
      <div id="page4" className="printable relative flex h-[842px] w-[595px] flex-wrap bg-[#FFF]">
        {/* HEADER */}
        <div className="mb-3 mt-8 flex h-[51px] w-full items-center justify-between px-4 ">
          <div className="border-l border-primary-900 pl-1">
            <p className="text-[20px] font-bold text-primary-900">{user.name}</p>
            <p className="text-[12px] text-primary-900">{user.job.job_name}</p>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <h1 className="text-[8px] font-medium text-neutral-700">Discover Your Potential!</h1>
            <div className="flex flex-col gap-1">
              <MetaCvLogoText className="w-[113px]" />
              <div className="flex justify-end gap-1">
                <span className="text-[8px] leading-tight text-primary-900">by</span>
                <img src={humanasLogo} alt="humanas" />
              </div>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <div className="h-[765px] w-full px-4">
          {/* TITLE */}
          <h2 className="mb-2 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
            Mesleki Yönelim Detayları
          </h2>
          {/* KUTUPLU YONELIMLER */}
          <div className="flex flex-col gap-2">
            {user.metacv.dimensions.slice(10, 15).map((orientation, idx) => (
              <PrintableOrientation key={idx} orientation={orientation} />
            ))}
          </div>
        </div>
        {/* PAGEINFO */}
        <BottomPageInfo totalPage={5} currentPage={4} />
      </div>
      {/* PAGE 5 */}
      <div id="page5" className="printable relative flex h-[842px] w-[595px] flex-wrap bg-[#FFF]">
        {/* HEADER */}
        <div className="mb-3 mt-8 flex h-[51px] w-full items-center justify-between px-4 ">
          <div className="border-l border-primary-900 pl-1">
            <p className="text-[20px] font-bold text-primary-900">{user.name}</p>
            <p className="text-[12px] text-primary-900">{user.job.job_name}</p>
          </div>
          <div className="flex flex-col items-start justify-center gap-1">
            <h1 className="text-[8px] font-medium text-neutral-700">Discover Your Potential!</h1>
            <div className="flex flex-col gap-1">
              <MetaCvLogoText className="w-[113px]" />
              <div className="flex justify-end gap-1">
                <span className="text-[8px] leading-tight text-primary-900">by</span>
                <img src={humanasLogo} alt="humanas" />
              </div>
            </div>
          </div>
        </div>
        {/* MAIN */}
        <div className="h-[765px] w-full px-4">
          {/* TITLE */}
          {user.review?.status > 0 && (
            <>
              <h2 className="mb-2 border-l border-primary-700 pl-1 text-[12px] font-bold leading-tight text-primary-700">
                Uzman Değerlendirmesi
              </h2>
              <div>
                <div className="flex items-center gap-1 pb-1">
                  <img src={userPP} alt={user.review.reviewer_name} className="h-6 w-6 rounded-full" />
                  <h2 className="text-[10px] font-medium text-primary-900">{user.review.reviewer_name}</h2>
                  <span className="ml-auto text-[10px] text-neutral-700">{user.review.review_date}</span>
                </div>
                <p className="text-justify text-[8px] text-primary-900">{user.review.review}</p>
              </div>
            </>
          )}
        </div>
        {/* PAGEINFO */}
        <BottomPageInfo totalPage={5} currentPage={5} />
      </div>
    </div>
  );
});

const PrintableOrientation = ({ orientation }) => {
  const percent =
    orientation.value === 20 ? 21 : orientation.value === 50 ? 51 : orientation.value === 80 ? 81 : orientation.value;

  const direction = orientation.direction;

  const indicatorLocation = direction ? 50 + percent : 50 - percent;

  return (
    <div
      id={`orientationCard_${orientation.dimension_title}`}
      className="rounded-xl border border-neutral-300 bg-neutral-100 p-2"
    >
      <h3 className="pb-1.5 text-center text-[10px] font-semibold leading-tight text-primary-900">
        {orientation.title}
      </h3>
      <div className="flex flex-col gap-1">
        <div className="grid grid-cols-2 gap-2">
          <div
            className={`${
              !direction || percent === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-2 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-2 right-1/2 z-40 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[8px] font-medium `}
            >
              {orientation.title_left}
            </div>
          </div>
          <div
            className={`${
              direction || percent === 0
                ? "border-secondary_recruiter-600 text-primary-900"
                : "border-secondary_recruiter-200 text-neutral-700"
            } relative my-0 h-2 rounded-tl-2xl rounded-tr-2xl border-l border-r border-t`}
          >
            <div
              className={`absolute -top-2 right-1/2 z-40 flex translate-x-1/2 items-center gap-1 bg-neutral-100 px-2 text-[8px] font-medium `}
            >
              {orientation.title_right}
            </div>
          </div>
        </div>
        {/* BAR */}
        <div className="relative mb-2 w-full">
          <div className="grid h-2.5 grid-cols-[repeat(20,minmax(0,1fr))] gap-2">
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="rounded-l-full bg-[#135B91]"></div>
              <div className="bg-[#3469AD]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Sıra Dışı
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#4771BD]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#7986E8]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Güçlü
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#8D94F8]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#82B7EA]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Dengeli
              </span>
            </div>
            <div className={` relative col-span-4 grid grid-cols-4`}>
              <div className="bg-[#7CCBE3]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#77DEDB]"></div>
              <div className="bg-[#7CCBE3]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Tam Dengeli
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#82B7EA]"></div>
              <div className="bg-[#86A9F0]"></div>
              <div className="bg-[#8D94F8]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Dengeli
              </span>
            </div>
            <div className={` relative col-span-3 grid grid-cols-3`}>
              <div className="bg-[#7986E8]"></div>
              <div className="bg-[#607CD3]"></div>
              <div className="bg-[#4771BD]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Güçlü
              </span>
            </div>
            <div className={` relative col-span-2 grid grid-cols-2`}>
              <div className="bg-[#3469AD]"></div>
              <div className="rounded-r-full  bg-[#135B91]"></div>
              <span className="absolute left-1/2 top-2 -translate-x-1/2 whitespace-nowrap pt-1 text-[6px] font-medium leading-tight text-primary-800">
                Sıra Dışı
              </span>
            </div>
          </div>

          <div
            className={`absolute -left-[8px] -top-3.5  z-10 w-full max-lg:-left-[10px]`}
            style={{ paddingLeft: `${indicatorLocation}%` }}
          >
            {/* INDICATOR */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="24"
              viewBox="0 0 33 24"
              fill="none"
              className="w-4"
            >
              <g filter="url(#filter0_d_5473_28772)">
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  fill="white"
                />
                <path
                  d="M16.707 3.45312H28.0642C29.7667 3.45312 30.6907 5.44468 29.5914 6.74461L18.2341 20.1738C17.4352 21.1185 15.9789 21.1185 15.1799 20.1738L3.82271 6.74461C2.72334 5.44468 3.64733 3.45312 5.34981 3.45312H16.707Z"
                  stroke="#6070CF"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_5473_28772"
                  x="0.845703"
                  y="0.953125"
                  width="31.7227"
                  height="22.4292"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset />
                  <feGaussianBlur stdDeviation="1" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0 0.343766 0 0 0 0.12 0"
                  />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5473_28772" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5473_28772" result="shape" />
                </filter>
              </defs>
            </svg>
          </div>

          <div className="absolute right-1/2 top-1/2 h-2 w-2 -translate-y-1/2 translate-x-1/2 rotate-45 rounded-sm bg-neutral-100 "></div>
        </div>

        <div
          className={`rounded-xl border border-none border-primary-800 bg-neutral-200 p-1.5 text-[7px] leading-tight`}
        >
          <h5 className="pb-1.5 font-bold text-neutral-800">
            <span className="text-primary-800">{orientation.description_main_level}</span>{" "}
            {orientation.description_main_level === "Tam dengeli düzeyde"
              ? orientation.title + " Yönelimi"
              : orientation.description_main_title}
          </h5>
          <p className="text-[7px] font-medium leading-tight text-neutral-800">{orientation.description_main}</p>
        </div>
      </div>
    </div>
  );
};

const BottomPageInfo = ({ currentPage, totalPage }) => {
  return (
    <div className="absolute bottom-4 flex h-[20px] w-[595px] flex-row items-center justify-between border border-[#E8E8E8] bg-white">
      <div className="flex flex-row items-center justify-center gap-1 pl-[45px]">
        <img src={worldIcon} alt="world" />
        <span className="font-openSans text-[10px]">humanas.io</span>
      </div>
      <div>
        <p className="font-openSans text-[10px]">
          {currentPage}/{totalPage}
        </p>
      </div>
      <div className="mr-[10px] flex flex-row items-center gap-0.5 bg-primary-75 px-[10px]">
        <img src={qrCode} alt="qrCode" className="h-[35px] w-[35px]" />
      </div>
    </div>
  );
};

export default PrintableMetaCv;
