import { useState } from "react";
import { Form } from "./Forms/Form";
import { Field } from "./Forms/Field";
import { Input } from "./Forms/Input";
import { CloseCircleIcon } from "../OldDesign/Icons";
import { useNavigate } from "react-router-dom";
import Button from "./Button/Button";
import { useForm } from "react-hook-form";
import { sendSupportTicket } from "../../api/endpoints";
import { toast } from "react-toastify";
import InviteFormSuccessPopup from "./Popups/InviteFormSuccessPopup";
import { useTranslation } from "react-i18next";

const StartADiscussionForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const sendQuestion = async (formData) => {
    const payload = {
      title: formData.support_title,
      description: formData.support_topic + ":" + formData.support_message,
    };
    await sendSupportTicket(
      payload,
      () => {
        setSuccess(true);
        toast.success(t("message.transaction_successful"));
      },
      (err) => {
        toast.error(t("message.transaction_failed"));
      },
    );
  };
  return (
    <>
      {success ? (
        <InviteFormSuccessPopup />
      ) : (
        <div className="max-w-[450px] rounded-xl bg-neutral-200 px-9 py-8 max-lg:max-w-[370px] max-lg:p-4">
          <div className="pb-4">
            <div className="flex flex-row justify-between">
              <h2 className="mb-2 border-l-2 border-primary-900 pl-2 text-[22px] font-semibold leading-tight text-primary-900">
                {t("button.start_discussion")}
              </h2>
              <button type="button" onClick={() => navigate(-1)}>
                <CloseCircleIcon className="h-6 w-6" />
              </button>
            </div>
            <p className="text-[17px] leading-tight text-primary-900">{t("dashboard.let_us_know")}</p>
          </div>
          <Form className="flex flex-col gap-3" onSubmit={handleSubmit(sendQuestion)}>
            <Field label={t("placeholder.title")}>
              <Input {...register("support_title")} id="support_title" />
            </Field>
            <Field label={t("placeholder.topic")}>
              <Input {...register("support_topic")} id="support_topic" />
            </Field>
            <Field label={t("placeholder.message")}>
              <textarea {...register("support_message")} id="support-message" className="p-4" cols={50} rows={4} />
            </Field>
            <Button type="submit" loading={isSubmitting} disabled={isSubmitting} className="self-end">
              {t("button.send")}
            </Button>
          </Form>
        </div>
      )}
    </>
  );
};

export default StartADiscussionForm;
