import SectionTitle from "./SectionTitle";
import OrientationCard from "./OrientationCard";
import Button from "./Button/Button";
import { RightArrowOutlined } from "../OldDesign/Icons";
import { useStore } from "../../store/useStore";
import { useTranslation } from "react-i18next";

const ProfessionalOrientation = () => {
  const { t } = useTranslation();
  const dimensions = useStore((state) => state.overview.data.metacv.dimensions);
  return (
    <section className="container2 flex flex-col gap-3 py-9 max-lg:py-3">
      <div className="px-9 py-3 max-lg:px-0 max-lg:py-2">
        <SectionTitle title={t("modal.metacv.occupational_orientation")} className="pb-3 max-lg:pb-2 lg:items-start" />
        <div className="flex flex-col justify-center gap-4 max-lg:px-0">
          <p className="text-[17px] font-medium leading-tight max-lg:text-center max-lg:text-[13px] max-lg:font-normal">
            {t("candidate.professional_orientation_explanation")}
          </p>
          <div className="flex flex-row items-center justify-between max-lg:hidden">
            <p className="text-[17px] font-medium leading-tight max-lg:text-[13px] max-lg:font-normal">
              {t("candidate.we_know_you_better")}
              <br />
            </p>
            <Button
              className="relative text-neutral-100"
              disabled={true}
              icon2={<RightArrowOutlined className="h-5 w-5" />}
            >
              {t("button.see_details")}
              <span className="absolute bottom-1 left-1 text-[13px] italic opacity-40">{t("common.coming_soon")}</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-6">
        {dimensions.map((orientation) => {
          return <OrientationCard key={orientation.title} orientation={orientation} />;
        })}
      </div>
    </section>
  );
};

export default ProfessionalOrientation;
