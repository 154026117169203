import { useStore } from "store/useStore";
import { ReactComponent as Logo } from "../assets/logo/humanas.svg";
import { DotOutlined } from "components/OldDesign/Icons";
import { Trans, useTranslation } from "react-i18next";
import { DownloadFromAppStoreButton, DownloadFromGooglePlayButton } from "components/NewDesign/Button/AuthButtons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { publicInvite } from "api/endpoints";
import { toast } from "react-toastify";
import Loading from "components/OldDesign/Loading";

const DownloadLazyGuru = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [campaign, setCampaign] = useState(JSON.parse(localStorage.getItem("campaignData")));
  const os = useStore((state) => state.os);
  const isIOS = os === "Mac OS";
  const isPositionInvite = campaign.is_job_position;
  const isEmployeeInvite = campaign.type === "employ";

  useEffect(() => {
    if (!campaign) {
      const autoLoginLink = decodeURIComponent(localStorage.getItem("autoLoginLink"));
      if (autoLoginLink) {
        const id = autoLoginLink.slice(13);
        const params = { campaign_name: id };
        publicInvite(
          params,
          (res) => {
            setCampaign(res.data);
            localStorage.setItem("campaignData", JSON.stringify(res.data));
          },
          (err) => {
            toast.error(t("message.transaction_failed"));
            setCampaign({ campaignData: false });
            navigate("/login");
          },
        );
      } else {
        navigate("/login");
      }
    }
  }, []);
  if (!campaign) {
    return <Loading />;
  }
  return (
    <div className="relative h-screen w-screen bg-soft-blur-mobile bg-cover bg-center bg-no-repeat">
      <div className="flex w-screen items-center justify-center border-b border-b-neutral-300 bg-white p-4">
        <img
          src={campaign.company_profile_picture}
          alt={campaign.company_name}
          className="max-h-[50px] max-w-[100px]"
        />
      </div>
      <div className="flex h-full flex-col items-center gap-4 p-6 text-center">
        <div className="flex h-[130px] flex-col gap-2">
          <div className="flex justify-center gap-1.5">
            <DotOutlined className={"w-[10px] text-neutral-400"} />
            <DotOutlined className={"w-[10px] text-primary-600"} />
          </div>
          <p className="text-lg font-semibold text-primary-600">{isPositionInvite && campaign.name}</p>
          <p className="text-sm font-semibold leading-tight text-neutral-900">
            <Trans i18nKey="auth.introduce_youself_to_company" values={{ companyname: campaign.company_name }}>
              <span className="text-primary-600" />
              <span className="text-primary-600" />
            </Trans>
          </p>
        </div>
        {isIOS ? <DownloadFromAppStoreButton /> : <DownloadFromGooglePlayButton />}
        <p className="text-sm leading-tight text-neutral-900">
          <Trans i18nKey="auth.company_invite_you_journey" values={{ companyname: campaign.company_name }}>
            <strong />
            <strong />
          </Trans>
        </p>
        <p className="text-sm leading-tight text-neutral-900">
          <Trans i18nKey="auth.take_action_to_standout">
            <strong />
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default DownloadLazyGuru;
