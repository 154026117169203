import { useEffect } from "react";
import HeroCandidate from "../../components/NewDesign/Hero/Overview/HeroCandidate";
import PersonalityType from "../../components/NewDesign/PersonalityType";
import ProfessionalOrientation from "../../components/NewDesign/ProfessionalOrientation";
import StartDiscussion from "../../components/NewDesign/StartDiscussion";
import { useStore } from "../../store/useStore";
import Loading from "../../components/OldDesign/Loading";
import CoreCvSection from "./CoreCvSection";

const Overview = () => {
  const getOverviewContent = useStore((state) => state.getOverview);
  const overview = useStore((state) => state.overview);
  const user = useStore((state) => state.user.data);
  const isMember = user.userCoreCvStatus === "completed";
  useEffect(() => {
    getOverviewContent();
  }, [getOverviewContent]);
  if (overview.isLoading) {
    return <Loading />;
  }
  if (!overview.isLoaded) {
    return null;
  }
  return (
    <>
      <HeroCandidate />
      {isMember && <CoreCvSection user={overview.data} />}
      <PersonalityType />
      <ProfessionalOrientation />
      <StartDiscussion />
    </>
  );
};

export default Overview;
